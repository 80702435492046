import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './hooks';
import { RouteEnum } from './RouteEnum';

const PublicRoutes = (props: any) => {
  const auth = useAuth();
  return auth ? <Navigate to={RouteEnum.Applicant} /> : <Outlet />;
};

export default PublicRoutes;
