import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIncomeDetail } from 'services/application';
import { IIncomeDetails } from 'types/redux/applicationDetails';
import { getExceptionPayload } from '../../services/api';

const initialState: IIncomeDetails = {
	data: undefined,
	error: undefined,
	loading: false,
};

export const getIncomeDetails = createAsyncThunk('incomeDetail', async (params: string, { rejectWithValue }) => {
	try {
		const response = await getIncomeDetail(params);
		return response.message;
	} catch (error) {
		throw rejectWithValue(getExceptionPayload(error));
	}
});

const slice = createSlice({
	name: 'incomeDetail',
	initialState,
	reducers: {
		resetApplications: (state) => {
			state.data = undefined;
			state.error = undefined;
		},
	},

	extraReducers(builder): void {
		builder
			.addCase(getIncomeDetails.pending, (state) => {
				state.data = undefined;
				state.error = undefined;
				state.loading = true;
			})
			.addCase(getIncomeDetails.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
			})
			.addCase(getIncomeDetails.rejected, (state, action) => {
				state.error = action.error.message as string;
				state.loading = false;
			});
	},
});
export const { resetApplications } = slice.actions;
export default slice.reducer;
