import InputCheckbox from 'components/form-components/InputCheckbox';
import VehicleImage from 'components/form-components/VehicleImage';
import { isEmpty } from 'lodash';
import { filterValues } from 'pages/application/helper';
import { Table } from 'react-bootstrap';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PreferenceData } from 'types/redux/applicationDetails';
import { IVehicleSharedImage } from '../../applicationDetailsInterface';

const SharedVehicleTable = (props: IVehicleSharedImage) => {
  const { setSelectedImage, selectedImage } = props;
  const data = useAppSelector((state: RootState) => state.vehicleShared.data);

  const toggleVehicleSelect = (data: PreferenceData) => {
    const vehicleId = data.VehicleId;
    if(selectedImage.includes(vehicleId)){
      setSelectedImage((preVehicle: any) => {
        return filterValues(preVehicle, vehicleId);
      });
    }
    else{
      setSelectedImage((preVehicle: any) => {
        return [...preVehicle, vehicleId];
      })
    }
  }

  return (
    <Table striped responsive borderless>
      <thead>
        <tr>
          <th />
          <th>Image</th>
          <th>VIN</th>
          <th>Make</th>
          <th>Model</th>
          <th>Odometer</th>
          <th>Stock Number</th>
          <th>Year</th>
        </tr>
      </thead>
      <tbody>
        {!isEmpty(data) ? (
          data?.map((item: PreferenceData, index: number) => {
            return (
              <tr key={index}>
                <td>
                  <InputCheckbox
                    id={item.VehicleId}
                    checked={selectedImage.includes(item.VehicleId)}
                    value={item.VehicleId}
                    handleOnChange={() => toggleVehicleSelect(item)}
                    name={'vehicleId'}
                  />
                </td>
                <td onClick={() => toggleVehicleSelect(item)}>{VehicleImage(item?.images?.path)}</td>
                <td>{item.VIN}</td>
                <td>{item.Make}</td>
                <td>{item.Model}</td>
                <td>{item.Odometer}</td>
                <td>{item.StockNumber}</td>
                <td>{item.Year}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td className='disabled' colSpan={8}>
              <h4 className='mb-2 text-center'>No Data Found</h4>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default SharedVehicleTable;
