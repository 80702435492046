import React from 'react';
import { IButton } from './FormComponentInterface';
import { Button } from 'react-bootstrap';

const ModalButton = (props: IButton) => {
  const { variant, onClick, label } = props;
  return (
    <Button variant={variant} type='button' className='btn-small mr-4' onClick={onClick}>
      {label}
    </Button>
  );
};

export default ModalButton;
