import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { IListItem, IListGroup } from './FormComponentInterface';

const SideBarListGroup = (props: IListGroup) => {
  const { listData, showDetails } = props;

  return (
    <ListGroup
      as='ul'
      bsPrefix=' '
      className={`list-unstyled profile-panel--details profile-panel--mobile-details ${showDetails ? 'show' : ''}`}
    >
      {!!listData?.length &&
        listData?.map((item: IListItem, index: number) => {
          return (
            <ListGroup.Item as='li' bsPrefix=' ' key={index}>
              <div className='icon-wrap'>
                <span className={`fa-icon ${item.icon}`} />
              </div>
              <div className='profile-panel--details-text'>
                <label className='heading-4'>{item.label}</label>
                <span className={`${item.label!=='Email' ? 'text-capitalize' : ''}`}>{item.value}</span>
              </div>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
};

export default SideBarListGroup;
