import { Images } from 'types/redux/applicationDetails';
import { IGenericOption } from './applicationDetailsInterface';
import { biWeekly, weekly, monthly, semiMonthly, annually } from './constants';

export const checkShow = (showFlag: string, value: string) => {
  return showFlag === value;
};

export const getValue = (obj: Object, key: string) => {
  type ObjectKey = keyof typeof obj;
  const typedKey = key as ObjectKey;
  return obj[typedKey];
};

export const addMasking = (value: string) => {
  let last3 = value.substring(value.length - 3);
  let mask = value.substring(0, value.length - 3).replace(/\d/g, '*');
  return mask + last3;
};

export const getLabel = (array: any, value: string | undefined, option?: string) => {
  const arr = array.find((item: { value: string }) => item?.value == value);
  if (option === 'label') {
    return arr?.label;
  }
  return arr;
};

export const amountFormatter = (number: number) => {
  return number ? `$${Math.round(number).toLocaleString()}` : 0;
};

export const convertToInteger = (value: string) => {
  return parseInt(value?.trim().replace(/,/g, ''));
};

export const calculateTotalIncome = (frequency: string, income: any) => {
  let result;
  switch (frequency) {
    case 'Weekly':
      result = income * weekly;
      break;
    case 'Bi-Weekly':
      result = income * biWeekly;
      break;
    case 'Monthly':
      result = income * monthly;
      break;
    case 'Semi Monthly':
      result = income * semiMonthly;
      break;
    case 'Annually':
      result = income * annually;
      break;
    default:
      result = income * monthly;
  }
  return result;
};

export const filterValues = (array: Array<any>, value: any) => {
  return array.filter((el: any) => el !== value);
};

export const prepareSlideshowImages = (images: Images[]) => {
  return images.map((image) => ({ src: image.path, alt: image.type }));
};

export const findValues = (array: Array<IGenericOption>, value: string) => {
  return array.find((el: IGenericOption) => el.label == value);
};
