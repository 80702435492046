import React from 'react';
import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { IAccordionCard } from './AccordionToggleInterface';

const AccordionCard = (props: IAccordionCard) => {
  const { eventKey, header, children } = props;
  return (
    <Card>
      <Card.Header>
        <AccordionCustomToggle eventKey={eventKey}>{header}</AccordionCustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AccordionCard;
