import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ListGroup from "react-bootstrap/ListGroup";
import "react-datepicker/dist/react-datepicker.css";
export default function UploadPayStubs() {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <Form>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group controlId="modal-employer" className="form-group">
            <Form.Label>Employer</Form.Label>
            <Select
              options={options}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group controlId="modal-pay-stubs" className="form-group">
            <Form.Label>Pay Stubs</Form.Label>
            <div className="file-ip-parent-wrap upload">
              <div className="file-ip-name-wrap">
                <span>PASSPORT</span>
                <span className="doc-count">, +1</span>
                <div className="close-circle">
                  <span className="fa-icon rv-close" />
                </div>
                <ListGroup
                  as="ul"
                  bsPrefix=" "
                  className="list-unstyled floating-doc-list"
                >
                  <ListGroup.Item bsPrefix=" " as="li">
                    <span>PASSPORT</span>
                    <div className="close-circle">
                      <span className="fa-icon rv-close" />
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " as="li">
                    <span>PASSPORT</span>
                    <div className="close-circle">
                      <span className="fa-icon rv-close" />
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " as="li">
                    <span>PASSPORT</span>
                    <div className="close-circle">
                      <span className="fa-icon rv-close" />
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="file-ip-wrap">
                <Form.Control type="file" className="file-input" />
                <label className="file-ip-label">
                  <span className="fa-icon rv-upload" />
                </label>
              </div>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col
          sm={12}
          md={12}
          className="d-flex align-items-end justify-content-end"
        >
          <Button variant="primary" type="submit" className="btn-small">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
