import Input from 'components/form-components/Input';
import { RouteEnum } from 'components/routes/RouteEnum';
import { emailReg } from 'constants/regex';
import { useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getForgotPassword } from 'store/forgot-password/slice';
import { useAppDispatch } from 'store/hooks';
import { IForgotPasswordData } from 'types/redux/forgotPassword';
import { IForgotPasswordForm } from './ForgotPasswordInterface';

const ForgotPasswordForm = () => {
  const dispatch = useAppDispatch();

  const formState = useForm<IForgotPasswordData>({
    defaultValues: {
      username: '',
    },
  });
  const { handleSubmit, watch } = formState;

  const password = useRef({});
  const { t } = useTranslation();

  const onSubmit = handleSubmit(data => {
    const signUpObj = {
      ...data,
    };
    dispatch(getForgotPassword(signUpObj));
  });

  const emailValue = watch('username');

  return (
    <Form onSubmit={onSubmit} autoComplete='off'>
      <Row>
        <Col sm={12} md={12}>
          <Form.Group controlId='form-email' className='form-group'>
            <Form.Label>{t('constants.emailId')}</Form.Label>
            <div className='input-with-icon'>
              <Input
                fieldName={'username'}
                fieldType={'username'}
                placeholder={t('constants.email-placeholder')}
                rules={{
                  required: {
                    value: true,
                    message: t('errorConstants.email-required'),
                  },
                  pattern: {
                    value: emailReg,
                    message: t('errorConstants.invalid-email'),
                  },
                }}
                formState={formState}
              />
              {
                emailValue && emailValue.match(emailReg) && (
                  <div className='icon-with-circle'>
                    <span className='fa-icon rv-check' />
                  </div>
                )
              }
            </div>
          </Form.Group>
        </Col>
        <Col sm={12} md={6} className='d-flex align-items-center'>
          <Link to={RouteEnum.Login} className='align-middle back-btn'>
            {t('constants.login-instead')}
          </Link>
        </Col>
        <Col sm={12} md={6}>
          <Button type='submit'>{t('constants.submit')}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ForgotPasswordForm;
