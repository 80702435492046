import { DDMMYYYY } from 'constants/dateTime';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { convertStringToLowerCase } from 'helpers/index';
import { isEmpty } from 'lodash';
import { IDocumentData } from 'pages/application/applicationDetailsInterface';
import { Col, Form, ListGroup, Row } from 'react-bootstrap';

const ShowDocument = (props: IDocumentData) => {
  const { documentData } = props;

  return (
    <>
      {!!documentData &&
        documentData?.map((item: any) => {
          return (
            <Row key={item?._id}>
              <Col sm={12} md={3}>
                <Form.Group controlId='form-name' className='form-group'>
                  <Form.Label>ID Proof</Form.Label>
                  <span className='read-text'>{convertStringToLowerCase(item?.fileName) ?? '-'}</span>
                </Form.Group>
              </Col>
              <Col sm={12} md={3}>
                <Form.Group controlId='form-name' className='form-group'>
                  <Form.Label>{`${convertStringToLowerCase(item?.fileName) ?? ''} No.`}</Form.Label>
                  <span className='read-text'>{item?.licenseNo ?? '-'}</span>
                </Form.Group>
              </Col>
              <Col sm={12} md={3}>
                <Form.Group controlId='form-name' className='form-group'>
                  <Form.Label>Expiry Date</Form.Label>
                  <span className='read-text'>{dateFormatter(item?.expiryDate, DDMMYYYY) ?? '-'}</span>
                </Form.Group>
              </Col>
              <Col sm={12} md={3}>
                <Form.Group controlId='form-name' className='form-group'>
                  {/* <Form.Label>Download</Form.Label> */}
                  <ListGroup as='ul' bsPrefix=' ' className='list-unstyled floating-doc-list'>
                    <ListGroup.Item bsPrefix=' ' as='li'>
                      <span>Download</span>
                    </ListGroup.Item>
                  </ListGroup>
                  {item?.fileName && (
                    <a href={item?.path} className='open-report doc-acc-open-report ml-2'>
                      {item?.fileName ? convertStringToLowerCase(item?.fileName) : ' '}
                      <span className='download' />
                    </a>
                  )}
                </Form.Group>
              </Col>
            </Row>
          );
        })}
      {isEmpty(documentData) && <p>No Documents Found</p>}
    </>
  );
};

export default ShowDocument;
