import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import { isEmpty } from 'lodash';
import { IDocumentData } from 'pages/application/applicationDetailsInterface';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EditDocument from './EditDocument';
import ShowDocument from './ShowDocument';

export default function Documents(props: IDocumentData | any) {
  const { documentData, leadId } = props;
  const [edit, setEdit] = useState(false);

  return (
    <Card>
      <Card.Header>
        <AccordionCustomToggle eventKey='1'>
          <div className='card-toggle--left'>
            <span className='fa-icon rv-document-filled' />
            <span className='card-toggle--left-text'>Documents</span>
          </div>
          <div className='card-toggle--right'>
            {isEmpty(documentData) ? (
              <Link
                to='#'
                className='blue-icon-text-link'
                onClick={(e) => {
                  e.stopPropagation();
                  setEdit(!edit);
                }}
              >
                <span className='fa-icon rv-add-circle' />
                <span className='blue-text'>ADD NEW</span>
              </Link>
            ) : (
              <span
                className='fa-icon rv-edit'
                onClick={(e) => {
                  e.stopPropagation();
                  setEdit(!edit);
                }}
              />
            )}
            <div className='accordion-arrow'>
              <span className='fa-icon rv-pointer-down' />
            </div>
          </div>
        </AccordionCustomToggle>
        <div className='card-toggle--right' />
      </Card.Header>
      <Accordion.Collapse eventKey='1'>
        <Card.Body>
          {edit ? (
            <EditDocument setEdit={setEdit} edit={edit} documentData={documentData} leadId={leadId} />
          ) : (
            <ShowDocument documentData={documentData} />
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
