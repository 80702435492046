import Loader from 'components/loader/Loader';
import { RouteEnum } from 'components/routes/RouteEnum';
import { isEmpty } from 'lodash';
import { ListGroup } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultCar from 'scss/images/demo-car.png';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PreferenceData } from 'types/redux/applicationDetails';
import { getView } from '../store/VehicleDetailSlice';
import { HEADING, STATUS, VEHICLE_TYPE } from '../VehicleConstants';
import { getVehicleImageHeading } from '../VehicleHelper';

const VehicleListView = () => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const { leadId } = useParams();

  const vehicleData = useAppSelector((state: RootState) => state.vehicleShared);
  const { preferenceData, loader } = vehicleData;

  const getDetailView = (vehicle: PreferenceData) => {
    history(`${RouteEnum.VehicleDetails}/${leadId}/${vehicle.VehicleId}`);
    dispatch(getView(false));
  };

  return loader ? (
    <Loader />
  ) : (
    <ListGroup as='ul' bsPrefix=' ' className='list-unstyled vehicle-details-list'>
      {!isEmpty(preferenceData) ? (
        preferenceData?.map((vehicle: PreferenceData) => (
          <ListGroup.Item as='li' bsPrefix=' ' key={vehicle._id}>
            <div className='slide'>
              <img
                src={vehicle.images?.path ?? DefaultCar}
                onClick={() => getDetailView(vehicle)}
                className='vehicle-image'
              />
              <div className='slide-text-wrap'>
                <h2 className='heading-2'>{getVehicleImageHeading(vehicle, HEADING)}</h2>
                <div className='d-flex align-items-center'>
                  <label className='slide-badge purple'>{getVehicleImageHeading(vehicle, STATUS)}</label>
                  <label className='slide-badge green'>{getVehicleImageHeading(vehicle, VEHICLE_TYPE)}</label>
                </div>
              </div>
            </div>
          </ListGroup.Item>
        ))
      ) : (
        <p>No vehicle Found</p>
      )}
    </ListGroup>
  );
};

export default VehicleListView;
