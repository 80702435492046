import Input from 'components/form-components/Input';
import InputNumber from 'components/form-components/InputNumber';
import InputSelect from 'components/form-components/InputSelect';
import Loader from 'components/loader/Loader';
import { isEmpty } from 'lodash';
import { IEditIncomeDetails } from 'pages/application/applicationDetailsInterface';
import { calculateTotalIncome, convertToInteger, findValues } from 'pages/application/helper';
import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearUpdate, postIncomeDetails } from 'store/post-income/slice';
import { IIncomeDetails } from 'types/redux/applicationDetails';
import { frequencyTypeOptions, otherIncomeTypeOption,MONTHLY } from '../../constants';

const EditIncome = (props: IEditIncomeDetails) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { setEdit, incomeDetail, leadId } = props;

  const formState = useForm({});
  const { handleSubmit, getValues, setValue } = formState;

  const putStatus: IIncomeDetails = useAppSelector((state: RootState) => state.postIncomeDetails);

  const onSubmit = handleSubmit((data) => {
    const putIncomeObj = {
      leadDetailsId: leadId,
      employmentIncomefrequency: data?.employmentIncomefrequency?.value ?? '',
      incomeSourceAmount: data?.incomeSourceAmount ? convertToInteger(data?.incomeSourceAmount) : '',
      otherIncomeFrequency: data?.otherIncomeFrequency?.value ?? '',
      otherIncomeAmount: data?.otherIncomeAmount ? convertToInteger(data?.otherIncomeAmount) : '',
      otherIncomeType: data?.otherIncomeType?.value ?? '',
      otherDescription: data?.otherDescription ?? '',
    };
    dispatch(postIncomeDetails(putIncomeObj));
  });

  useEffect(() => {
    if (!isEmpty(putStatus?.data) && id) {
      dispatch(getApplicationsDetails(id));
      dispatch(clearUpdate());
      setEdit(false);
    }
  }, [putStatus]);

  useEffect(() => {
    let netValue;
    const monthlyFreq = findValues(frequencyTypeOptions, MONTHLY);
    if (
      !isEmpty(getValues('incomeSourceAmount')) &&
      !isEmpty(getValues('otherIncomeAmount')) &&
      !isEmpty(getValues('employmentIncomefrequency')) &&
      !isEmpty(getValues('otherIncomeFrequency'))
    ) {
      netValue =
        calculateTotalIncome(
          getValues('employmentIncomefrequency')?.label,
          getValues('incomeSourceAmount')?.replace(/,?/g, '')
        ) +
        calculateTotalIncome(
          getValues('otherIncomeFrequency')?.label,
          getValues('otherIncomeAmount')?.replace(/,?/g, '')
        );
    }
    if (
      !isEmpty(getValues('incomeSourceAmount')) &&
      isEmpty(getValues('otherIncomeAmount')) &&
      !isEmpty(getValues('employmentIncomefrequency'))
    ) {
      netValue = calculateTotalIncome(
        getValues('employmentIncomefrequency')?.label,
        getValues('incomeSourceAmount')?.replace(/,?/g, '')
      );
    }
    if (isEmpty(getValues('employmentIncomefrequency'))) {
      setValue('employmentIncomefrequency', monthlyFreq);
    }
    if (isEmpty(getValues('otherIncomeFrequency'))) {
      setValue('otherIncomeFrequency', monthlyFreq);
    }
    setValue('totalIncome', netValue);
  }, [
    getValues('incomeSourceAmount'),
    getValues('otherIncomeAmount'),
    getValues('employmentIncomefrequency'),
    getValues('otherIncomeFrequency'),
  ]);

  useEffect(() => {
    if (incomeDetail) {
      const filteredFrequency = frequencyTypeOptions?.filter(
        (item) => item.value === incomeDetail?.employmentIncomefrequency
      );
      if (filteredFrequency?.length > 0) {
        setValue('employmentIncomefrequency', filteredFrequency[0]);
      }

      const filteredOtherIncomeType = otherIncomeTypeOption?.filter(
        (item) => item.value === incomeDetail?.otherIncomeType
      );
      if (filteredOtherIncomeType?.length > 0) {
        setValue('otherIncomeType', filteredOtherIncomeType[0]);
      }

      const filteredOtherIncomeFrequency = frequencyTypeOptions?.filter(
        (item) => item.value === incomeDetail?.otherIncomeFrequency
      );
      if (filteredOtherIncomeFrequency?.length > 0) {
        setValue('otherIncomeFrequency', filteredOtherIncomeFrequency[0]);
      }

      setValue('otherDescription', incomeDetail?.otherDescription);
      setValue('incomeSourceAmount', incomeDetail?.incomeSourceAmount);
      setValue('otherIncomeAmount', incomeDetail?.secondSourceIncomeAmount);

      setValue('employeeIncome', incomeDetail?.employmentIncome);
      setValue('otherIncomeAmount', incomeDetail?.otherIncomeAmount);
      setValue('totalIncome', incomeDetail?.totalMonthlyIncome);
    }
  }, [incomeDetail]);

  return (
    <>
      {putStatus?.loading ? (
        <Loader />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-primary-income' className='form-group'>
                <Form.Label>Primary Income Amount:</Form.Label>
                <InputNumber
                  fieldName={'incomeSourceAmount'}
                  fieldType={'text'}
                  placeholder='Primary Income Amount'
                  rules={{
                    required: {
                      value: true,
                      message: 'Primary income amount required',
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-frequency' className='form-group'>
                <Form.Label>Frequency:</Form.Label>
                <InputSelect
                  options={frequencyTypeOptions}
                  fieldName={'employmentIncomefrequency'}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: 'Frequency required',
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-other-income' className='form-group'>
                <Form.Label>Other Income amount:</Form.Label>
                <InputNumber
                  fieldName={'otherIncomeAmount'}
                  fieldType={'text'}
                  placeholder={'Other Income amount'}
                  rules={{
                    required: false,
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-other-desc' className='form-group'>
                <Form.Label>Other Description:</Form.Label>
                <Input
                  fieldName={'otherDescription'}
                  fieldType={'text'}
                  placeholder={'Other Description'}
                  rules={{
                    required: false,
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='justify-content-end'>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-income-type' className='form-group'>
                <Form.Label>Other Income Type:</Form.Label>
                <InputSelect options={otherIncomeTypeOption} fieldName={'otherIncomeType'} formState={formState} />
              </Form.Group>
            </Col>
          </Row>
          <Row className='justify-content-end'>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-income-frequency' className='form-group'>
                <Form.Label>Other Income Frequency:</Form.Label>
                <InputSelect options={frequencyTypeOptions} fieldName={'otherIncomeFrequency'} formState={formState} />
              </Form.Group>
            </Col>
          </Row>
          <Row className='row-border'>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-other-desc' className='form-group'>
                <Form.Label>Annual Total Income:</Form.Label>
                <InputNumber
                  fieldName={'totalIncome'}
                  fieldType={'text'}
                  placeholder={'Other Income amount'}
                  rules={{
                    required: false,
                  }}
                  formState={formState}
                  disabled={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='form-btn' className='form-group'>
                <Button type='submit' className='btn-small ml-auto'>
                  Update
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default EditIncome;
