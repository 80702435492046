import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { isLoggedIn } from 'helpers';
import { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { signOutUser } from 'store/login/slice';
import { getProfileDetails } from 'store/profile-detail/slice';
import { RootState } from '../../store';
import { CustomDropDown } from '../profile-dropdown/CustomDropDown';
import { DropDownMenu } from './DropDownMenu';
import { profileFullName } from './header.helpers';
import { DropDownValues } from './headerConstant';

const DropDownToggel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const profileData = useAppSelector((state: RootState) => state.profile.data);
  
  useEffect(() => {
    !profileData && isLoggedIn() && dispatch(getProfileDetails());
  }, [profileData])

  const navigateToProfile = async (name: string, value: any) => {
    if (name === 'logout') {
      await dispatch(signOutUser());
    }
    navigate(value);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomDropDown} id='dropdown-custom-components'>
          <div className='profile-circle'>
            {profileData && (
              <ProfileAvatar
                name={profileFullName(profileData)}
                image={profileData?.imageUrl}
              />
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu as={DropDownMenu}>
          {DropDownValues.map((value) => {
            return (
              <Dropdown.Item
                key={value.id}
                eventKey={value.id.toString()}
                onClick={() => navigateToProfile(value.value, value.url)}
              >
                {value.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropDownToggel;
