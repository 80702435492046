import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import Loader from 'components/loader/Loader';
import CustomModal from 'components/modal';
import ShowSuccessMessageModal from 'components/modal/ShowSuccessMessageModal';
import { SUBMITTED, DELETED } from 'constants/common';
import { ADD_PREFERENCE, DELETE_PREFERENCE } from 'constants/message';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearPostPreference,
  getCompareVehicleData,
  postVehicleSharedPreferenceData,
  clearDeletePreferenceFlag,
} from 'store/vehicle-share/slice';
import { COMPARE, PREFERENCE } from '../../constants';
import { checkShow } from '../../helper';
import VehiclesCompare from './VehiclesCompare';
import SharedVehicleTable from './SharedVehicleTable';
import { capitalizeFirstLetter } from 'helpers';

export default function VehicleShared() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const vehicleShareImage: any = useAppSelector((state: RootState) => state.vehicleShared);
  const { postPreference, deletePreferenceFlag } = vehicleShareImage;
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [showFlag, setShowFlag] = useState('');

  useEffect(() => {
    if (isEmpty(selectedImage)) {
      setShowFlag('');
    }
  }, [selectedImage]);

  const getCompareDate = (type: string) => {
    let vehicleObj: any = { vehicleId: [] };
    if (!isEmpty(selectedImage)) {
      vehicleObj = {
        vehicleId: selectedImage,
      };
    }
    if (type === COMPARE) {
      dispatch(getCompareVehicleData(vehicleObj));
    } else {
      dispatch(postVehicleSharedPreferenceData({ ...vehicleObj, leadId: id }));
    }
  };

  const showSuccessModal = (clearFunction: ActionCreatorWithoutPayload, title: string, text: string) => {
    return (
      <ShowSuccessMessageModal
        show
        handleClose={() => {
          closeSuccessModal(clearFunction);
        }}
        classNames={'modal-without-header login-modal'}
        title={title}
        text={text}
      />
    );
  };

  const closeSuccessModal = (dispatchAction: ActionCreatorWithoutPayload) => {
    id && dispatch(getApplicationsDetails(id));
    dispatch(dispatchAction());
  };

  return (
    <>
      {vehicleShareImage?.loader ? (
        <Loader />
      ) : (
        <Card>
          <Card.Header>
            <AccordionCustomToggle eventKey='7'>
              <div className='card-toggle--left'>
                <span className='fa-icon rv-car-filled' />
                <span className='card-toggle--left-text'>Vehicle Shared</span>
              </div>
              <div className='card-toggle--right'>
                <div className='accordion-arrow'>
                  <span className='fa-icon rv-pointer-down' />
                </div>
              </div>
            </AccordionCustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey='7'>
            <Card.Body>
              <div className='vertical-scroll'>
                <SharedVehicleTable selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
              </div>
              {!isEmpty(vehicleShareImage?.data) && (
                <div className='row-border d-flex justify-content-end align-items-center my-3 my-md-4'>
                  <Link
                    to='#'
                    className={`${
                      isEmpty(selectedImage) || selectedImage?.length < 2 ? 'disabled' : ''
                    } blue-icon-text-link mr-3`}
                  >
                    <span className='fa-icon rv-share-files' />
                    <span
                      className='blue-text'
                      onClick={() => {
                        setShowFlag(COMPARE);
                        getCompareDate(COMPARE);
                      }}
                    >
                      Compare
                    </span>
                  </Link>
                  <Button
                    variant='primary'
                    type='button'
                    className='btn-small'
                    onClick={() => {
                      getCompareDate(PREFERENCE);
                    }}
                    disabled={isEmpty(selectedImage)}
                  >
                    Send My Preference
                  </Button>
                </div>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
      {checkShow(showFlag, COMPARE) && (
        <CustomModal
          title={capitalizeFirstLetter(COMPARE)}
          show={checkShow(showFlag, COMPARE)}
          handleClose={() => setShowFlag('')}
          classNames='large-modal compare-modal'
        >
          <div className='form-area'>
            <VehiclesCompare selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
          </div>
        </CustomModal>
      )}
      {postPreference && showSuccessModal(clearPostPreference, SUBMITTED, ADD_PREFERENCE)}
      {deletePreferenceFlag && showSuccessModal(clearDeletePreferenceFlag, DELETED, DELETE_PREFERENCE)}
    </>
  );
}
