import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { IPhoneNumber } from './FormComponentInterface';
import { useTranslation } from 'react-i18next';

const PhoneNumberInput = ({
  country,
  onlyCountries,
  countryCodeEditable,
  fieldName,
  placeholder,
  rules,
  formState,
  name = fieldName,
  disabled = false,
}: IPhoneNumber) => {
  const {
    setValue,
    setError,
    clearErrors,
    getValues,
    control,
    formState: { errors },
  } = formState;
  const { t } = useTranslation();
  return (
    <>
      <Controller
        render={({ field }) => (
          <PhoneInput
            {...field}
            country={country}
            onlyCountries={onlyCountries}
            countryCodeEditable={false}
            value={getValues(name) || ''}
            onChange={(phone: string | Number) => {
              setValue(name, phone);
              {
                phone == 1
                  ? setError(name, {
                      type: 'manual',
                      message: t('errorConstants.phone-required'),
                    })
                  : clearErrors(name);
              }
            }}
            disabled={disabled}
          />
        )}
        name={name}
        control={control}
        rules={rules}
      />
      {!!errors?.[name] && <Form.Text className='text-danger'>{errors[name].message}</Form.Text>}
    </>
  );
};

export default PhoneNumberInput;
