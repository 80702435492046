import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExceptionPayload } from 'services/api/index';
import { api } from 'utils/base';
import { get } from 'utils/HttpUtility';
import { IVehicleDetails } from '../VehicleInterface';

const initialState: IVehicleDetails = {
  preferenceData: undefined,
  loader: false,
  errors: undefined,
  listView: false,
};

export const getVehicleDetailsData = createAsyncThunk('vehicleDetails', async (params: Object, { rejectWithValue }) => {
  try {
    const endpoint = `${api.vehicleDetail}/${params}`;
    const response = await get({ endpoint });
    return response.data.message;
  } catch (error) {
    throw rejectWithValue(getExceptionPayload(error));
  }
});

export const getView = createAsyncThunk('vehicleDetails/view', async (params: boolean, { rejectWithValue }) => {
  try {
    return params;
  } catch (error) {
    throw rejectWithValue(getExceptionPayload(error));
  }
});

const slice = createSlice({
  name: 'vehicleDetails',
  initialState,
  reducers: {
    resetVehicleDetails: (state) => {
      state.errors = undefined;
      state.preferenceData = initialState.preferenceData;
    },
  },
  extraReducers(builder): void {
    builder
      .addCase(getVehicleDetailsData.pending, (state) => {
        state.preferenceData = initialState.preferenceData;
        state.errors = initialState.errors;
        state.loader = true;
      })
      .addCase(getVehicleDetailsData.fulfilled, (state, action) => {
        state.preferenceData = action.payload;
        state.loader = false;
      })
      .addCase(getVehicleDetailsData.rejected, (state, action) => {
        state.errors = action.error.message as string;
        state.loader = false;
      }).addCase(getView.fulfilled, (state, action) => {
        state.listView = action.payload;
        state.loader = false;
      });
  },
});
export const { resetVehicleDetails } = slice.actions;
export default slice.reducer;
