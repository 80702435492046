import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from '../../scss/images/login-logo.svg';
import Wave from '../../scss/images/login-top-right-wave.svg';
import LoginLayout from "../../components/login-layout/LoginLayout";
import "./landing.scss";


function LandingPage() {
  const navigate = useNavigate();

  return (
    <LoginLayout classNames={"landing-form"}>
      <div className="login-form-btn-group w-100">
          <Button variant="primary" className="w-100" onClick={() => navigate("/login")}>Login</Button>
          <p className="login-option">
            <span>OR</span>
          </p>
          <Button variant="override" className="w-100" onClick={() => navigate("/sign-up")}>SIGN UP</Button>
        </div>
    </LoginLayout>
  );
}

export default LandingPage;
