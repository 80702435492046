import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { api, apiKey, baseURL } from './base';
import {RouteEnum} from 'components/routes/RouteEnum';
import { jwtExpired } from 'constants/common';

const defaultBaseUrl = baseURL || 'http://localhost:3000';

interface IRequestConfig extends AxiosRequestConfig {
	_retry?: boolean;
}
class ApiFactoryWrapper {
	private readonly baseURL;
	private isRefreshing: boolean = false;
	private failedQueue: {}[] = [];

	constructor(URL: string = defaultBaseUrl) {
		this.baseURL = URL;
	}

	transformResponse = (input: string) => {
		try {
			return JSON.parse(input);
		} catch {
			/* Ignore */
			return false;
		}
	};

	buildHeader = (obj = {}) => {
		const tokenValue = localStorage.getItem('accessToken');
		const header: any = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'x-api-key': `${apiKey}`,
		};
		if (tokenValue) {
			header.Authorization = `Bearer ${tokenValue}`;
		}
		Object.assign(header, obj);
		return header;
	};

	apiFactory = (header: Object = {}) => {
		const instance = axios.create({
			baseURL: this.baseURL,
			headers: this.buildHeader(header),
			transformResponse: [
				(data) => {
					if (typeof data === 'string') {
						return this.transformResponse(data);
					}
					return data;
				},
			],
		});

		instance.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error) => {
				if (error.response.status === 401 && error.response.data.message == jwtExpired) {
					return this.retryRequest(error);
				}
				return Promise.reject(error);
			}
		);

		return instance;
	};

	logout = () => {
		localStorage.clear();
		window.location.href = RouteEnum.Login;
	}

	retryRequest = (error: AxiosError) => {
		if (error.response) {
			const originalRequest: IRequestConfig = error.response.config;
			
			if (error?.response?.status === 401 && originalRequest.url === api.signIn) {
				this.logout();
			}

			if (!originalRequest._retry) {
				if (this.isRefreshing) {
					return new Promise((resolve, reject) => {
						this.failedQueue.push({ resolve, reject });
					})
						.then((token) => {
							originalRequest.headers && (originalRequest.headers['Authorization'] = 'Bearer ' + token);
							return axios(originalRequest);
						})
						.catch((err) => {
							return Promise.reject(err);
						});
				}

				originalRequest._retry = true; // mark request a retry
				this.isRefreshing = true;

				return this.logIn().then((tokenData: any) => {
					originalRequest.headers && (originalRequest.headers['Authorization'] = 'Bearer ' + tokenData.accessToken);
					this.processQueue(null, tokenData.accessToken);

					return axios(originalRequest);
				});
			}
			return Promise.reject(error);
		}
		return Promise.reject(error);
	};

	logIn = () => {
		return new Promise((resolve, reject) => {
			resolve(
				axios
					.post(
						`${this.baseURL}${api.signIn}`,
						{ token: localStorage.getItem('refreshToken'), grantType: 'refreshToken' },
						{ headers: this.buildHeader() }
					)
					.then((res) => {
						const data = res.data.message;
						localStorage.setItem('accessToken', data.accessToken);
						localStorage.setItem('refreshToken', data.refreshToken);
						return Promise.resolve(data);
					})

					.catch((e) => {
						this.logout();
						return Promise.reject(e);
					})
					.finally(() => {
						this.isRefreshing = false;
					})
			);
		});
	};

	processQueue = (error: Error | null, token: string) => {
		this.failedQueue.map((prom: any) => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve(token);
			}
		});

		this.failedQueue = [];
	};
}

const apiFactory = new ApiFactoryWrapper().apiFactory;
export default apiFactory;
