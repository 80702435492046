import DateInput from 'components/form-components/DateInput';
import Input from 'components/form-components/Input';
import PhoneNoInput from 'components/form-components/PhoneNoInput';
import { RouteEnum } from 'components/routes/RouteEnum';
import { onlyCountries } from 'constants/common';
import { emailReg } from 'constants/regex';
import { dateOfBirthValidation } from 'helpers/dateTimeHelper';
import { emptyPhone, invalidPhone } from 'helpers/validationHelpers';
import { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ISignUpForm } from './SignUpInterface';

const SignUpForm = ({ onSubmit, formState, setShow }: ISignUpForm) => {
  const { t } = useTranslation();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setConfirmPassword] = useState(false);
  const { watch, setValue, getValues, setError, clearErrors } = formState;
  
  const passwordValue = watch('password');
  const emailValue = watch('email');
  
  const onChangeDateOfBirth = (date: Date | any) => {
    setValue('birthDate', new Date(date));
    {
      getValues('birthDate') && dateOfBirthValidation(date) !== ''
        ? setError('birthDate', {
            type: 'manual',
            message: t('errorConstants.dob-pattern'),
          })
        : clearErrors('birthDate');
    }
  };

  return (
    <Form onSubmit={onSubmit} autoComplete='new-input'>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group controlId='form-fName' className='form-group'>
            <Form.Label>{t('constants.firstName')}</Form.Label>
            <Input
              fieldName={'firstName'}
              fieldType={'text'}
              placeholder={t('constants.first-name')}
              rules={{
                required: {
                  value: true,
                  message: t('errorConstants.first-name-required'),
                },
              }}
              formState={formState}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group controlId='form-lName' className='form-group'>
            <Form.Label>{t('constants.lastName')}</Form.Label>
            <Input
              fieldName={'lastName'}
              fieldType={'text'}
              placeholder={t('constants.last-name')}
              rules={{
                required: {
                  value: true,
                  message: t('errorConstants.last-name-required'),
                },
              }}
              formState={formState}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={12}>
          <Form.Group controlId='form-email' className='form-group'>
            <Form.Label>{t('constants.emailId')}</Form.Label>
            <div className='input-with-icon'>
              <Input
                fieldName={'email'}
                fieldType={'email'}
                placeholder={t('constants.email-placeholder')}
                rules={{
                  required: {
                    value: true,
                    message: t('errorConstants.email-required'),
                  },
                  pattern: {
                    value: emailReg,
                    message: t('errorConstants.invalid-email'),
                  },
                }}
                formState={formState}
              />
              {
                emailValue && emailValue.match(emailReg) && (
                  <div className='icon-with-circle'>
                    <span className='fa-icon rv-check' />
                  </div>
                )
              }
            </div>
          </Form.Group>
        </Col>

        <Col sm={12} md={6}>
          <Form.Group controlId='form-birth' className='form-group'>
            <Form.Label>{t('constants.dob')}</Form.Label>
            <DateInput
              fieldName={'birthDate'}
              fieldType={'birthDate'}
              placeholder={t('constants.birthDate')}
              formState={formState}
              rules={{
                required: {
                  value: true,
                  message: t('errorConstants.dob-required'),
                },
              }}
              onChange={onChangeDateOfBirth}
            />
          </Form.Group>
        </Col>

        <Col sm={12} md={6}>
          <Form.Group controlId='form-phone' className='form-group'>
            <Form.Label>{t('constants.phone')}</Form.Label>
            <PhoneNoInput
              fieldName={'cellPhone'}
              country={onlyCountries[0]}
              onlyCountries={onlyCountries}
              countryCodeEditable={false}
              rules={{
                validate: {
                  emptyPhone: (v:string) => emptyPhone(v, 'constants.phoneNumber'),
                  invalidPhone: (v:string) => invalidPhone(v, 'constants.phoneNumber'),
                },
              }}
              formState={formState}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className='form-group' controlId='formGroupPassword'>
            <Form.Label>{t('constants.password')}</Form.Label>
            <div className='input-with-icon'>
              <Input
                fieldName={'password'}
                fieldType={viewPassword ? 'text' : 'password'}
                placeholder={t('constants.password-placeholder')}
                rules={{
                  required: {
                    value: true,
                    message: t('errorConstants.password-required'),
                  },
                }}
                formState={formState}
              />
              <span
                onClick={() => setViewPassword(!viewPassword)}
                className={`fa-icon ${viewPassword ? 'rv-eye-on' : 'rv-eye-off'}`}
              />
            </div>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className='form-group' controlId='formGroupPassword1'>
            <Form.Label>{t('constants.confirmPassword')}</Form.Label>
            <div className='input-with-icon'>
              <Input
                fieldName={'confirmPassword'}
                fieldType={viewConfirmPassword ? 'text' : 'password'}
                placeholder={t('constants.confirm-password-placeholder')}
                rules={{
                  required: {
                    value: true,
                    message: t('errorConstants.confirm-password'),
                  },
                  validate: (value: any) => value === passwordValue || t('errorConstants.password-not-match'),
                }}
                formState={formState}
              />
              <span
                onClick={() => setConfirmPassword(!viewConfirmPassword)}
                className={`fa-icon ${viewConfirmPassword ? 'rv-eye-on' : 'rv-eye-off'}`}
              />
            </div>
          </Form.Group>
        </Col>
        <Col sm={12} md={6} className='d-flex align-items-center'>
          <Link to={RouteEnum.Login} className='align-middle back-btn'>
            {t('constants.login-instead')}
          </Link>
        </Col>
        <Col sm={12} md={6}>
          <Button type='submit'>{t('constants.submit')}</Button>
        </Col>
      </Row>

      {/*TODO: Trade In Step 2 */}
      {/* <TradeIn /> */}
    </Form>
  );
};

export default SignUpForm;
