import { createSlice } from '@reduxjs/toolkit';
import { IApplicationDetail } from 'types/redux/applicationDetails';
import { getApplicationsDetails } from './action';

const initialState: IApplicationDetail = {
  data: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'applicationDetails',
  initialState,
  reducers: {
    resetApplications: (state) => {
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers(builder): void {
    builder
      .addCase(getApplicationsDetails.pending, (state) => {
        state.data = undefined;
        state.error = undefined;
        state.loader = true;
      })
      .addCase(getApplicationsDetails.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loader = false;
      })
      .addCase(getApplicationsDetails.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loader = false;
      });
  },
});
export const { resetApplications } = slice.actions;
export default slice.reducer;
