import { vehicleCompareSingleProp } from 'types/redux/applicationDetails';

export const TIMELINE = [
  { label: 'personal info', value: 'profileInfoIncomplete' },
  { label: 'documents', value: 'documentsIncomplete' },
  { label: 'current address', value: 'currentAddressIncomplete' },
  { label: 'home/mortgage details', value: 'homeMortgageIncomplete' },
  { label: 'income details', value: 'incomeDetailsIncomplete' },
  { label: 'employment history', value: 'employmentHistoryIncomplete' },
  { label: 'vehicle share', value: 'vehicleShareIncomplete' },
];

export const directionOption = [
  { label: 'East', value: 'E' },
  { label: 'North', value: 'N' },
  { label: 'North East', value: 'NE' },
  { label: 'North West', value: 'NW' },
  { label: 'South', value: 'S' },
  { label: 'South East', value: 'SE' },
  { label: 'South West', value: 'SW' },
  { label: 'West', value: 'W' },
];

export const countries = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
];

export const residenceTypeOptions = [
  { label: 'Mortgage', value: 'Own with Mortgage' },
  { label: 'Rent', value: 'Rent' },
  { label: 'Own', value: 'Own Free & Clear' },
  { label: 'With Family', value: 'Family' },
];

export const frequencyTypeOptions = [
  {
    label: 'Weekly',
    value: 'W',
  },
  {
    label: 'Bi-Weekly',
    value: 'BiWK',
  },
  {
    label: 'Monthly',
    value: 'M',
  },
  {
    label: 'Semi Monthly',
    value: 'SmMO',
  },
  {
    label: 'Annually',
    value: 'A',
  },
];

export const employmentTypeOptions = [
  {
    label: 'Employed',
    value: 'E',
  },
  {
    label: 'Self-Employed',
    value: 'SELFEMPLOYED',
  },
  {
    label: 'Retired',
    value: 'RETIRED',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
];

export const occupationTypeOptions = [
  {
    label: 'At Home',
    value: 'Athome',
  },
  {
    label: 'Executive',
    value: 'Executive',
  },
  {
    label: 'Labourer',
    value: 'Labourer',
  },
  {
    label: 'Management',
    value: 'Management',
  },
  {
    label: 'Office Staff',
    value: 'OfficeStaff',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Production',
    value: 'Production',
  },
  {
    label: 'Professional',
    value: 'Professional',
  },
  {
    label: 'Full Time',
    value: 'FULLTIME',
  },
  {
    label: 'Part Time',
    value: 'PARTTIME',
  },
  {
    label: 'Retired',
    value: 'Retired',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Self-Employeed',
    value: 'SELFEMPLOYED',
  },
  {
    label: 'Service',
    value: 'Service',
  },
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Trades',
    value: 'Trades',
  },
  {
    label: 'Unemployed',
    value: 'Unemployed',
  },
];

export const otherIncomeTypeOption = [
  {
    label: 'Car Allowance',
    value: 'carAllowance',
  },
  {
    label: 'Child Support/alimony',
    value: 'childSupport/alimony',
  },
  {
    label: 'Disability Payments',
    value: 'disabilityPayments',
  },
  {
    label: 'Investment Income',
    value: 'investmentIncome',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Pensions',
    value: 'Pensions',
  },
  {
    label: 'Rental Income',
    value: 'rentalIncome',
  },
  {
    label: 'Works Compensation',
    value: 'worksCompensation',
  },
];

export const momentDateMonthYear = 'DD-MM-YYYY';

export const weekly = 52;

export const monthly = 12;

export const biWeekly = 26;

export const semiMonthly = 24;

export const annually = 1;

export const incomeFrequency = [
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    label: 'Bi-Weekly',
    value: 'BIWEEKLY',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
];

export const prefixOption = [
  { label: 'Dr', value: 'Dr' },
  { label: 'Mr', value: 'Mr' },
  { label: 'Mrs', value: 'Mrs' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Ms', value: 'Ms' },
];

export const suffixOption = [
  { label: 'I', value: 'I' },
  { label: 'II', value: 'II' },
  { label: 'III', value: 'III' },
  { label: 'IV', value: 'IV' },
  { label: 'JR', value: 'JR' },
  { label: 'SR', value: 'SR' },
];

export const yearMonthDate = 'YYYY-MM-DD';

export const DRIVING_LICENCE = 'DRIVING_LICENSE';

export const PASSPORT = 'PASSPORT';

export const documentList = [
  {
    label: 'Rent Receipt',
    value: 'RENT_RECEIPT',
  },
];

export const employmentStatusOption = [
  {
    label: 'Full Time',
    value: 'FULLTIME',
  },
  {
    label: 'Full Time (probation)',
    value: 'FULLTIME(probation)',
  },
  {
    label: 'Part Time (regular)',
    value: 'PARTTIME(regular)',
  },
  {
    label: 'Part Time (casual)',
    value: 'PARTTIME(casual)',
  },
  {
    label: 'Retired',
    value: 'retired',
  },
  {
    label: 'Seasonal Summer',
    value: 'seasonalSummer',
  },
  {
    label: 'Seasonal Winter',
    value: 'seasonalWinter',
  },
  {
    label: 'Self-Employed',
    value: 'selfEmployed',
  },
];

export const label = 'label';
export const COMPARE = 'compare';
export const PREFERENCE = 'preference';

export const vehicleDetails: vehicleCompareSingleProp[] = ['Engine', 'Transmission', 'Make', 'Model'];

export const MONTHLY = 'Monthly';
