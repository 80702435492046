export enum RouteEnum {
  Applicant = '/applicant',
  Dashboard = '/dashboard',
  Login = '/login',
  Landing = '/',
  Applications = '/applications',
  ApplicationDetail = '/applications/:id',
  SignUp = '/sign-up',
  Profile = '/profile',
  ForgotPassword = '/forgot-password',
  UpdatePassword = '/update-password',
  VehicleDetails = '/vehicle-details',
}
