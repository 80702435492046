import LoginLayout from 'components/login-layout/LoginLayout';
import ShowMessageModal from 'components/modal/ShowMessageModal';
import { DDMMYYYY } from 'constants/dateTime';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Check from 'scss/images/login-check.svg';
import { userSignUp } from 'store/sign-up/slice';
import { ISignUpData, ISignUpState } from 'types/redux/signup';
import { APIStatus } from '../../services/api';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SignUpForm from './SignUpForm';

const SignUpPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const formState = useForm<ISignUpData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      cellPhone: '',
      email: '',
      birthDate: '',
      password: '',
      confirmPassword: '',
    },
  });

  const { handleSubmit } = formState;

  const history = useNavigate();
  const signUpState: ISignUpState = useAppSelector((state: RootState) => state.signUp);

  useEffect(() => {
    const status = signUpState?.signUpUser?.status;
    if (status === APIStatus.FULFILLED) {
      setShow(!show);
      setShow(true);
      history('/login');
    } else if (status === APIStatus.REJECTED) {
      console.log({ signUpState });
      // add logic to be performed when error occurs
    }
  }, [signUpState]);

  const onSubmit = handleSubmit((data) => {
    const signUpObj = { ...data, birthDate: dateFormatter(data.birthDate, DDMMYYYY) };
    dispatch(userSignUp(signUpObj));
  });

  return (
    <LoginLayout classNames={'signup-form trade-in-form'}>
      <h2 className='heading-2'>
        {t('constants.personal')}
        <br />
        {t('constants.information')}
      </h2>

      <SignUpForm onSubmit={onSubmit} formState={formState} setShow={setShow} />
      {show && (
        <ShowMessageModal
          show={show}
          handleClose={() => setShow(!show)}
          classNames={'modal-without-header login-modal'}
        >
          <>
            <img src={Check} alt='check' />
            <h2 className='heading-1'>{t('constants.email-sent')}</h2>
            <p>{t('constants.email-verification-msg')}</p>
          </>
        </ShowMessageModal>
      )}
    </LoginLayout>
  );
};

export default SignUpPage;
