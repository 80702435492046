import { IFormItem } from 'components/form-components/FormComponentInterface';
import { DDMMYYYY } from 'constants/dateTime';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { formatPhoneNumber } from 'helpers/helper';
import { PreferenceData } from 'types/redux/applicationDetails';
import { HEADING, STATUS, VEHICLE_TYPE } from './VehicleConstants';
import { IVehicleData, LeadDetails } from './VehicleInterface';

export const getListItem = (applicationDetails: LeadDetails) => {
  const listItemData = [
    {
      label: 'Applicant',
      icon: 'rv-user',
      value: `${applicationDetails?.applicantRole?.toLowerCase()} Applicant`,
    },
    {
      label: 'Email',
      icon: 'rv-email',
      value: `${applicationDetails?.email ?? ''}`,
    },
    {
      label: 'Phone',
      icon: 'rv-phone',
      value: applicationDetails?.phone ? formatPhoneNumber(applicationDetails?.phone) : ' ',
    },
    {
      label: 'Date of birth',
      icon: 'rv-calendar-1',
      value: `${applicationDetails?.birthDate ? dateFormatter(applicationDetails?.birthDate, DDMMYYYY) : ''}`,
    },
  ];
  return listItemData;
};

export const getShowVehicleData = (preferenceData: IVehicleData) => {
  const vehicleData = [
    { name: 'Year', value: preferenceData?.Year ?? '-' },
    { name: 'Make', value: preferenceData?.Make ?? '-' },
    { name: 'Modal', value: preferenceData?.Model ?? '-' },
    { name: 'Trim', value: preferenceData?.Trim ?? '-' },
    { name: 'Color', value: preferenceData?.InteriorColor?.Description ?? '-' },
    { name: 'VIN Number', value: preferenceData?.VIN ?? '-' },
    { name: 'Odometer', value: preferenceData?.Odometer ?? '-' },
  ];
  return withCheckPoints(vehicleData);
};

// TODO:Once we get data will integrate preferenceData in values
export const getFeatureData = (preferenceData: IVehicleData) => {
  const vehicleData = [
    { name: 'PowerSeat', value: '-' },
    { name: 'Backup Camera', value: '-' },
    { name: 'Bluetooth', value: '-' },
    { name: 'Keyless Entry', value: '-' },
    { name: 'Voice Command', value: '-' },
    { name: 'Heated Side', value: '-' },
    { name: 'Auto Climate Control', value: '-' },
  ];
  return withCheckPoints(vehicleData);
};

const withCheckPoints = (data: IFormItem[], additionalCheckpoints = {}) => {
  const checkpoints = { ...{ sm: 12, md: 3 }, ...additionalCheckpoints };
  return data?.map((item: IFormItem) => ({ ...item, ...checkpoints }));
};

export const getVehicleImageHeading = (data: IVehicleData | PreferenceData, type: string) => {
  if (type === HEADING) return `${data?.Year ?? ''} ${data?.Make ?? ''} ${data?.Model ?? ''}`;
  if (type === STATUS) return data?.Status ?? '';
  if (type === VEHICLE_TYPE) return data?.VehicleType ?? '';
};

export const getApplicantAvatar=(applicationDetails:LeadDetails)=>{
return `${applicationDetails?.firstName?.charAt(0) ?? ''} ${applicationDetails?.lastName?.charAt(0) ?? ''}`
}