import React from 'react';
import { IInputCheckbox } from './FormComponentInterface';

const InputCheckbox = (props: IInputCheckbox) => {
  const { id, handleOnChange, value, checked, name = '' } = props;

  return (
    <div className='custom-control custom-checkbox'>
      <input
        type='checkbox'
        id={id}
        className='custom-control-input'
        name={name}
        checked={checked}
        value={value}
        onChange={(event: React.FormEvent<HTMLInputElement>) => handleOnChange(event)}
      />
      <label  htmlFor={id} className='custom-control-label'></label>
    </div>
  );
};

export default InputCheckbox;
