import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import { IFormInput } from './FormComponentInterface';

const Input = ({
  fieldName,
  fieldType = 'text',
  placeholder,
  rules,
  formState,
  classNames = '',
  disabled = false,
}: IFormInput) => {
  const {
    control,
    formState: { errors },
  } = formState;
  const { field } = useController({
    name: fieldName,
    control: control,
    rules: rules,
  });

  return (
    <>
      <Form.Control type={fieldType} placeholder={placeholder} {...field} className={classNames} disabled={disabled} />
      {errors[fieldName] && <Form.Text className='text-danger'>{errors[fieldName].message}</Form.Text>}
    </>
  );
};

export default Input;
