import Input from 'components/form-components/Input';
import { RouteEnum } from 'components/routes/RouteEnum';
import { passwordReg } from 'constants/regex';
import { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { updatePassword } from 'store/update-password/slice';
import { IUpdatePasswordData } from 'types/redux/forgotPassword';
import { IUpdatePassword } from './ForgotPasswordInterface';

const UpdatePasswordForm = ({ queryparams }: IUpdatePassword) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setConfirmPassword] = useState(false);
  const formState = useForm<IUpdatePasswordData>({});
  const { handleSubmit,watch } = formState;
  const password = watch('password', '');

  const onSubmit = handleSubmit((data) => {
    // return;
    if (data && queryparams) {
      const payload = {
        ...data,
        verificationToken: queryparams.token,
        verificationId: queryparams.id,
      };
      dispatch(updatePassword(payload));
    }
  });

  return (
    <Form onSubmit={onSubmit} autoComplete='new-input'>
      <Row>
        <Col sm={12} md={12}>
          <Form.Group className='form-group' controlId='formGroupPassword'>
            <Form.Label>{t('constants.password')}</Form.Label>
            <div className='input-with-icon'>
              <Input
                fieldName={'password'}
                fieldType={viewPassword ? 'text' : 'password'}
                placeholder={t('constants.password-placeholder')}
                rules={{
                  required: {
                    value: true,
                    message: t('errorConstants.password-required'),
                  },
                  pattern: {
                    value: passwordReg,
                    message: t('errorConstants.invalid-password'),
                  },
                }}
                formState={formState}
              />
              <span
                onClick={() => setViewPassword(!viewPassword)}
                className={`fa-icon ${viewPassword ? 'rv-eye-on' : 'rv-eye-off'}`}
              />
            </div>
          </Form.Group>
        </Col>
        <Col sm={12} md={12}>
          <Form.Group className='form-group' controlId='formGroupPassword1'>
            <Form.Label>{t('constants.confirmPassword')}</Form.Label>
            <div className='input-with-icon'>
              <Input
                fieldName={'confirmPassword'}
                fieldType={viewConfirmPassword ? 'text' : 'password'}
                placeholder={t('constants.confirm-password-placeholder')}
                rules={{
                  required: {
                    value: true,
                    message: t('errorConstants.confirm-password'),
                  },
                  validate: (value: string) => value === password || t('errorConstants.password-not-match')
                }}
                formState={formState}
              />
              <span
                onClick={() => setConfirmPassword(!viewConfirmPassword)}
                className={`fa-icon ${viewConfirmPassword ? 'rv-eye-on' : 'rv-eye-off'}`}
              />
            </div>
          </Form.Group>
        </Col>
        <Col sm={12} md={6} className='d-flex align-items-center'>
          <Link to={RouteEnum.Login} className='align-middle back-btn'>
            {t('constants.login-instead')}
          </Link>
        </Col>
        <Col sm={12} md={6}>
          <Button type='submit'>{t('constants.submit')}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UpdatePasswordForm;
