import Input from 'components/form-components/Input';
import InputSelect from 'components/form-components/InputSelect';
import Loader from 'components/loader/Loader';
import { IManualAddress } from 'pages/application/applicationDetailsInterface';
import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { clearRequest, putApplicationDetail } from 'store/home-details/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { countries, directionOption } from '../../constants';
import { isEmpty } from 'lodash';
import { getLabel } from '../../helper';
import { isRequired } from 'helpers/errorMessageHelper';
import { enterPlaceholder } from 'helpers/placeHoldersHelper';

const ManualAddress = (props: IManualAddress) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const formState = useForm({});
  const { addressData, manualInput } = props;
  const { handleSubmit, reset } = formState;
  const putStatus: any = useAppSelector((state: RootState) => state.homeDetails);

  useEffect(() => {
    if (addressData) {
      const direction = getLabel(directionOption, addressData?.direction);
      const country = getLabel(countries, addressData?.country);
      reset({
        ...addressData,
        country: country,
        direction: direction,
        streetNumber: addressData?.addressNumber,
      });
    }
  }, [addressData]);

  useEffect(() => {
    if (!isEmpty(putStatus?.data) && id) {
      dispatch(getApplicationsDetails(id));
      dispatch(clearRequest());
    }
  }, [putStatus]);

  const getFullAddress = (data: any) => {
    const {
      streetNumber = '',
      streetName = '',
      suiteNumber = '',
      city = '',
      zipCode = '',
      province: { value: provinceValue = '' },
      country: { value: countryValue = '' },
    } = data;
    const manualAddress = `${streetNumber}  ${streetName} ${suiteNumber}, ${city}, ${provinceValue} ${zipCode},${countryValue}`;
    return manualAddress;
  };

  const onSubmit = handleSubmit((data) => {
    const payload = {
      id: id,
      address: !manualInput ? getFullAddress(data) : addressData?.address ?? '',
      suiteNumber: data?.suiteNumber ?? '',
      direction: data?.direction?.value ?? '',
      city: data?.city ?? '',
      zipCode: data?.zipCode ?? '',
      streetName: data?.streetName ?? '',
      monthsAtResidence: data?.monthsAtResidence ?? '',
      yearsAtResidence: data?.yearsAtResidence ?? '',
      addressNumber: data?.streetNumber ?? '',
      country: data?.country?.value ?? '',
    };

    dispatch(putApplicationDetail(payload));
  });

  return (
    <>
      {putStatus?.loading ? (
        <Loader />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-suite' className='form-group'>
                <Form.Label>Suite Number:</Form.Label>
                <Input
                  fieldName={'suiteNumber'}
                  fieldType={'text'}
                  placeholder={t('constants.suiteNumber')}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-street' className='form-group'>
                <Form.Label>Street Number:</Form.Label>
                <Input
                  fieldName={'streetNumber'}
                  fieldType={'number'}
                  placeholder={t('constants.streetNumber')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.streetNumber'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-street-name' className='form-group'>
                <Form.Label>Street Name:</Form.Label>
                <Input
                  fieldName={'streetName'}
                  fieldType={'text'}
                  placeholder={t('constants.streetName')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.streetName'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-direction' className='form-group'>
                <Form.Label>Direction:</Form.Label>
                <InputSelect options={directionOption} fieldName={'direction'} formState={formState} />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-city' className='form-group'>
                <Form.Label>City:</Form.Label>
                <Input
                  fieldName={'city'}
                  fieldType={'text'}
                  placeholder={t('constants.city')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.city'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-postal' className='form-group'>
                <Form.Label>Postal Code:</Form.Label>
                <Input
                  fieldName={'zipCode'}
                  fieldType={'text'}
                  placeholder={t('constants.zipCode')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.zipCode'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-country' className='form-group'>
                <Form.Label>Country:</Form.Label>
                <InputSelect
                  options={countries}
                  fieldName={'country'}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.country'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            {/* <Col sm={12} md={6}>
        <Form.Group controlId='form-province' className='form-group'>
          <Form.Label>Province:</Form.Label>
          <Select options={options} className='react-select-container' classNamePrefix='react-select' />
        </Form.Group>
      </Col> */}
            <Col sm={12} md={6}>
              <Form.Group controlId='form-duration' className='form-group'>
                <Form.Label>Duration:</Form.Label>
                <Row>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Group controlId='form-duration-years' className='form-group d-flex align-items-center'>
                      <Form.Label className='mr-2 mb-0'>Years:</Form.Label>
                      <Input
                        fieldName={'yearsAtResidence'}
                        fieldType={'number'}
                        placeholder={enterPlaceholder('constants.durationyears')}
                        formState={formState}
                        rules={{
                          required: {
                            value: true,
                            message: isRequired('constants.durationyears'),
                          },
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Group controlId='form-duration-months' className='form-group d-flex align-items-center'>
                      <Form.Label className='mr-2 mb-0'>Months:</Form.Label>
                      <Input
                        fieldName={'monthsAtResidence'}
                        fieldType={'number'}
                        placeholder={enterPlaceholder('constants.durationMonths')}
                        formState={formState}
                        rules={{
                          required: {
                            value: true,
                            message: isRequired('constants.durationMonths'),
                          },
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='form-btn' className='form-group'>
                <Form.Label className='invisible d-none d-md-block'>Secondary Email:</Form.Label>
                <Button type='submit' className='btn-small ml-auto'>
                  {t('constants.update')}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ManualAddress;
