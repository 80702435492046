import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postForgotPassword } from 'services/login';
import { IForgotPasswordState } from 'types/redux/forgotPassword';
import { getExceptionPayload } from '../../services/api';

const initialState: IForgotPasswordState = {
	data: undefined,
	error: undefined,
	loading: false,
	success: false,
};

export const getForgotPassword = createAsyncThunk('forgotPassword', async (params: Object, { rejectWithValue }) => {
	try {
		const response = await postForgotPassword(params);
		return response.message;
	} catch (error) {
		throw rejectWithValue(getExceptionPayload(error));
	}
});

const slice = createSlice({
	name: 'forgotPassword',
	initialState,
	reducers: {
		resetApplications: (state) => {
			state.data = undefined;
			state.error = undefined;
			state.success = false;
		},
	},
	extraReducers(builder): void {
		builder
			.addCase(getForgotPassword.pending, (state) => {
				state.data = undefined;
				state.error = undefined;
				state.loading = true;
			})
			.addCase(getForgotPassword.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
				state.success = true;
			})
			.addCase(getForgotPassword.rejected, (state, action) => {
				state.error = action.error.message as string;
				state.loading = false;
				state.success = false;
			});
	},
});
export const { resetApplications } = slice.actions;
export default slice.reducer;
