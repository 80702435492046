const environment = process.env;

export const baseURL = environment.REACT_APP_API_URL;

export const apiKey = environment.REACT_APP_API_KEY;

export const api = {
  signIn: `dealers/api/customer/signin`,
  signUp: `dealers/api/customer/signup`,
  forgotPassword: `dealers/api/auth/customer/forgetPassword`,
  signOut: `dealers/api/customer/signout`,
  updatePassword: `dealers/api/auth/customer/updatePassword`,
  getApplications: `/leads/api/customer/myApplications`,
  getApplicationDetails: `/leads/api/customer/applicationById/:id`,
  updateApplicationDetails: `/leads/api/customer/updateApplication`,
  calculateIncome: `/leads/api/customer/calculate-income-details`,
  getIncomeDetail: `/leads/api/customer/income-details`,
  getProfileDetails: `dealers/api/customer/profile`,
  putProfileDetails: `dealers/api/customer/profile/:id`,
  putProfileImage: `dealers/api/customer/image/:id`,
  updateDocuments: `/leads/api/customer/uploadDocument`,
  updateDocumentsDetails: `/leads/api/customer/update-doc-details`,
  deleteDocument: `/leads/api/customer/deleteDocument`,
  addEmploymentHistory: `leads/api/customer/addEmploymentHistory`,
  uploadEmploymentHistory: `leads/api/customer/uploadEmploymentHistory`,
  deleteEmploymentHistory: `leads/api/customer/deleteEmploymentHistory`,
  deletePaystubFile: `leads/api/customer/delete-payStub/:leadId/:payStubId`,
  vehicleShared: `inventory/api/customer/vehicle-details`,
  vehicleDetail:`inventory/api/customer/vehicleId`,
  postVehicleSharedPreference: `leads/api/customer/customer-vehicle-preference`,
  compareVehicle: `inventory/api/customer/search/vehicle`,
  deletePreference: `leads/api/customer/delete-vehicle-preference/:leadId/:vehicleId`,
};
