import { onlyCountries as countryList } from 'constants/common'
import React from 'react'
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import { IPhoneNoInput } from './FormComponentInterface';

const PhoneNoInput = ({
	fieldName,
	rules,
	formState,
	country=countryList[0],
	onlyCountries=countryList,
	countryCodeEditable=false,
}:IPhoneNoInput) => {

	const {control, formState:{errors}, getValues, setValue} = formState;

	return (
		<>
			<Controller
				name={fieldName}
				render={({field:{onChange}}) => (
					<PhoneInput
						country={country}
						onlyCountries={onlyCountries}
						countryCodeEditable={countryCodeEditable}
						value={getValues(fieldName) || ''}
						onChange={onChange}
					/>
				)}
				rules={rules}
				control={control}
			/>
			{!!errors?.[fieldName] && <Form.Text className='text-danger'>{errors[fieldName].message}</Form.Text>}
		</>
  )
}

export default PhoneNoInput