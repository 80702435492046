import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import { IIncomeDetails } from 'pages/application/applicationDetailsInterface';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import EditIncome from './EditIncome';
import ShowIncomeDetails from './ShowIncomeDetails';

export default function IncomeDetails(props: IIncomeDetails) {
  const { incomeData, leadId, incomeDetail } = props;
  const [edit, setEdit] = useState(false);

  return (
    <Card>
      <Card.Header>
        <AccordionCustomToggle eventKey='4'>
          <div className='card-toggle--left'>
            <span className='fa-icon rv-income-details' />
            <span className='card-toggle--left-text'>Income Details</span>
          </div>
          <div className='card-toggle--right'>
            <span
              className='fa-icon rv-edit'
              onClick={(e) => {
                e.stopPropagation();
                setEdit(!edit);
              }}
            />
            <div className='accordion-arrow'>
              <span className='fa-icon rv-pointer-down' />
            </div>
          </div>
        </AccordionCustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey='4'>
        <Card.Body>
          {edit ? (
            <EditIncome setEdit={setEdit} incomeDetail={incomeDetail} leadId={leadId} />
          ) : (
            <ShowIncomeDetails incomeData={incomeData} />
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
