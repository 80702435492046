import AccordionCard from 'components/accordion-custom-toggle/AccordionCard';
import { IFormItem } from 'components/form-components/FormComponentInterface';
import RowCol from 'components/form-components/RowCol';
import { IVehicleDetails, ShowVehicleDetailsAccordion } from 'pages/vehicle-garage/VehicleInterface';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

const VehicleDetailsAccordions = (props: ShowVehicleDetailsAccordion) => {
  const { label, getDataFn, eventKey, icon } = props;
  const [data, setData] = useState<IFormItem[]>();
  const { preferenceData }: IVehicleDetails = useAppSelector((state: RootState) => state.vehicleDetails);

  useEffect(() => {
    if (preferenceData) {
      setData(getDataFn(preferenceData));
    }
  }, [preferenceData]);

  return (
    <AccordionCard
      header={
        <div className='card-toggle--left'>
          <span className='fa-icon rv-pointer-down' />
          <span className={`fa-icon ${icon}`} />
          <span className='card-toggle--left-text'>{label}</span>
        </div>
      }
      eventKey={eventKey}
    >
      <RowCol formData={data} />
    </AccordionCard>
  );
};

export default VehicleDetailsAccordions;
