import { vehicleDetailsBreadcrumb } from 'components/breadcrumbs/breadcrumbsHelper';
import InputRadioButton from 'components/form-components/InputRadioButton';
import Layout from 'components/layout/Layout';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getVehicleSharedPreferenceData } from 'store/vehicle-share/slice';
import VehicleDetailsView from './components/VehicleDetailsView';
import VehicleLeftSideBar from './components/VehicleLeftSideBar';
import VehicleListView from './components/VehicleListView';
import { getVehicleDetailsData, getView } from './store/VehicleDetailSlice';
import { LeadDetails } from './VehicleInterface';

const VehicleGarageWrapper = () => {
  const dispatch = useAppDispatch();
  const { leadId, vehicleId } = useParams();
  
  const applicationDetails: LeadDetails = useAppSelector(
    (state: RootState) => state.applicationDetails.data?.leadDetails
  );
  const listView = useAppSelector((state: RootState) => state.vehicleDetails.listView);

  useEffect(() => {
    if (leadId) {
      dispatch(getApplicationsDetails(leadId));
    }
  }, [leadId]);

  const getPreferenceListView = () => {
    if (leadId) {
      dispatch(getVehicleSharedPreferenceData({ id: leadId, type: 'customerVehiclePreference' }));
    }
  };

  useEffect(() => {
    if (vehicleId) {
      dispatch(getVehicleDetailsData(vehicleId));
    }
  }, [vehicleId]);

  const toggleExpandAll = (value: boolean) => {
    dispatch(getView(value));
  };

  return (
    <Layout heading='Vehicle Details' breadcrumb={vehicleDetailsBreadcrumb(applicationDetails, leadId)}>
      <section className='details-page details-page-with-timeline'>
        <div className='details-page--left'>
          <VehicleLeftSideBar />
        </div>
        <div className='details-page--right'>
          <div className='details-page--right-with-border'>
            <div className='d-flex justify-content-end mb-4'>
              <div className='switch-toggle'>
                <InputRadioButton
                  id='radio-one'
                  name='switch-one'
                  value='yes'
                  handleOnClick={() => {
                    toggleExpandAll(true);
                    getPreferenceListView();
                  }}
                  checked={listView}
                  label='List View'
                />
                <InputRadioButton
                  id='radio-two'
                  name='switch-one'
                  value='no'
                  handleOnClick={() => {
                    toggleExpandAll(false);
                  }}
                  checked={!listView}
                  label='Details View'
                />
              </div>
            </div>
            {listView ? <VehicleListView /> : <VehicleDetailsView />}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default VehicleGarageWrapper;
