import React, { forwardRef, useState } from 'react';
import { ICustomMenuProps } from './HeaderInterface';

export const DropDownMenu = forwardRef((props: ICustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
  const [value, setValue] = useState('');

  return (
    <div ref={ref} style={props.style} className={props.className} aria-labelledby={props.labeledBy}>
      <ul className='list-unstyled'>
        {React.Children.toArray(props.children).filter(
          (child: any) => !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </ul>
    </div>
  );
});
