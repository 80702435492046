import ListGroup from "react-bootstrap/ListGroup";
import { ILeadDetails } from "../applicationDetailsInterface";
import { TIMELINE } from "../constants";
import { getValue } from "../helper";

export default function Timeline(props: ILeadDetails) {
  const { applicationDetailData } = props;

  return (
    <ListGroup as="ul" bsPrefix=" " className="list-unstyled timeline-bar">
      {TIMELINE.map((timeline) => {
        const value =
          timeline.value === "" ||
          getValue({ ...applicationDetailData }, timeline.value);
        return (
          <ListGroup.Item
            as="li"
            bsPrefix=" "
            key={timeline.label}
            className={`${!value ? "active" : ""}`}
          >
            <div className="timeline-circle">
              <span className="fa-icon rv-check" />
            </div>
            <label className="text-uppercase">{timeline.label}</label>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}
