import { IProfileDetail } from 'pages/profile/ProfileInterface';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ShowPersonalInfo = (props: IProfileDetail) => {
  const { profileData } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={12} md={6} lg={4}>
        <Form.Group controlId='form-name' className='form-group'>
          <Form.Label>{t('constants.firstName')}</Form.Label>
          <span className='read-text'>{profileData?.firstName ?? '-'}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4}>
        <Form.Group controlId='form-sin' className='form-group'>
          <Form.Label>{t('constants.lastName')}</Form.Label>
          <span className='read-text'>{profileData?.lastName ?? '-'}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4}>
        <Form.Group controlId='form-birth' className='form-group'>
          <Form.Label>{t('constants.phone')}</Form.Label>
          <span className='read-text'>{profileData?.cellPhone ?? '-'}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4}>
        <Form.Group controlId='form-lang' className='form-group'>
          <Form.Label>{t('constants.primary-email')}</Form.Label>
          <span className='read-text'>{profileData?.username ?? '-'}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4}>
        <Form.Group controlId='form-gender' className='form-group'>
          <Form.Label>{t('constants.secondary-email')}</Form.Label>
          <span className='read-text'>{profileData?.secondaryEmail ?? '-'}</span>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ShowPersonalInfo;
