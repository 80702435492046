import moment from 'moment';
import { dateFormatWithSlash, dateFormatWithSlashMonth } from 'constants/dateTime';

export const dateFormatter = (value?: any, format?: string) => {
  const newDate = new Date(value);
  return moment(newDate).format(format);
};

export const isValidDate = (dateObject: string) => {
  return (
    new Date(dateObject).toString() !== 'Invalid Date' ||
    moment(dateObject, dateFormatWithSlash) ||
    moment(dateObject, dateFormatWithSlashMonth)
  );
};

export const dateOfBirthValidation = (dob: string) => {
  if (isValidDate(dob)) {
    const calculatedAge = Math.floor(moment().diff(moment(dob, 'MM/DD/YYYY'), 'years', true));
    if (calculatedAge < 18) {
      return 'Age should be greater than or equals to 18';
    } else if (calculatedAge >= 18) {
      return '';
    }
  } else {
    return 'Invalid Date';
  }
};

export const maxDate = (fieldName: string) => {
  if (fieldName === 'birthDate') {
    const newDate = moment().subtract(18, 'years').format();
    return new Date(newDate);
  }
};

export const minDateForDateInput = (fieldName: string) => {
  if (fieldName !== 'birthDate') {
  return new Date();
  }
};

export const minDate = () => {
  return moment().subtract(500, 'years');
};

export const convertBirthDate = (date: any) => {
  const formattedDate = new Date(date);
  const monthYearFormattedDate = new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
  const dateMonthYearFormattedDate = new Date(date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'));
  return formattedDate.toString() !== 'Invalid Date'
    ? formattedDate
    : monthYearFormattedDate.toString() !== 'Invalid Date'
    ? monthYearFormattedDate
    : dateMonthYearFormattedDate;
};
