import Modal from 'react-bootstrap/Modal';
import { IShowSuccessMessageModal } from './CustomModalInterface';
import Check from 'scss/images/login-check.svg';
import ShowMessageModal from './ShowMessageModal';


const ShowSuccessMessageModal:React.FC<IShowSuccessMessageModal> = (props) => {
  const { show, handleClose, classNames, title, text } = props;
  return (

    <ShowMessageModal show={show} handleClose={handleClose} classNames={classNames}>
      <>
        <img src={Check} alt='check' />
        <h2 className='heading-1'>{title}</h2>
        <p>{text}</p>
      </>
    </ShowMessageModal>
  );
};

export default ShowSuccessMessageModal;
