import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { putApplicationDetails } from 'services/application';
import { IPutProfileDetails, IPutProfileStatus } from 'types/redux/profileDetails';
import { APIStatus, getExceptionPayload } from '../../services/api';

const initialState: IPutProfileStatus = {
	data: undefined,
	error: undefined,
	loading: false,
	putStatus: {
		status: APIStatus.IDLE,
	},
};

export const putApplicationDetail = createAsyncThunk(
	'applicationDetails/put',
	async (params: IPutProfileDetails, { rejectWithValue }) => {
		try {
			const response = await putApplicationDetails(params?.id, params);
			return response.message;
		} catch (error) {
			throw rejectWithValue(getExceptionPayload(error));
		}
	}
);

const slice = createSlice({
	name: 'applicationDetails',
	initialState,
	reducers: {
		resetHomeDetailsUpdate: (state) => {
			state.data = undefined;
			state.error = undefined;
		},
		clearRequest: (state) => {
			state.data = undefined;
			state.error = undefined;
			state.putStatus = initialState.putStatus;
		},
	},

	extraReducers(builder): void {
		builder
			.addCase(putApplicationDetail.pending, (state) => {
				state.data = undefined;
				state.error = undefined;
				state.putStatus.status = APIStatus.PENDING;
				state.loading = true;
			})
			.addCase(putApplicationDetail.fulfilled, (state, action) => {
				state.data = action.payload;
				state.putStatus.status = APIStatus.FULFILLED;
				state.loading = false;
			})
			.addCase(putApplicationDetail.rejected, (state, action) => {
				state.error = action.error.message as string;
				state.putStatus.status = APIStatus.REJECTED;
				state.loading = false;
			});
	},
});
export const { resetHomeDetailsUpdate, clearRequest } = slice.actions;
export default slice.reducer;
