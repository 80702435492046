export const GENDER = {
  M: 'Male',
  F: 'Female',
};

export const MARITAL_STATUS_OPTIONS = [
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Married', value: 'M' },
  { label: 'Common Law', value: 'CL' },
  { label: 'Widow/Widower', value: 'Widow/Widower' },
  { label: 'Separated', value: 'Separated' },
  { label: 'Single', value: 'S' },
];

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'M',
  },
  { label: 'Female', value: 'F' },
  { label: 'X', value: 'X' },
];

export const LANGUAGES_OPTIONS = [
  { label: 'English', value: 'english' },
  { label: 'French', value: 'french' },
];

export const onlyCountries = ['ca', 'us'];

export const commonQueryParams = {
  filter: {},
  sort: {
    createdAt: 'desc',
  },
  pagination: {
    page: 1,
    limit: 10,
  },
};

export const phoneFormat = '+# (###) ###-####';

export enum ToastStatusEnum {
  Error = 'Error',
  Warning = 'Warning',
  Success = 'Success',
}

export const SUBMITTED = 'Submitted';

export const DELETED = 'Deleted';

export const DELETE_CONFIRMATION = 'Are you sure you want to delete?';

export const REQUIRED_CONFIRMATION='Required Confirmation'

export const jwtExpired = 'jwt expired';

