import AddressAutoComplete from "components/form-components/AddressAutoComplete";
import DatePickerInput from "components/form-components/DatePickerInput";
import FileUpload from "components/form-components/FileUpload";
import { IAddressAutoCompleteResponse } from "components/form-components/FormComponentInterface";
import Input from "components/form-components/Input";
import InputNumber from "components/form-components/InputNumber";
import InputSelect from "components/form-components/InputSelect";
import PhoneNoInput from "components/form-components/PhoneNoInput";
import { onlyCountries } from "constants/common";
import { isRequired } from "helpers/errorMessageHelper";
import { getSelectedOption } from "helpers/helper";
import { enterPlaceholder, selectPlaceholder } from "helpers/placeHoldersHelper";
import { emptyPhone, invalidPhone } from 'helpers/validationHelpers';
import { employmentStatusOption, employmentTypeOptions, frequencyTypeOptions, occupationTypeOptions } from "pages/application/constants";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { getApplicationsDetails } from "store/application-detail/action";
import { deletePaystubFile, postEmployeeHistory, updateEmployeeHistory } from "store/empHistory/slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IApplicationDetail, IDeletePaystubParams, IEmploymentHistoryData, IEmploymentHistoryState } from "types/redux/applicationDetails";

const AddNewEmp: React.FC<{ isEdit: boolean; editData?: any | null }> = ({ isEdit, editData }) => {
  const formState = useForm<IEmploymentHistoryData>();
  const { handleSubmit, setValue, clearErrors, register, formState:{errors} } = formState;
  const { t } = useTranslation();
  const applicationDetails: IApplicationDetail = useAppSelector((state: RootState) => state.applicationDetails);
  const { addEmployeeHistoryPending, updateEmpHistoryPending }: IEmploymentHistoryState =
    useAppSelector((state: RootState) => state.empHistory);
  const { id: leadId } = useParams();
  const dispatch = useAppDispatch();
  const [newPaystubFiles, setNewPaystubFiles] = useState<File[]>([]);
  const [existingPaystubFiles, setExistingPaystubFiles] = useState<{}[]>([]);
  const isRequesting = addEmployeeHistoryPending || updateEmpHistoryPending;

  useEffect(() => {
    if (isEdit) {
      const employeeStatusFilter = getSelectedOption(employmentStatusOption, editData?.employmentStatus);
      const employmentTypeFilter = getSelectedOption(employmentTypeOptions, editData?.employmentType);
      const occupationTypeFilter = getSelectedOption(occupationTypeOptions, editData?.occupation);
      const frequencyFilter = getSelectedOption(frequencyTypeOptions, editData?.frequency);

      setValue('employmentStatus', employeeStatusFilter);
      setValue('employmentType', employmentTypeFilter);
      setValue('occupation', occupationTypeFilter);
      setValue('frequency', frequencyFilter);
      setValue('employer', editData?.employer);
      setValue('address', editData?.address);
      setValue('employerCity', editData?.employerCity);
      setValue('employerProvince', editData?.employerProvince);
      setValue('employerPostalCode', editData?.employerPostalCode);
      setValue('employerStreetDirection', editData?.employerStreetDirection);
      setValue('employerStreetNumber', editData?.employerStreetNumber);
      setValue('employerStreetName', editData?.employerStreetName);
      setValue('employerStreetType', editData?.employerStreetType);
      setValue('employerSuiteNumber', editData?.employerSuiteNumber);
      setValue('employerPhoneNumber', editData?.employerPhoneNumber?.toString() || '1');
      setValue(
        'employerPhoneExtension',
        editData?.employerPhoneExtension ? editData?.employerPhoneExtension?.toString() : '1'
      );
      setValue('yearsAtJob', editData?.yearsAtJob);
      setValue('monthsAtJob', editData?.monthsAtJob);
      setValue('income', editData?.income);
      setValue('yearsAtJob', editData?.yearsAtJob);
      setValue('durationFrom', editData.duration.from ? new Date(editData.duration.from) : '');
      setValue('durationTo', editData.duration.to ? new Date(editData.duration.to) : '');

      setExistingPaystubFiles(editData.paystub);
      
    }
  }, [isEdit]);

  const onSubmit = handleSubmit((data) => {
    const duration = {
      from: data.durationFrom,
      to: data.durationTo,
    };

    const formData = new FormData();

    for (const file of newPaystubFiles) {
      formData.append('document', file);
    }

    formData.append('duration', JSON.stringify(duration));
    formData.append('leadId', leadId?.toString() || '');
    formData.append('employer', data?.employer);
    formData.append('employmentType', data?.employmentType?.value);
    formData.append('occupation', data?.occupation?.value);
    formData.append('income', data?.income?.replace(/,/g, '') ?? '');
    formData.append('frequency', data?.frequency?.value ?? '');
    formData.append('employerPhoneNumber', data?.employerPhoneNumber?.toString());
    formData.append('employerPhoneExtension', data?.employerPhoneExtension?.toString());
    formData.append('address', data?.address);
    formData.append('employerCity', data?.employerCity);
    formData.append('employerPostalCode', data?.employerPostalCode);
    formData.append('employerStreetNumber', data?.employerStreetNumber);
    formData.append('employerStreetName', data?.employerStreetName);
    formData.append('employerSuiteNumber', data?.employerSuiteNumber);
    formData.append('employerProvince', data?.employerProvince);
    formData.append('employmentStatus', data?.employmentStatus?.value);
    formData.append('employerStreetType', data?.employerStreetType);
    formData.append('employerStreetDirection', data?.employerStreetDirection);
    formData.append('yearsAtJob', data?.yearsAtJob);
    formData.append('monthsAtJob', data?.monthsAtJob);

    if (isEdit) {
      formData.append('employmentId', editData._id);
      dispatch(updateEmployeeHistory(formData));
    } else {
      dispatch(postEmployeeHistory(formData));
    }
  });

  const onAddressSelect = (result:IAddressAutoCompleteResponse) => {

    setValue('address', `${result.streetNumber}, ${result.routeAddress}`);
    setValue('employerCity', result?.city);
    setValue('employerProvince', result?.province);
    setValue('employerPostalCode', result?.postalCode);
    setValue('employerStreetNumber', result?.streetNumber);
    setValue('employerStreetName', result?.streetName);
    setValue('employerSuiteNumber', result?.suiteNumber);
  }

  const onRemoveExistingPaystubFile = async(file:any) => {
    if(leadId){
      const params:IDeletePaystubParams = {
        leadId, 
        payStubId: file._id,
      }
      await dispatch(deletePaystubFile(params));
      await dispatch(getApplicationsDetails(leadId));
    }
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group controlId='modal-emp-status' className='form-group'>
              <Form.Label>{t('constants.employmentStatus')}</Form.Label>
              <InputSelect
                options={employmentStatusOption}
                fieldName='employmentStatus'
                formState={formState}
                placeholder={selectPlaceholder('constants.employmentStatus')}
              />
            </Form.Group>
          </Col>
          <>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-emp-type' className='form-group'>
                <Form.Label>{t('constants.employmentType')}</Form.Label>
                <InputSelect
                  options={employmentTypeOptions}
                  fieldName='employmentType'
                  formState={formState}
                  placeholder={selectPlaceholder('constants.employmentType')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.employmentType'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-emp' className='form-group'>
                <Form.Label>{t('constants.employer')}</Form.Label>
                <Input
                  fieldName={'employer'}
                  placeholder={enterPlaceholder('constants.employer')}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-occ' className='form-group'>
                <Form.Label>{t('constants.occupation')}</Form.Label>
                <InputSelect
                  options={occupationTypeOptions}
                  fieldName='occupation'
                  formState={formState}
                  placeholder={selectPlaceholder('constants.occupation')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.occupation'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-add' className='form-group'>
                <Form.Label>{t('constants.address')}</Form.Label>
                <AddressAutoComplete
                  placeholder={enterPlaceholder('constants.address')}
                  formState={formState}
                  fieldName="address"
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.address'),
                    },
                  }}
                  onAddressSelect={onAddressSelect}
                />

              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-city' className='form-group'>
                <Form.Label>{t('constants.city')}</Form.Label>
                <Input
                  fieldName={'employerCity'}
                  placeholder={enterPlaceholder('constants.city')}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.city'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-province' className='form-group'>
                <Form.Label>{t('constants.provience')}</Form.Label>
                <Input
                  fieldName={'employerProvince'}
                  placeholder={enterPlaceholder('constants.provience')}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.provience'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-postal-code' className='form-group'>
                <Form.Label>{t('constants.postalCode')}</Form.Label>
                <Input
                  fieldName={'employerPostalCode'}
                  placeholder={enterPlaceholder('constants.postalCode')}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.postalCode'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-street-type' className='form-group'>
                <Form.Label>{t('constants.streetType')}</Form.Label>
                <Input
                  fieldName={'employerStreetType'}
                  placeholder={enterPlaceholder('constants.streetType')}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-direction' className='form-group'>
                <Form.Label>{t('constants.direction')}</Form.Label>
                <Input
                  fieldName={'employerStreetDirection'}
                  placeholder={enterPlaceholder('constants.direction')}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-street-number' className='form-group'>
                <Form.Label>{t('constants.streetNumber')}</Form.Label>
                <Input
                  fieldName={'employerStreetNumber'}
                  placeholder={enterPlaceholder('constants.streetNumber')}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.streetNumber'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-street-name' className='form-group'>
                <Form.Label>{t('constants.streetName')}</Form.Label>
                <Input
                  fieldName={'employerStreetName'}
                  placeholder={enterPlaceholder('constants.streetName')}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-suite-number' className='form-group'>
                <Form.Label>{t('constants.suiteNumber')}</Form.Label>
                <Input
                  fieldName={'employerSuiteNumber'}
                  placeholder={enterPlaceholder('constants.suiteNumber')}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-phone-number' className='form-group'>
                <Form.Label>{t('constants.phoneNumber')}</Form.Label>
                <PhoneNoInput
                  country={onlyCountries[0]}
                  onlyCountries={onlyCountries}
                  countryCodeEditable={false}
                  formState={formState}
                  fieldName='employerPhoneNumber'
                  rules={{
                    validate: {
                      emptyPhone: (v:string) => emptyPhone(v, 'constants.phoneNumber'),
                      invalidPhone: (v:string) => invalidPhone(v, 'constants.phoneNumber'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-phone-ext' className='form-group'>
                <Form.Label>{t('constants.employerPhoneExtension')}</Form.Label>
                <PhoneNoInput
                  country={onlyCountries[0]}
                  onlyCountries={onlyCountries}
                  countryCodeEditable={false}
                  formState={formState}
                  fieldName='employerPhoneExtension'
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-time-job-yrs' className='form-group'>
                <Form.Label>{t('constants.yearsAtJob')}</Form.Label>
                <InputNumber
                  fieldName='yearsAtJob'
                  placeholder={enterPlaceholder('constants.yearsAtJob')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.yearsAtJob'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-time-job-months' className='form-group'>
                <Form.Label>{t('constants.monthsAtJob')}</Form.Label>
                <InputNumber
                  fieldName='monthsAtJob'
                  placeholder={enterPlaceholder('constants.monthsAtJob')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.monthsAtJob'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-income' className='form-group'>
                <Form.Label>{t('constants.income')}</Form.Label>
                <InputNumber
                  fieldName='income'
                  placeholder={enterPlaceholder('constants.income')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.income'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-frequency' className='form-group'>
                <Form.Label>{t('constants.frequency')}</Form.Label>
                <InputSelect
                  options={frequencyTypeOptions}
                  fieldName='frequency'
                  formState={formState}
                  placeholder={selectPlaceholder('constants.frequency')}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.frequency'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='modal-duration' className='form-group mb-0'>
                <Form.Label>{t('constants.duration')}</Form.Label>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group className='form-group'>
                      <DatePickerInput
                        fieldName='durationFrom'
                        rules={{
                          required: { value: true, message: isRequired('constants.durationFrom') },
                        }}
                        formState={formState}
                        className='form-control'
                        datepickerOptions={{maxDate: new Date()}}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group className='form-group'>
                      <DatePickerInput
                        fieldName='durationTo'
                        rules={{
                          required: { value: true, message: isRequired('constants.durationTo') },
                        }}
                        formState={formState}
                        className='form-control'
                        datepickerOptions={{maxDate: new Date()}}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </>
          <Col sm={12} md={6}>
            <Form.Group controlId='modal-pay-stubs' className='form-group'>
              <Form.Label>Pay Stubs</Form.Label>
              <FileUpload
                isMultiple={true}
                existingFiles={existingPaystubFiles}
                newUploadedFiles={newPaystubFiles}
                setNewUploadedFiles={setNewPaystubFiles}
                onExistingFileRemove={onRemoveExistingPaystubFile}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} className='d-flex align-items-end justify-content-end'>
            <Button variant='primary' type='submit' className='btn-small' disabled={isRequesting}>
              {isRequesting ? 'Sending...' : 'Submit'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddNewEmp;
