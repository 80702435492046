import { RouteEnum } from 'components/routes/RouteEnum';
import { getUserName } from 'helpers';
import { ILeadDetailsData } from 'pages/application/applicationDetailsInterface';
import { LeadDetails } from 'pages/vehicle-garage/VehicleInterface';

export const applicationBreadcrumbs = (applicationDetailData: ILeadDetailsData | undefined) => {
  return [
    { label: 'Applications', link: RouteEnum.Applications },
    { label: getUserName(applicationDetailData) },
  ];
};

export const vehicleDetailsBreadcrumb = (applicationDetails: LeadDetails, leadId: string | undefined) => {
  return [
    { label: 'Applications Details', link: `${RouteEnum.ApplicationDetail}/${leadId}` },
    {
      label: getUserName(applicationDetails),
    },
  ];
};
