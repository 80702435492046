import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { IListItem } from 'components/form-components/FormComponentInterface';
import SideBarListGroup from 'components/form-components/SidebarListGroup';
import { DDMMYYYY } from 'constants/dateTime';
import { getUserName } from 'helpers';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { getApplicantAvatar, getListItem } from '../VehicleHelper';
import { LeadDetails } from '../VehicleInterface';

const VehicleLeftSideBar = () => {
  const [showDetails, setShowProfileDetails] = useState<boolean>(false);
  const [listData, setListData] = useState<IListItem[]>();
  const applicationDetails: LeadDetails = useAppSelector(
    (state: RootState) => state.applicationDetails.data?.leadDetails
  );

  const profileClickHandler = () => {
    setShowProfileDetails(!showDetails);
  };

  useEffect(() => {
    if (applicationDetails) {
      setListData(getListItem(applicationDetails));
    }
  }, [applicationDetails]);

  return (
    <div className='profile-panel'>
      <div className='profile-circle' onClick={profileClickHandler}>
        <ProfileAvatar image={applicationDetails?.imageUrl} name={getApplicantAvatar(applicationDetails)} />
      </div>
      <h2 className='heading-6'>{getUserName(applicationDetails)}</h2>
      <h4 className='profile-panel--subtitle'>{`Active Since: ${dateFormatter(
        applicationDetails?.createdAt,
        DDMMYYYY
      )}`}</h4>

      <SideBarListGroup showDetails={showDetails} listData={listData} />
    </div>
  );
};
export default VehicleLeftSideBar;
