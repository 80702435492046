import VehicleImage from 'components/form-components/VehicleImage';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { HEADING, STATUS, VEHICLE_TYPE } from '../VehicleConstants';
import { getVehicleImageHeading } from '../VehicleHelper';
import { Images } from '../VehicleInterface';

const SlideVehicleImages = () => {
  const vehicleData = useAppSelector((state: RootState) => state.vehicleDetails.preferenceData);

  const properties = {
    duration: 5000,
    autoplay: false,
    transitionDuration: 500,
    arrows: vehicleData?.images?.length ? true : false,
    infinite: true,
  };

  return (
    <div className='slide-container'>
      {!!vehicleData?.images?.length && (
        <Slide {...properties}>
          {vehicleData?.images?.map((item: Images, index: number) => (
            <div key={index} className='slide'>
              {VehicleImage(item?.path)}
              <div className='slide-text-wrap'>
                <h2 className='heading-2'>{getVehicleImageHeading(vehicleData, HEADING)}</h2>
                <div className='d-flex align-items-center'>
                  <label className='slide-badge purple'>{getVehicleImageHeading(vehicleData, STATUS)}</label>
                  <label className='slide-badge green'>{getVehicleImageHeading(vehicleData, VEHICLE_TYPE)}</label>
                </div>
              </div>
            </div>
          ))}
        </Slide>
      )}
    </div>
  );
};

export default SlideVehicleImages;
