import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Viewer from 'react-viewer';
import DemoCar from 'scss/images/demo-car.png';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PreferenceDataImages, vehicleCompareSingleProp } from 'types/redux/applicationDetails';
import { IVehicleSharedImage } from '../../applicationDetailsInterface';
import { vehicleDetails } from '../../constants';
import CompareVehiclesGallery from './CompareVehiclesGallery';


export default function VehiclesCompare(props: IVehicleSharedImage) {
  const [showDetails, setShowDetails] = useState<PreferenceDataImages[]>([]);
  const { setSelectedImage } = props;

  const compareData: PreferenceDataImages[] = useAppSelector((state: RootState) => state.vehicleShared.compareData);
  
  const [showSlideshow, setShowSlideshow] = useState<boolean>(false);
  const [slideshowImages, setSlideshowImages] = useState<{src:string, alt:string}[]>([]);

  useEffect(() => {
    if (!isEmpty(compareData)) setShowDetails(compareData);
  }, [compareData]);

  const selectToTrim = (vehicle: any) => {
    setSelectedImage((prevState: any) => {
      const vehicleData = vehicle?.['VehicleId'];
      return prevState.includes(vehicleData)
        ? prevState.filter((el: any) => el !== vehicleData)
        : [...prevState, vehicleData];
    });
    const trimData = showDetails.filter((el: any) => el?.VehicleId !== vehicle?.VehicleId);
    setShowDetails(trimData);
  };

  const getVehicleData = (label: vehicleCompareSingleProp) => {
    return (
      <>
        {!isEmpty(showDetails) && (
          <td className='data-1'>
            <p>{label}</p>
          </td>
        )}
        {showDetails.map((item: PreferenceDataImages,index:number) => {
          return (
            <td className='data-2' key={index}>
              { <p>{item[label] ?? '-'}</p> }
            </td>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Table responsive className='compare-table'>
        <thead>
          <tr>
            <th className='data-1' />
            {showDetails?.map((item: any, index: number) => {
              return (
                <th className='data-2' key={index}>
                  <div className='vehicle-image'>
                    <img src={item?.images[0]?.path ?? DemoCar} className='mb-2'/>
                    <Button
                      variant='override'
                      className='btn-small w-100'
                      onClick={() => selectToTrim(item)}
                    >
                      Select This Trim
                    </Button>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='data-1'>
              <p>Gallery</p>
            </td>
            {showDetails.map((item: PreferenceDataImages) => (
              <CompareVehiclesGallery 
                key={item._id} 
                images={item.images} 
                setShowSlideshow={setShowSlideshow} 
                setSlideshowImages={setSlideshowImages}  
              />
            ))}
          </tr>
          {vehicleDetails.map((item) => (
            <tr key={item}>{getVehicleData(item)}</tr>
          ))}
        </tbody>
      </Table>

      <Viewer
        visible={showSlideshow}
        onClose={() => { setShowSlideshow(false); } }
        images={slideshowImages}
        zoomSpeed={0.5}
        disableMouseZoom
        zoomable={true}
        downloadable={false}
        zIndex={1100}
      />
    </>
  );
}
