import Layout from '../../components/layout/Layout';
import './application-listing.scss';
import DataTable from '../../components/data-table/DataTable';
import { useState, useEffect, useMemo } from 'react';
import { loadColumns, stringifyAndRedirect } from './helper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getApplicationsListing } from '../../store/application-listing/slice';
import { RootState } from '../../store';
import { IQueryParams } from '../../components/data-table/DataTableInterface';
import { TableChangeHandler, TableChangeType } from 'react-bootstrap-table-next';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { commonQueryParams } from 'constants/common';
import Loader from 'components/loader/Loader';

const ApplicationListing = () => {
  const [columns, setColumns] = useState<any>([]);
  const [queryParams, setQueryParams] = useState<any>(commonQueryParams);
  const defaultSorted = [
    {
      dataField: 'createdAt',
      order: 'desc',
    },
  ];

  const applications = useAppSelector((state: RootState) => state.applications);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    loadColumns(setColumns, queryParams);
  }, [queryParams]);

  useEffect(() => {
    const qpFromLocation: any = queryString.parse(location.search);
    if (qpFromLocation && qpFromLocation.pagination && qpFromLocation.sort && qpFromLocation.filter) {
      qpFromLocation.pagination = JSON.parse(qpFromLocation.pagination);
      qpFromLocation.sort = JSON.parse(qpFromLocation.sort);
      qpFromLocation.filter = JSON.parse(qpFromLocation.filter);
      setQueryParams(qpFromLocation);
      dispatch(getApplicationsListing(qpFromLocation));
    } else {
      dispatch(getApplicationsListing(queryParams));
    }
  }, [location.search]);

  const onTableChange: TableChangeHandler<any> = (type: TableChangeType, newState: any) => {
    const { page, sizePerPage, filters, sortField, sortOrder } = newState;
    // const currentIndex = (page - 1) * sizePerPage;
    const tableFilters: IQueryParams = {
      ...queryParams,
      pagination: {
        page: page,
        limit: sizePerPage,
      },
    };

    if (sortField) {
      tableFilters.sort = {
        [sortField]: sortOrder,
      };
    }

    tableFilters.filter['firstName'] = filters.firstName ? filters.firstName.filterVal : '';
    tableFilters.filter['lastName'] = filters.lastName ? filters.lastName.filterVal : '';
    tableFilters.filter['email'] = filters.email ? filters.email.filterVal : '';
    tableFilters.filter['phone'] = filters.phone ? filters.phone.filterVal : '';
    tableFilters.filter['leadId'] = filters.leadId ? filters.leadId.filterVal : '';
    tableFilters.filter['createdAt'] = filters.createdAt ? filters.createdAt.filterVal : '';
    stringifyAndRedirect(tableFilters, navigate, queryString);
  };

  const renderDataTable = useMemo(() => {
    return (
      <DataTable
        searchPlaceholder={'Search by id or name'}
        columns={columns}
        rows={applications?.data?.docs}
        queryParams={queryParams}
        onTableChange={onTableChange}
        keyField={'_id'}
        tableTitle={'Your Applications'}
        defaultSorted={defaultSorted}
      />
    );
  }, [applications]);

  return (
    <Layout heading={'Applications'}>
      {applications?.loading ? <Loader /> : <section className='listing-page'>{renderDataTable}</section>}
    </Layout>
  );
};

export default ApplicationListing;
