import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { IDropdown } from './CustomeDropDowmInterface';

export const CustomDropDown = forwardRef((props: IDropdown, ref: React.Ref<HTMLAnchorElement>) => {
  return (
    <Link
      to='#'
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </Link>
  );
});
