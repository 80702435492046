import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import loginReducer from './login/slice';
import applicationsReducer from './application-listing/slice';
import applicationDetailsReducer from './application-detail/slice';
import signUpReducer from './sign-up/slice';
import profileReducer from './profile-detail/slice';
import putProfileReducer from './profile-update/slice';
import forgotPasswordReducer from './forgot-password/slice';
import UpdatePasswordReducer from './update-password/slice';
import incomeDetailsReducer from './income-details/slice';
import postIncomeReducer from './post-income/slice';
import homeDetailsReducer from './home-details/slice';
import uploadDocumentReducer from './update-files/slice';
import empHistory from './empHistory/slice';
import errorToast from './error-toast/slice';
import errorToastMiddleware from './middlewares/errorToastMiddleware';
import vehicleSharedReducer from './vehicle-share/slice';
import vehicleDetailsReducer from 'pages/vehicle-garage/store/VehicleDetailSlice'

const combinedReducer = combineReducers({
	signUp: signUpReducer,
	login: loginReducer,
	forgotPassword: forgotPasswordReducer,
	updatePassword: UpdatePasswordReducer,
	profile: profileReducer,
	putProfile: putProfileReducer,
	applications: applicationsReducer,
	applicationDetails: applicationDetailsReducer,
	incomeDetails: incomeDetailsReducer,
	postIncomeDetails: postIncomeReducer,
	homeDetails: homeDetailsReducer,
	uploadDocument: uploadDocumentReducer,
	empHistory: empHistory,
	errorToast: errorToast,
	vehicleShared: vehicleSharedReducer,
    vehicleDetails: vehicleDetailsReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'login/signOut/fulfilled') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorToastMiddleware()),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export default store;
