import { useState } from 'react';
import { Button, Form, Spinner, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Input from '../../components/form-components/Input';
import { RootState } from '../../store';
import { useAppSelector } from '../../store/hooks';
import { ILoginPageState } from '../../types/redux/login';
import { ILoginForm } from './LoginInterface';

export default function LoginForm({ onSubmit, formState }: ILoginForm) {
  const { t } = useTranslation();
  const [viewPassword, setViewPassword] = useState(false);
  const loginState: ILoginPageState | any = useAppSelector((state: RootState) => state.login);

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className='form-group' controlId='formGroupEmail'>
        <Form.Label>{t('constants.email')}</Form.Label>
        <Input
          fieldName={'username'}
          fieldType={'email'}
          placeholder={t('constants.email-placeholder')}
          rules={{
            required: {
              value: true,
              message: t('errorConstants.email-required'),
            },
          }}
          formState={formState}
        />
      </Form.Group>
      <Form.Group className='form-group' controlId='formGroupPassword'>
        <Form.Label>{t('constants.password')}</Form.Label>
        <div className='input-with-icon'>
          <Input
            fieldName={'password'}
            fieldType={viewPassword ? 'text' : 'password'}
            placeholder={t('constants.password-placeholder')}
            rules={{
              required: {
                value: true,
                message: t('errorConstants.password-required'),
              },
            }}
            formState={formState}
          />
          <span
            onClick={() => setViewPassword(!viewPassword)}
            className={`fa-icon ${viewPassword ? 'rv-eye-on' : 'rv-eye-off'}`}
          />
        </div>
      </Form.Group>
      <div className='d-flex justify-content-end'>
        <Link to='/forgot-password' className='login-forgot-pass'>
          {t('constants.forgot-password')}
        </Link>
      </div>
      <Row>
        <Col sm={12} md={6} className='d-flex align-items-center'>
          <Link to='/' className='align-middle back-btn'>
            Back
          </Link>
        </Col>
        <Col sm={12} md={6}>
          <Button type='submit' className='ml-auto'>
            {t('constants.login')} {loginState?.loading && <Spinner className='ml-2' animation='border' role='status' />}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
