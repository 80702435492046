import { applicationBreadcrumbs } from 'components/breadcrumbs/breadcrumbsHelper';
import Loader from 'components/loader/Loader';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getIncomeDetails } from 'store/income-details/slice';
import { getVehicleSharedData, getVehicleSharedPreferenceData } from 'store/vehicle-share/slice';
import Layout from '../../components/layout/Layout';
import CustomModal from '../../components/modal';
import './application.scss';
import {
  IAddressDetailsData,
  IDocumentData,
  IEmployeesDetails,
  IHomeMortgageData,
  IIncomeDetails,
  ILeadDetailsData
} from './applicationDetailsInterface';
import LeftSideBar from './components/LeftSideBar';
import RightSideBar from './components/RightSideBar';
import UploadPayStubs from './components/UploadPayStubs';
import { checkShow } from './helper';

export default function Application() {
  const [showFlag, setShowFlag] = useState('');
  const [leadId, setLeadId] = useState('');
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [applicationDetailData, setApplicationDetailData] = useState<ILeadDetailsData>();
  const [documentsData, setDocumentsDataData] = useState<IDocumentData>();
  const [addressData, setAddressData] = useState<IAddressDetailsData>();
  const [homeData, setHomeData] = useState<IHomeMortgageData>();
  const [incomeData, setIncomeData] = useState<IIncomeDetails>();
  const [incomeDetail, setIncomeDetail] = useState<IIncomeDetails>();
  const [employeeData, setEmployeeData] = useState<IEmployeesDetails>();
  const applicationDetails = useAppSelector((state: RootState) => state.applicationDetails);
  const incomeDetails = useAppSelector((state: RootState) => state.incomeDetails);

  useEffect(() => {
    if (applicationDetails) {
      setApplicationDetailData(applicationDetails?.data?.leadDetails);
      setDocumentsDataData(applicationDetails?.data?.leadDetails?.documents ?? []);
      setAddressData(applicationDetails.data?.leadDetails);
      if (!isEmpty(applicationDetails?.data?.leadDetails?.financialInfo)) {
        setHomeData(applicationDetails?.data?.leadDetails?.financialInfo);
        setIncomeDetail(applicationDetails?.data?.leadDetails?.financialInfo);
      }
      if (!isEmpty(applicationDetails?.data?.LeadDetailsId)) {
        dispatch(getIncomeDetails(applicationDetails?.data?.LeadDetailsId));
        setLeadId(applicationDetails?.data?.LeadDetailsId);
      }
      if (!isEmpty(applicationDetails?.data?.leadDetails?.employmentHistory)) {
        setEmployeeData(applicationDetails?.data?.leadDetails?.employmentHistory);
      }
      if (!isEmpty(applicationDetails?.data?.leadDetails?.customerVehicle) && id) {
        const Obj = {
          id: id,
          type: 'customerVehicle',
        };
        if (!isEmpty(applicationDetails?.data?.leadDetails?.customerVehiclePreference)) {
          dispatch(getVehicleSharedPreferenceData({ ...Obj, type: 'customerVehiclePreference' }));
        }
        dispatch(getVehicleSharedData(Obj));
      }
    }
  }, [applicationDetails]);

  useEffect(() => {
    if (incomeDetails) {
      setIncomeData(incomeDetails?.data);
    }
  }, [incomeDetails]);

  useEffect(() => {
    if (id) {
      dispatch(getApplicationsDetails(id));
    }
  }, [id]);

  return applicationDetails?.loader ? (
    <Loader />
  ) : (
    <Layout heading='Applications' breadcrumb={applicationBreadcrumbs(applicationDetailData)}>
      <section className='details-page details-page-with-timeline'>
        <div className='details-page--left'>
          <LeftSideBar applicationDetailData={applicationDetailData} />
        </div>
        <div className='details-page--right'>
          <RightSideBar
            setShowFlag={setShowFlag}
            applicationDetailData={applicationDetailData}
            documentData={documentsData}
            addressData={addressData}
            homeData={homeData}
            incomeData={incomeData}
            employeeData={employeeData}
            leadId={leadId}
            incomeDetail={incomeDetail}
          />
        </div>
      </section>
      
      {checkShow(showFlag, 'paystub') && (
        <CustomModal title={'Upload Paystub'} show={checkShow(showFlag, 'paystub')} handleClose={() => setShowFlag('')}>
          <div className='form-area'>
            <UploadPayStubs />
          </div>
        </CustomModal>
      )}
    </Layout>
  );
}
