import Header from '../header/Header';
import SideBar from '../side-bar/SideBar';
import { ILayout } from './LayoutInterface';

export default function Layout(props: ILayout) {
  
  return (
    <>
      <div className='main-content-pages'>
        <SideBar />
        <main className='main-content'>
          <Header heading={props.heading} breadcrumb={props.breadcrumb} />
          <div className='main-content__area'>{props.children}</div>
        </main>
        {props.rightSideBar}
      </div>
    </>
  );
}
