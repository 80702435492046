import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExceptionPayload } from '../../services/api';
import { IApplicationDetail, IDeletePaystubParams, IEmploymentHistoryState } from 'types/redux/applicationDetails';
import { addEmployeeHistory, deletePayStub, editEmployeeHistory, removeEmployeeHistory } from 'services/application';

const initialState: IEmploymentHistoryState = {
	addEmployeeHistoryError: undefined,
	addEmployeeHistoryPending: false,
	addEmployeeHistorySuccess: false,
	deleteEmployeeHistorySuccess: false,
	updateEmpHistoryError: undefined,
	updateEmpHistoryPending: false,
	updateEmpHistorySuccess: false,
	deletePaystubError: undefined,
	deletePaystubSuccess: false,

};

export const postEmployeeHistory = createAsyncThunk(
	'empHistory/post',
	async (params: FormData, { rejectWithValue }) => {
		try {
			const response = await addEmployeeHistory(params);
			return response.message;
		} catch (error) {
			throw rejectWithValue(getExceptionPayload(error));
		}
	}
);

export const deleteEmployeeHistory = createAsyncThunk('empHistory/delete', async (params: {}, { rejectWithValue }) => {
	try {
		const response = await removeEmployeeHistory(params);
		return response.message;
	} catch (error) {
		throw rejectWithValue(getExceptionPayload(error));
	}
});

export const updateEmployeeHistory = createAsyncThunk(
	'empHistory/put',
	async (params: FormData, { rejectWithValue }) => {
		try {
			const response = await editEmployeeHistory(params);
			return response.message;
		} catch (error) {
			throw rejectWithValue(getExceptionPayload(error));
		}
	}
);

export const deletePaystubFile = createAsyncThunk(
	'empHistory/deletePayStub',
	async (params:IDeletePaystubParams, { rejectWithValue }) => {
		try {
			const response = await deletePayStub(params);
			return response.message;
		} catch (error) {
			throw rejectWithValue(getExceptionPayload(error));
		}
	}
);

const slice = createSlice({
	name: 'empHistory',
	initialState,
	reducers: {
		clearAddEmployeeHistory: (state) => {
			state.addEmployeeHistoryError = undefined;
			state.addEmployeeHistorySuccess = false;
		},
		clearDeleteEmployeeHistory: (state) => {
			state.deleteEmployeeHistorySuccess = false;
		},
		clearUpdateEmployeeHistory: (state) => {
			state.updateEmpHistoryError = undefined;
			state.updateEmpHistorySuccess = false;
		},
		clearDeletePaystub: (state) => {
			state.deletePaystubError = undefined;
			state.deletePaystubSuccess = false;
		},
	},
	extraReducers(builder): void {
		builder
			.addCase(postEmployeeHistory.pending, (state) => {
				state.addEmployeeHistoryPending = true;
				state.addEmployeeHistoryError = undefined;
			})
			.addCase(postEmployeeHistory.fulfilled, (state, action) => {
				state.addEmployeeHistorySuccess = true;
				state.addEmployeeHistoryPending = false;

			})
			.addCase(postEmployeeHistory.rejected, (state, action) => {
				state.addEmployeeHistoryError = action.error.message as string;
				state.addEmployeeHistorySuccess = false;
				state.addEmployeeHistoryPending = false;
			});

		builder.addCase(deleteEmployeeHistory.fulfilled, (state) => {
			state.deleteEmployeeHistorySuccess = true;
		});

		builder
			.addCase(updateEmployeeHistory.pending, (state) => {
				state.updateEmpHistoryError = undefined;
				state.updateEmpHistoryPending = true;
			})
			.addCase(updateEmployeeHistory.fulfilled, (state, action) => {
				state.updateEmpHistorySuccess = true;
				state.updateEmpHistoryPending = false;

			})
			.addCase(updateEmployeeHistory.rejected, (state, action) => {
				state.updateEmpHistoryError = action.error.message as string;
				state.updateEmpHistorySuccess = false;
				state.updateEmpHistoryPending = false;
			});
		
		builder
			.addCase(deletePaystubFile.pending, (state) => {
				state.deletePaystubError = undefined;
			})
			.addCase(deletePaystubFile.fulfilled, (state, action) => {
				state.deletePaystubSuccess = true;
			})
			.addCase(deletePaystubFile.rejected, (state, action) => {
				state.deletePaystubError = action.error.message as string;
				state.deletePaystubSuccess = false;
			});
	},
});
export const { clearAddEmployeeHistory, clearDeleteEmployeeHistory, clearUpdateEmployeeHistory, clearDeletePaystub } = slice.actions;
export default slice.reducer;
