import { IRightSideBar } from 'pages/application/applicationDetailsInterface';
import { Accordion } from 'react-bootstrap';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import AddressInfo from './address/AddressInfo';
import Documents from './documents/Documents';
import EmpHistory from './employment-history/EmpHistory';
import HomeMortgage from './home-mortgage/HomeMortgage';
import IncomeDetails from './income-details/IncomeDetails';
import PersonalInfo from './personal-info/PersonalInfo';
import Timeline from './TimeLine';
import PreferenceVehicle from './vehicle-shared/PreferenceVehicle';
import VehicleShared from './vehicle-shared/VehicleShared';
import { isEmpty } from 'lodash';

export default function RightSideBar(props: IRightSideBar) {
  const {
    applicationDetailData,
    setShowFlag,
    documentData,
    addressData,
    homeData,
    incomeData,
    employeeData,
    leadId,
    incomeDetail,
  } = props;

  const vehicleShareImage: any = useAppSelector((state: RootState) => state.vehicleShared);
  const { data, preferenceData } = vehicleShareImage;

  return (
    <div className='details-page--right-with-border'>
      <Timeline applicationDetailData={applicationDetailData} />
      <Accordion defaultActiveKey='0' className='accordion-style-1'>
        <PersonalInfo applicationDetailData={applicationDetailData} />
        <Documents documentData={documentData} leadId={leadId} />
        <AddressInfo addressData={addressData} />
        <HomeMortgage homeData={homeData} />
        <IncomeDetails incomeData={incomeData} leadId={leadId} incomeDetail={incomeDetail} />
        <EmpHistory setShowFlag={setShowFlag} employeeData={employeeData} />
        {!isEmpty(data) && <VehicleShared />}
        {!isEmpty(preferenceData) && <PreferenceVehicle />}
      </Accordion>
    </div>
  );
}
