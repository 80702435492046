import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, getExceptionPayload } from '../../services/api';
import { authenticateUser, logoutUser } from '../../services/login';
import { ILoginData, ILoginPageState } from '../../types/redux/login';

const initialState: ILoginPageState = {
	data: undefined,
	loading: false,
	error: undefined,
	loggedInUser: {
		status: APIStatus.IDLE,
	},
};

export const userLogin = createAsyncThunk('login/user', async (params: ILoginData, { rejectWithValue }) => {
  try {
    const response = await authenticateUser(params);
    return response.message;
  } catch (error) {
    throw rejectWithValue(getExceptionPayload(error));
  }
});

export const signOutUser = createAsyncThunk('login/signOut', async () => {
  try {
    const response = await logoutUser();
    return response.message;
  } catch (error) {
    throw getExceptionPayload(error);
  }
});

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetUserLogin: (state) => {
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.error = initialState.error;
      localStorage.clear();
    },
  },
  extraReducers(builder): void {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.data = undefined;
        state.error = undefined;
        state.loggedInUser.status = APIStatus.PENDING;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem('accessToken', action.payload.accessToken);
        localStorage.setItem('refreshToken', action.payload.refreshToken);
        state.data = action.payload;
        state.loggedInUser.status = APIStatus.FULFILLED;
      })
      .addCase(userLogin.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload.message as string;
        state.loggedInUser.status = APIStatus.REJECTED;
      })
      .addCase(signOutUser.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.clear();
        state.data = action.payload;
        state.loggedInUser.status = APIStatus.IDLE;
      })
  },
});
export const { resetUserLogin } = slice.actions;
export default slice.reducer;
