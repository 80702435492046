import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { IFormItem, IFormGroupData } from './FormComponentInterface';

const RowCol = (props: IFormGroupData) => {
  const { formData } = props;
  return (
    <Row>
      {!!formData?.length &&
        formData?.map((item: IFormItem, index: number) => {
          return (
            <Col sm={item.sm} md={item.md} key={index}>
              <Form.Group controlId='form-name' className='form-group'>
                <Form.Label>{item.name}:</Form.Label>
                <span className='read-text'>{item.value}</span>
              </Form.Group>
            </Col>
          );
        })}
    </Row>
  );
};

export default RowCol;
