import { RouteEnum } from 'components/routes/RouteEnum';
import { commonQueryParams } from 'constants/common';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getApplicationsListing } from 'store/application-listing/slice';
import { getProfileDetails } from 'store/profile-detail/slice';
import LoginLayout from '../../components/login-layout/LoginLayout';
import { APIStatus } from '../../services/api';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { userLogin } from '../../store/login/slice';
import { ILoginData, ILoginPageState } from '../../types/redux/login';
import './login-form.scss';
import LoginForm from './LoginForm';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useNavigate();
  const formState = useForm<ILoginData>({
    defaultValues: {
      username: '',
      password: '',
      grantType: '',
    },
  });
  const { handleSubmit } = formState;

  const loginState: ILoginPageState | any = useAppSelector((state: RootState) => state.login);

  const onSubmit = handleSubmit((data) => {
    const loginObject = { ...data, grantType: 'password' };
    dispatch(userLogin(loginObject));
  });

  const getDetails = async () => {
    await dispatch(getApplicationsListing(commonQueryParams));
    await history(RouteEnum.Applicant);
  };

  useEffect(() => {
    const status = loginState?.loggedInUser?.status;
    if (status === APIStatus.FULFILLED) {
      getDetails();
    }
  }, [loginState]);

  return (
    <LoginLayout classNames={'login-form'}>
      <h2 className='heading-2'>
        {t('constants.welcome')}
        <br />
        {t('constants.dabadu')}
      </h2>
      <LoginForm onSubmit={onSubmit} formState={formState} />
    </LoginLayout>
  );
};

export default LoginPage;
