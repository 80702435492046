import ModalButton from 'components/form-components/ModalButton';
import Confeti1 from '../../scss/images/del-confeti1.svg';
import Confeti2 from '../../scss/images/del-confeti2.svg';
import { IConfirmationModal } from './CustomModalInterface';
import ShowMessageModal from './ShowMessageModal';

const ShowConfirmationMessageModal = (props: IConfirmationModal) => {
  const { requiredConfirmation, hideConfirmationModal, className, title, msg, onConfirm } = props;

  return (
    <ShowMessageModal show={requiredConfirmation} handleClose={hideConfirmationModal} classNames={className}>
      <div className='delete-confirmation-animation'>
        <img src={Confeti1} alt='confeti1' className='confeti confeti-left zoom' />
        <img src={Confeti2} alt='confeti2' className='confeti confeti-right zoom' />
        <div className='delete-icon-wrap'>
          <span className='fa-icon rv-close' />
        </div>
      </div>
      <h2 className='heading-1'>{title}</h2>
      <p>{msg}</p>
      <div className='d-flex justify-content-center align-items-center mt-4'>
        <ModalButton variant='gray' onClick={hideConfirmationModal} label='No' />
        <ModalButton variant='primary' onClick={onConfirm} label='Yes' />
      </div>
    </ShowMessageModal>
  );
};

export default ShowConfirmationMessageModal;
