// import BootstrapTable from "react-bootstrap-table-next";
import { IDataTable } from './DataTableInterface';
// import filterFactory from "react-bootstrap-table2-filter";
import { useState } from 'react';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationListStandalone,
// } from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Search from './components/Search';
// import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import LayoutThemeProvider from '../layout-theme-provider';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueFormatter: (params: GridValueFormatterParams) => {
      console.log({ params });
    },
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function DataTable(props: IDataTable) {
  const { columns, queryParams, onTableChange, rows, keyField, tableTitle, defaultSorted, searchPlaceholder } = props;
  const [hideFilter, setHideFilter] = useState(true);
  const [filterCount, setFilterCount] = useState(0);

  return (
    <LayoutThemeProvider>
      {/* TODO:future use */}
      {/* <div className="listing-page--search">
        <Search
          placeholder={searchPlaceholder}
          setHideFilter={() => setHideFilter(false)}
          filterCount={filterCount}
        />
      </div> */}
      <div className='listing-page--list'>
        <h2 className='heading-4'>{tableTitle}</h2>
        {!!rows?.length && (
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={queryParams?.pagination?.limit}
              experimentalFeatures={{ newEditingApi: true }}
              getRowId={(row) => row[keyField]}
              filterMode={'server'}
              hideFooter={true}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        )}
      </div>
    </LayoutThemeProvider>
  );
}
