import React from 'react';
import { IInputRadio } from './FormComponentInterface';

const InputRadioButton = (props: IInputRadio) => {
  const { id, handleOnClick, value, checked, name = '',label='' } = props;

  return (
    <div className='custom-control custom-radio'>
      <input
        type='radio'
        id={id}
        className='custom-control-input'
        name={name}
        checked={checked}
        value={value}
        onChange={() => handleOnClick()}
      />
      <label htmlFor={id} className='custom-control-label'>{label}</label>
    </div>
  );
};

export default InputRadioButton;
