import { countries } from 'pages/application/constants';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { IAddressAutoComplete } from './FormComponentInterface';

const AddressAutoComplete:React.FC<IAddressAutoComplete> = ({placeholder, formState, fieldName, rules, onAddressSelect}) => {
  
  const handleSelect = (address:string) => {
    geocodeByAddress(address)
      .then((results) => {
        
        const countryData = results[0].address_components.filter((addressComponent) => {
          return addressComponent.types.includes('country');
        });
        const cityData = results[0].address_components.filter((addressComponent) => {
          return addressComponent.types.join(', ').includes('locality, political');
        });
        const route = results[0].address_components.filter((addressComponent) => {
          return addressComponent.types.join(', ').includes('route');
        });
        const provinceData = results[0].address_components.filter((addressComponent) => {
          return addressComponent.types.includes('administrative_area_level_1');
        });
        const postalCodeData = results[0].address_components.filter((addressComponent) => {
          return addressComponent.types.includes('postal_code');
        });
        const streetNumber = results[0].address_components.filter((addressComponent) => {
          return addressComponent.types.join(', ').includes('street_number');
        });
        const suiteNumber = results[0].address_components.filter((addressComponent) => {
          return addressComponent.types.join(', ').includes('subpremise');
        });

        const countryArray = countries
          ?.filter((item) => item.value === countryData[0]?.short_name)
          ?.map((item) => ({ label: item.label, value: item.value }));
       
        const streetAdd = streetNumber[0]?.long_name ? streetNumber[0]?.long_name : '';
        
        const routeAdd = route[0]?.long_name ? route[0]?.long_name : '';

        const addressParts = {
          streetNumber: streetAdd || '',
          streetName: route[0]?.short_name || '',
          streetType: route[0]?.short_name,
          routeAddress: routeAdd,
          suiteNumber: suiteNumber[0]?.short_name || '',
          city: cityData[0]?.short_name || '',
          province: provinceData[0]?.short_name || '',
          postalCode: postalCodeData[0]?.short_name || '',
          country: countryArray[0],
          formatted_address: results?.[0]?.formatted_address
        }

        onAddressSelect(addressParts);
      })
      .catch((error) => console.error('Error', error));
  };

	const {control, formState:{errors}, getValues, setValue} = formState;

  return (

    <>
      <Controller
        control={control}
        name={fieldName}
        rules={rules}
        render={({ field }) => (
          <PlacesAutocomplete
            {...field}
            value={getValues(fieldName) || ''}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  name="address"
                  {...getInputProps({
                    placeholder: 'Enter Address line 1',
                    className: `form-control ${errors.address && 'input-field-error'}`,
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, key) => {
                    const className = suggestion.active ? 'suggestion-item--active form-control' : 'suggestion-item form-control';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <select
                        // key={key}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <option
                          // key={key}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          value={suggestion.description}
                        >
                          {suggestion.description}
                        </option>
                      </select>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      />
      {errors[fieldName] && <Form.Text className='text-danger'>{errors[fieldName].message}</Form.Text>}
    </>
  )
}

export default AddressAutoComplete