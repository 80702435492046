import { getFeatureData, getShowVehicleData } from './VehicleHelper';

export const showVehicleAccordions = [
  { label: 'Vehicle Detail', getDataFn: getShowVehicleData, eventKey: '0', icon: 'rv-car-filled' },
  { label: 'Feature', getDataFn: getFeatureData, eventKey: '1', icon: 'rv-car-seat' },
];

export const HEADING = 'heading';

export const STATUS = 'status';

export const VEHICLE_TYPE = 'type';
