import { jwtExpired, ToastStatusEnum } from 'constants/common';
import { addError } from 'store/error-toast/slice';
import { v4 as uuidv4 } from 'uuid';

export default function errorToastMiddleware() {
	return (store) => (next) => (action) => {
		if (action.error && action.payload.message && action.payload.message != jwtExpired) {
			const errorObj = {
				id: uuidv4(),
				message: action.payload.message,
				type: ToastStatusEnum.Error,
			};
			next(addError(errorObj));
		}
		next(action);
	};
}
