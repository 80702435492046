import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApplications } from '../../services/applicationListing';
import { IApplicationList } from '../../types/redux/applicationListing';
import { getExceptionPayload } from '../../services/api';

const initialState: IApplicationList = {
  data: undefined,
  error: undefined,
  loading: false,
};

export const getApplicationsListing = createAsyncThunk(
  'applicationListing',
  async (params: Object = {}, { rejectWithValue }) => {
    try {
      const response = await getApplications({ ...params });
      return response.message;
    } catch (error) {
      throw rejectWithValue(getExceptionPayload(error));
    }
  }
);

const slice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    resetApplications: (state) => {
      state.data = undefined;
      state.error = undefined;
      state.loading = false;
    },
  },
  extraReducers(builder): void {
    builder
      .addCase(getApplicationsListing.pending, (state) => {
        state.data = undefined;
        state.error = undefined;
        state.loading = true;
      })
      .addCase(getApplicationsListing.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getApplicationsListing.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loading = false;
      });
  },
});
export const { resetApplications } = slice.actions;
export default slice.reducer;
