import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApplicationDetails } from 'services/application';
import { getExceptionPayload } from '../../services/api';

export const getApplicationsDetails = createAsyncThunk(
  'applicationDetails',
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await getApplicationDetails(params);
      return response.message;
    } catch (error) {
      throw rejectWithValue(getExceptionPayload(error));
    }
  }
);
