import { AccordionProps } from "./AccordionToggleInterface";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { AccordionContext } from "react-bootstrap";
import { useContext } from "react";

export default function AccordionCustomToggle(props: AccordionProps) {
  const { eventKey, children, callback } = props;

  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div className={`${isCurrentEventKey? 'card-toggle active' : 'card-toggle'}`}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}
