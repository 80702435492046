import LoginLayout from 'components/login-layout/LoginLayout';
import ShowMessageModal from 'components/modal/ShowMessageModal';
import { RouteEnum } from 'components/routes/RouteEnum';

import { useNavigate } from 'react-router-dom';
import Check from 'scss/images/login-check.svg';
import { resetApplications } from 'store/forgot-password/slice';
import { IForgotPasswordState } from 'types/redux/forgotPassword';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();

  const history = useNavigate();

  const {success:forgotPasswordSuccess}: IForgotPasswordState = useAppSelector((state: RootState) => state.forgotPassword);

  const closeSuccessModal = () => { 
    dispatch(resetApplications());
    history(RouteEnum.Login);
   }

  return (
    <LoginLayout classNames={'signup-form'}>
      <h2 className='heading-2'>
        Forgot
        <br />
        Password
      </h2>
      <ForgotPasswordForm/>
      {forgotPasswordSuccess && (
        <ShowMessageModal
          show={forgotPasswordSuccess}
          handleClose={closeSuccessModal}
          classNames={'modal-without-header login-modal'}
        >
          <>
            <img src={Check} alt='check' />
            <h2 className='heading-1'>Email Sent</h2>
            <p>We have sent you a verification email which you need to complete in order to access all of the features on
              the platform.</p>
          </>
        </ShowMessageModal>
      )}
    </LoginLayout>
  );
};

export default ForgotPasswordPage;
