import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { RootState } from 'store';
import { clearError } from 'store/error-toast/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IErrorToast } from 'types/redux/errorToast';

const ToastMessage = () => {
  const dispatch = useAppDispatch();

  const { item }: IErrorToast = useAppSelector((state: RootState) => state.errorToast);

  useEffect(() => {
    if (item) {
      toast.error(item.message);
      dispatch(clearError());
    }
  }, [item]);

  return (
    <div>
      <Toaster position='top-right' reverseOrder={true} />
    </div>
  );
};

export default ToastMessage;
