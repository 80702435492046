import { prepareSlideshowImages } from 'pages/application/helper';
import { ICompareVehiclesGallery } from 'types/redux/applicationDetails';
import {Link} from 'react-router-dom';

const CompareVehiclesGallery:React.FC<ICompareVehiclesGallery> = ({images, setShowSlideshow, setSlideshowImages}) => {
  
  const showGallery = () => {
    setShowSlideshow(true);
    setSlideshowImages(prepareSlideshowImages(images));
  }

  return (
    <td className='data-2'>
      <div className="gallery-image-wrap">
        {images.slice(0,2).map(image => <img src={image.path} alt={image.category} key={image._id} />)}
      </div>
      {!!images?.length ? <Link to="#" onClick={showGallery} className="view-more">
        View More
      </Link>: <p>No Images Found</p>}
    </td>
  )
}

export default CompareVehiclesGallery