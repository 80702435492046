import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { IProfileDetail } from '../ProfileInterface';
import EditPersonalInfo from './personal-info/EditPersonalInfo';
import ShowPersonalInfo from './personal-info/ShowPersonalInfo';

const PersonalInfo = (props: IProfileDetail) => {
  const [edit, setEdit] = useState(false);
  const { profileData } = props;

  return (
    <div className='accordion accordion-style-1'>
      <Card>
        <Card.Header>
          <div className='card-toggle'>
            <div className='card-toggle--left'>
              <span className='fa-icon rv-personal-details' />
              <span className='card-toggle--left-text'>Personal details</span>
            </div>
            <div className='card-toggle--right'>
              <span
                className='fa-icon rv-edit mr-0'
                onClick={(e) => {
                  e.stopPropagation();
                  setEdit(!edit);
                }}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {edit ? (
            <EditPersonalInfo profileData={profileData} setEdit={setEdit} />
          ) : (
            <ShowPersonalInfo profileData={profileData} />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PersonalInfo;
