import { post } from '../utils/HttpUtility';
import { api } from '../utils/base';

export const authenticateUser = async (params: Object) => {
  const endpoint = api.signIn;
  const response = await post({ endpoint, params });
  return response.data;
};

export const postForgotPassword = async (params: Object) => {
  const endpoint = api.forgotPassword;
  const response = await post({ endpoint, params });
  return response.data;
};

export const postUpdatePassword = async (params: Object) => {
  const endpoint = api.updatePassword;
  const response = await post({ endpoint, params });
  return response.data;
};

export const logoutUser = async () => {
  const endpoint = api.signOut;
  const response = await post({ endpoint });
  return response.data;
};
