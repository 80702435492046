import { IAddressDetails } from 'pages/application/applicationDetailsInterface';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const ShowAddress = (props: IAddressDetails) => {
  const { addressData } = props;
  return (
    <Row>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-name' className='form-group'>
          <Form.Label>Address</Form.Label>
          <span className='read-text'>{addressData?.address ?? ''}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-name' className='form-group'>
          <Form.Label>Duration</Form.Label>
          <span className='read-text'>{`${addressData?.yearsAtResidence ?? 0} Years | ${
            addressData?.monthsAtResidence ?? 0
          } Months`}</span>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ShowAddress;
