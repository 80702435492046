import Input from 'components/form-components/Input';
import PhoneNumberInput from 'components/form-components/PhoneNumberInput';
import { onlyCountries } from 'constants/common';
import { emailReg } from 'constants/regex';
import { isInvalid, isRequired } from 'helpers/errorMessageHelper';
import { isEmpty } from 'lodash';
import { IProfileDetail } from 'pages/profile/ProfileInterface';
import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getProfileDetails } from 'store/profile-detail/slice';
import { clearRequest, putProfileDetails } from 'store/profile-update/slice';
import { IPutProfileStatus } from 'types/redux/profileDetails';

const EditPersonalInfo = (props: IProfileDetail) => {
  const dispatch = useAppDispatch();
  const { profileData, setEdit } = props;
  const { t } = useTranslation();

  const formState = useForm({});
  const { handleSubmit, reset } = formState;

  const putStatus: IPutProfileStatus = useAppSelector((state: RootState) => state.putProfile);

  useEffect(() => {
    if (!isEmpty(profileData)) {
      reset({
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        cellPhone: profileData.cellPhone,
        email: profileData.username,
        secondaryEmail: profileData.secondaryEmail,
      });
    }
  }, [profileData]);

  useEffect(() => {
    const data = putStatus?.data;
    if (!isEmpty(data)) {
      setEdit(false);
      dispatch(clearRequest());
      dispatch(getProfileDetails());
    }
  }, [putStatus]);

  const onSubmit = handleSubmit((data) => {
    const putProfileObj = {
      id: profileData?.id,
      ...data,
    };
    dispatch(putProfileDetails(putProfileObj));
  });

  return (
    <Form onSubmit={onSubmit}>
      <Row>
        <Col sm={12} md={6} lg={4}>
          <Form.Group controlId='form-name' className='form-group'>
            <Form.Label>{t('constants.firstName')}</Form.Label>
            <Input
              fieldName={'firstName'}
              fieldType={'text'}
              placeholder={t('constants.first-name')}
              rules={{
                required: {
                  value: true,
                  message: t('errorConstants.first-name-required'),
                },
              }}
              formState={formState}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Form.Group controlId='form-name' className='form-group'>
            <Form.Label>{t('constants.lastName')}</Form.Label>
            <Input
              fieldName={'lastName'}
              fieldType={'text'}
              placeholder={t('constants.last-name')}
              rules={{
                required: {
                  value: true,
                  message: t('errorConstants.last-name-required'),
                },
              }}
              formState={formState}
            />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <Form.Group controlId='form-phone' className='form-group'>
            <Form.Label>{t('constants.phone')}</Form.Label>
            <PhoneNumberInput
              fieldName={'cellPhone'}
              fieldType={'phone'}
              placeholder={t('constants.phone')}
              country={onlyCountries[0]}
              onlyCountries={onlyCountries}
              countryCodeEditable={false}
              formState={formState}
              disabled={true}
            />
          </Form.Group>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <Form.Group controlId='form-email' className='form-group'>
            <Form.Label>{t('constants.primary-email')}</Form.Label>
            <Input
              fieldName={'email'}
              fieldType={'email'}
              placeholder={t('constants.primary-email')}
              rules={{
                required: {
                  value: true,
                  message: isRequired('constants.primary-email'),
                },
              }}
              formState={formState}
              disabled={true}
            />
          </Form.Group>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Form.Group controlId='form-email' className='form-group'>
            <Form.Label>{t('constants.secondary-email')}</Form.Label>
            <Input
              fieldName={'secondaryEmail'}
              fieldType='text'
              placeholder={t('constants.secondary-email')}
              rules={{
                pattern: {
                  value: emailReg,
                  message: isInvalid('constants.secondary-email'),
                },
              }}
              formState={formState}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId='form-btn' className='form-group'>
            <Form.Label className='invisible d-none d-md-block'>{t('constants.secondary-email')}</Form.Label>
            <Button type='submit' className='btn-small ml-auto'>
              {t('constants.update')}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default EditPersonalInfo;
