import DateInput from 'components/form-components/DateInput';
import FileUpload from 'components/form-components/FileUpload';
import Input from 'components/form-components/Input';
import InputSelect from 'components/form-components/InputSelect';
import Loader from 'components/loader/Loader';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { isEmpty } from 'lodash';
import { IDocumentFile, IEditDocumentData } from 'pages/application/applicationDetailsInterface';
import { documentList, DRIVING_LICENCE, PASSPORT, yearMonthDate } from 'pages/application/constants';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteFiles, updateDetails, updateFiles } from 'store/update-files/slice';
import DocumentForm from './DocumentForm';

const EditDocument = (props: IEditDocumentData) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { documentData, setEdit, edit, leadId } = props;

  const [primaryDocument, setPrimaryDocument] = useState<any>(null);
  const [secondaryDocument, setSecondaryDocument] = useState(null);
  const [otherDocument, setOtherDocument] = useState<any>(null);
  const [otherIdProof, setOtherIdProof] = useState<any>('');
  const [drivingDetails, setDrivingDetails] = useState([]);
  const [passportDetails, setPassportDetails] = useState([]);
  const [otherDetails, setOtherDetails] = useState([]);

  const [primaryDoc, setPrimaryDoc] = useState<File[]>([]);
  const [secDoc, setSecDoc] = useState<File[]>([]);
  const [otherDoc, setOtherDoc] = useState<File[]>([]);

  const filterFile = (fileName: string) => {
    return documentData?.filter((r: any) => r.fileName === fileName)?.[0];
  };

  const formState = useForm({});
  const { handleSubmit, getValues, setValue, clearErrors, setError } = formState;

  const putStatus: any = useAppSelector((state: RootState) => state.homeDetails);
  const docUpdateStatus: any = useAppSelector((state: RootState) => state.uploadDocument);

  useEffect(() => {
    if (documentData) {
      const otherDetails = documentData?.filter((item: any) => item?.fileName === documentList[0]?.value);
      const otherDetailsOption = documentList?.filter((item) => item.value === otherDetails?.[0]?.fileName);

      if (!isEmpty(otherDetails) && !!otherDetailsOption?.length) {
        setValue('otherIdProof', otherDetailsOption?.[0]);
        setValue('otherNumber', otherDetails?.[0]?.licenseNo);
        setValue('otherExpiryDate', otherDetails?.[0]?.expiryDate ? new Date(otherDetails?.[0]?.expiryDate) : '');
        clearErrors('otherExpiryDate');
        const filteredOtherDoc = {
          documentId: otherDetails?.[0]?._id,
          fileName:
            otherDetails?.[0]?.fileName.charAt(0).toUpperCase() + otherDetails?.[0]?.fileName.slice(1).toLowerCase(),
          downloadUrl: otherDetails?.[0]?.path,
        };
        setOtherDocument(filteredOtherDoc);
      }

      const primaryDoc = filterFile(DRIVING_LICENCE);
      if (!isEmpty(primaryDoc)) {
        const filteredPrimaryDoc = {
          documentId: primaryDoc._id,
          fileName: primaryDoc.fileName.charAt(0).toUpperCase() + primaryDoc.fileName.slice(1).toLowerCase(),
          downloadUrl: primaryDoc.path,
          licenseNo: primaryDoc.licenseNo,
        };
        setPrimaryDocument(filteredPrimaryDoc);
      }
      const secondaryDoc = filterFile(PASSPORT);
      if (!isEmpty(secondaryDoc)) {
        const filteredSecondaryDoc: any = {
          documentId: secondaryDoc._id,
          fileName: secondaryDoc.fileName.charAt(0).toUpperCase() + secondaryDoc.fileName.slice(1).toLowerCase(),
          downloadUrl: secondaryDoc.path,
          licenseNo: secondaryDoc.licenseNo,
        };
        setSecondaryDocument(filteredSecondaryDoc);
      }
      setDrivingDetails(filterFile(DRIVING_LICENCE));
      setPassportDetails(filterFile(PASSPORT));
      setOtherDetails(filterFile(documentList[0]?.value));
    }
  }, [documentData]);

  useEffect(() => {
    setData(passportDetails, 'passport');
  }, [passportDetails]);

  useEffect(() => {
    setData(drivingDetails, 'drivingLicence');
  }, [drivingDetails]);

  useEffect(() => {
    setData(otherDetails, 'otherLicence');
    if (!isEmpty(otherDetails)) {
      setOtherIdProof(documentList[0]);
    }
  }, [otherDetails]);

  useEffect(() => {
    if (!isEmpty(primaryDoc)) {
      updateValue(primaryDoc[0], 'Primary');
    }
  }, [primaryDoc]);

  useEffect(() => {
    if (!isEmpty(secDoc)) {
      updateValue(secDoc[0], 'Secondary');
    }
  }, [secDoc]);

  useEffect(() => {
    if (!isEmpty(otherDoc)) {
      updateValue(otherDoc[0], 'Other');
    }
  }, [otherDoc]);

  function updateValue(value: any, type: string | null) {
    if (type === 'Primary') {
      setPrimaryDocument(value);
      clearErrors('drivingLicenceFile');
    } else if (type === 'Secondary') {
      setSecondaryDocument(value);
      clearErrors('passportFile');
    } else {
      setOtherDocument(value);
      clearErrors('otherLicenceFile');
    }
  }

  const handleDocument = (e: any, type: string) => {
    const file = e.target.files[0];
    updateValue(file, type);
  };

  const handlremoveFile = (type: string) => {
    updateValue(null, type);
  };

  const dispatchData = async (
    data: any,
    document: any,
    fileName: any,
    field: any,
    isPrimary: any,
    fileType: any,
    record: any,
    isOther: any = false
  ) => {
    if (!isEmpty(document?.name) && id) {
      const formData = new FormData();
      formData.append('fileName', fileName ?? data[`${field}IdProof`]?.value);
      formData.append('licenseNo', data[`${field}Number`]);
      formData.append('expiryDate', dateFormatter(data[`${field}ExpiryDate`], yearMonthDate));
      formData.append('leadId', id);
      formData.append('isPrimary', isPrimary);
      formData.append('isOther', isOther);
      formData.append('fileType', fileType);

      if (!isEmpty(record)) {
        formData.append('documentId', record?._id);
      }
      if (document) {
        formData.append('document', document);
        const docPayload = {
          type: 'idproof',
          formData: formData,
        };
        await dispatch(updateFiles(docPayload));
      }
    } else if (!isEmpty(record?.path)) {
      let payload: any = {
        licenseNo: data[`${field}Number`],
        expiryDate: data[`${field}ExpiryDate`],
        leadDetailsId: leadId,
      };
      if (!isEmpty(record)) {
        payload = {
          ...payload,
          documentId: record?._id,
        };
      }
      await dispatch(updateDetails(payload));
    }
  };
  const setData = (record: any, fieldName: string) => {
    if (!isEmpty(record)) {
      setValue(`${fieldName}Number`, record?.licenseNo);
      setValue(`${fieldName}ExpiryDate`, record?.expiryDate ? new Date(record?.expiryDate) : '');
    }
  };
  const onSubmit = handleSubmit(async (data: any) => {
    if (id) {
      await dispatchData(data, primaryDocument, DRIVING_LICENCE, 'drivingLicence', true, 'PRIMARY', drivingDetails);
      await dispatchData(data, secondaryDocument, PASSPORT, 'passport', false, 'SECONDARY', passportDetails);
      await dispatchData(data, otherDocument, otherIdProof.value, 'other', false, 'SECONDARY', otherDetails, true);
      await dispatch(getApplicationsDetails(id));
      await setEdit(false);
    }
  });

  const onFileDelete = async (file: IDocumentFile) => {
    if (id) {
      await dispatch(deleteFiles({ documentId: file.documentId, leadDetailId: leadId }));
      await dispatch(getApplicationsDetails(id));
    }
  };

  return (
    <>
      {putStatus?.loading || docUpdateStatus?.loading ? (
        <Loader />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row>
            <DocumentForm
              label='ID Proof'
              value={'Driving Licence'}
              inputLabel={'Driving Licence No.'}
              fieldName={'drivingLicence'}
              handleDocument={handleDocument}
              handlremoveFile={handlremoveFile}
              fileName='License'
              fileType={'Primary'}
              formState={formState}
              data={primaryDocument}
              isEdit={edit}
              // deleteDocument={deleteDocument}
              doc={primaryDoc}
              setDoc={setPrimaryDoc}
              leadId={leadId}
            />
            <DocumentForm
              label='Secondary ID Proof'
              value={'Passport'}
              inputLabel={'Passport Number'}
              fieldName={'passport'}
              handleDocument={handleDocument}
              handlremoveFile={handlremoveFile}
              fileName='Passport'
              fileType={'Secondary'}
              formState={formState}
              data={secondaryDocument}
              isEdit={edit}
              // deleteDocument={deleteDocument}
              doc={secDoc}
              setDoc={setSecDoc}
              leadId={leadId}
            />
            <Col sm={12} md={3}>
              <Form.Group controlId='form-residence-type' className='form-group'>
                <Form.Label>Other</Form.Label>
                <InputSelect options={documentList} fieldName={'otherIdProof'} formState={formState} disabled={!edit} />
              </Form.Group>
            </Col>
            {getValues('otherIdProof') && (
              <>
                <Col sm={12} md={3}>
                  <Form.Group controlId='form-other-desc' className='form-group'>
                    <Form.Label>{getValues('otherIdProof')?.label}</Form.Label>
                    <Input
                      fieldName={`otherNumber`}
                      fieldType={'text'}
                      placeholder={`otherLicense`}
                      formState={formState}
                      disabled={!edit}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={3}>
                  <Form.Group controlId='form-expiryDate' className='form-group'>
                    <Form.Label>{'Expiry Date'}</Form.Label>
                    <DateInput
                      fieldName={`otherExpiryDate`}
                      fieldType={`otherExpiryDate`}
                      placeholder={`otherExpiryDate `}
                      formState={formState}
                      onChange={(date: any) => {
                        setValue('otherExpiryDate', date ? new Date(date) : '');
                        clearErrors('otherExpiryDate');
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={3}>
                  <Form.Group controlId='upload-doc' className='form-group'>
                    <Form.Label>Upload</Form.Label>
                    <FileUpload
                      isMultiple={false}
                      existingFiles={!isEmpty(otherDocument) ? [otherDocument] : []}
                      newUploadedFiles={otherDoc}
                      setNewUploadedFiles={setOtherDoc}
                      onExistingFileRemove={onFileDelete}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            <Col>
              <Form.Group controlId='form-btn' className='form-group'>
                <Button type='submit' className='btn-small ml-auto'>
                  Update
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default EditDocument;
