import { maxDate, minDateForDateInput } from 'helpers/dateTimeHelper';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDateInput } from './FormComponentInterface';

const DateInput = ({
  fieldName,
  placeholder,
  rules,
  formState,
  name = fieldName,
  onChange,
  disabled = false,
}: IDateInput) => {
  const {
    getValues,
    control,
    formState: { errors },
  } = formState;

  const { t } = useTranslation();

  const maxDateValue = maxDate(fieldName);
  const minDate = minDateForDateInput(fieldName);

  return (
    <>
      <div className='input-with-icon'>
        <Controller
          render={({ field }) => (
            <DatePicker
              {...field}
              className='form-control'
              autoComplete='off'
              showMonthDropdown
              showYearDropdown={true}
              dropdownMode='select'
              selected={getValues(name)}
              name={name}
              onChange={onChange}
              maxDate={maxDateValue}
              minDate={minDate}
              disabled={disabled}
            />
          )}
          name={name}
          control={control}
          rules={rules}
        />
      </div>
      {!!errors?.[name] && <Form.Text className='text-danger'>{errors[name].message}</Form.Text>}
    </>
  );
};

export default DateInput;
