import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { DDMMYYYY } from 'constants/dateTime';
import { capitalizeFirstLetter } from 'helpers';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { formatPhoneNumber } from 'helpers/helper';
import { useRef } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useAppDispatch } from 'store/hooks';
import { getProfileDetails } from 'store/profile-detail/slice';
import { putProfileImg } from 'store/profile-update/slice';
import { ILeftSidebarDetails } from '../ProfileInterface';
import { useTranslation } from 'react-i18next';

const LeftSideBar = (props: ILeftSidebarDetails) => {
  const dispatch = useAppDispatch();
  const imageRef: any = useRef();
  const { t } = useTranslation();
  const { profileSidebarDetail } = props;

  //open file selection
  const handleEditClick = (type: string) => {
    if (type === 'profile') imageRef.current.click();
  };

  //Update  images profile/banner
  const handleChange = async (e: any, type: string) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    if (type === 'profile') {
      const profileObj = { id: profileSidebarDetail?.id, image: formData };
      await dispatch(putProfileImg(profileObj));
    }
    dispatch(getProfileDetails());
  };

  return (
    <div className='profile-panel'>
      <div className='profile-circle--with-icon' onClick={() => handleEditClick('profile')}>
        <ProfileAvatar
          image={profileSidebarDetail?.imageUrl}
          name={`${profileSidebarDetail?.firstName ? capitalizeFirstLetter(profileSidebarDetail?.firstName.charAt(0)) : ''} ${
            profileSidebarDetail?.lastName ? capitalizeFirstLetter(profileSidebarDetail?.lastName.charAt(0)) : ''
          }`}
        />
        <div className='icon-wrap--circle'>
          <span className='fa-icon rv-camera' />
          <input
            ref={imageRef}
            type='file'
            style={{ display: 'none' }}
            accept='image/*'
            onChange={(e) => handleChange(e, 'profile')}
          />
        </div>
      </div>
      <ListGroup as='ul' bsPrefix=' ' className='list-unstyled profile-panel--details'>
        {/* TODO:pending from response */}
        {/* <ListGroup.Item as='li' bsPrefix=' '>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>Applicant</label>
            <span className='text-capitalize'>{profileSidebarDetail?.applicant ?? '-'}</span>
          </div>
        </ListGroup.Item> */}
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>{t('constants.mail')}</label>
            <span>{profileSidebarDetail?.username ?? '-'}</span>
          </div>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>{t('constants.phone')}</label>
            <span className='text-capitalize'>
              {profileSidebarDetail?.cellPhone ? formatPhoneNumber(profileSidebarDetail?.cellPhone) : '-'}
            </span>
          </div>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>{t('constants.dob')}</label>
            <span>
              {profileSidebarDetail?.birthDate ? dateFormatter(profileSidebarDetail?.birthDate, DDMMYYYY) : '-'}
            </span>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default LeftSideBar;
