import { commonQueryParams } from 'constants/common';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApplicationsListing } from 'store/application-listing/slice';
import { getProfileDetails } from 'store/profile-detail/slice';
import { APIStatus } from '../../services/api';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ILoginPageState } from '../../types/redux/login';

const LandingPageAfterLogin = () => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const applications = useAppSelector((state: RootState) => state.applications);
  const loginState: ILoginPageState | any = useAppSelector((state: RootState) => state.login);

  const getDetails = async () => {
    await dispatch(getApplicationsListing(commonQueryParams));
  };

  useEffect(() => {
    const status = loginState?.loggedInUser?.status;

    if (applications?.data?.total == 1) {
      history(`/applications/${applications?.data?.docs?.[0]?.lead}`);
    } else if (applications?.data?.total > 1) {
      history('/applications');
    } else if (isEmpty(applications?.data?.docs) && status === APIStatus.FULFILLED) {
      history('/profile');
    } else if (isEmpty(applications?.data)) {
      getDetails();
    }
  });

  return <></>;
};

export default LandingPageAfterLogin;
