import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import { ILeadDetails } from 'pages/application/applicationDetailsInterface';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EditPersonalInfo from './EditPersonalInfo';
import ShowPersonalInfo from './ShowPesonalInfo';

export default function PersonalInfo(props: ILeadDetails) {
  const { applicationDetailData } = props;
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  
  return (
    <Card>
      <Card.Header>
        <AccordionCustomToggle eventKey='0'>
          <div className='card-toggle--left'>
            <span className='fa-icon rv-info-circle-filled' />
            <span className='card-toggle--left-text'>{t('constants.personal-info')}</span>
          </div>
          <div className='card-toggle--right'>
            <span
              className='fa-icon rv-edit'
              onClick={(e) => {
                e.stopPropagation();
                setEdit(!edit);
              }}
            />
            <div className='accordion-arrow'>
              <span className='fa-icon rv-pointer-down' />
            </div>
          </div>
        </AccordionCustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey='0'>
        <Card.Body>
          {edit ? (
            <EditPersonalInfo applicationDetailData={applicationDetailData} />
          ) : (
            <ShowPersonalInfo applicationDetailData={applicationDetailData} />
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
