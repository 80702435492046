import ToastMessage from 'components/toasts/ToastMessage';
import LandingPageAfterLogin from 'pages/after-login-landing/LandingPage';
import ForgotPasswordPage from 'pages/forgot-password/ForgotPasswordPage';
import UpdatePasswordPage from 'pages/forgot-password/UpdatePasswordPage';
import ProfilePage from 'pages/profile/ProfilePage';
import SignUpPage from 'pages/sign-up/SignUpPage';
import VehicleGarageWrapper from 'pages/vehicle-garage/VehicleGarageWrapper';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './components/routes/ProtectedRoutes';
import PublicRoutes from './components/routes/PublicRoutes';
import { RouteEnum } from './components/routes/RouteEnum';
import ApplicationListing from './pages/application-listing/ApplicationListing';
import Application from './pages/application/index';
import Dashboard from './pages/dashboard';
import LandingPage from './pages/landing/LandingPage';
import LoginPage from './pages/login/LoginPage';

function App() {
  return (
    <>
      <ToastMessage />
      <div className='main'>
        <BrowserRouter>
          <Routes>
            {/** Protected Routes */}
            {/** Wrap all Route under ProtectedRoutes element */}
            <Route path='/' element={<ProtectedRoutes />}>
              <Route path={RouteEnum.Dashboard} element={<Dashboard />} />
              <Route path={RouteEnum.Applications} element={<ApplicationListing />} />
              <Route path={RouteEnum.ApplicationDetail} element={<Application />} />
              <Route path={RouteEnum.Profile} element={<ProfilePage />} />
              <Route path={RouteEnum.Applicant} element={<LandingPageAfterLogin />} />
              <Route path={`${RouteEnum.VehicleDetails}/:leadId/:vehicleId`} element={<VehicleGarageWrapper />} />
            </Route>
            {/** Public Routes */}
            {/** Wrap all Route under PublicRoutes element */}
            <Route path='' element={<PublicRoutes />}>
              <Route index element={<LandingPage />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/sign-up' element={<SignUpPage />} />
              <Route path='/forgot-password' element={<ForgotPasswordPage />} />
              <Route path={RouteEnum.UpdatePassword} element={<UpdatePasswordPage />} />
            </Route>
            <Route path='*' element={<Navigate to='/' />} />

            {/* <LanguageChange /> */}
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
