import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { RootState } from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { IDeletePreference, IVehicleShared } from 'types/redux/applicationDetails';
import { useParams } from 'react-router-dom';
import { deletePreferenceVehicle } from 'store/vehicle-share/slice';
import { DELETE_CONFIRMATION, REQUIRED_CONFIRMATION } from 'constants/common';
import ShowConfirmationMessageModal from 'components/modal/ShowConfirmationMessageModal';
import VehicleImage from 'components/form-components/VehicleImage';
import AppLink from 'components/form-components/AppLink';
import { RouteEnum } from 'components/routes/RouteEnum';

const PreferenceTable = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [deleteId, setDeleteId] = useState('');
  const [requiredConfirmation, setRequiredConfirmation] = useState(false);

  const vehicleShareImage: IVehicleShared = useAppSelector((state: RootState) => state.vehicleShared);

  function hideConfirmationModal() {
    setRequiredConfirmation(false);
  }

  function onConfirm() {
    if (id && deleteId) {
      const params: IDeletePreference = {
        leadId: id,
        vehicleId: deleteId,
      };
      dispatch(deletePreferenceVehicle(params));
      hideConfirmationModal();
    }
  }

  return (
    <>
      <Table striped responsive borderless>
        <thead>
          <tr>
            <th>Image</th>
            <th>VIN</th>
            <th>Make</th>
            <th>Model</th>
            <th>Odometer</th>
            <th>Stock Number</th>
            <th>Year</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!!vehicleShareImage?.preferenceData?.length ? (
            vehicleShareImage?.preferenceData?.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{VehicleImage(item?.images?.path)}</td>
                  <td>{AppLink(`${RouteEnum.VehicleDetails}/${id}/${item.VehicleId}`, item?.VIN)}</td>
                  <td>{item?.Make}</td>
                  <td>{item?.Model}</td>
                  <td>{item?.Odometer}</td>
                  <td>{item?.StockNumber}</td>
                  <td>{item?.Year}</td>
                  <td>
                    <div className='align-items-center'>
                      <span
                        className='fa-icon rv-trash'
                        onClick={() => {
                          setDeleteId(item.VehicleId);
                          setRequiredConfirmation(true);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className='disabled' colSpan={8}>
                <h4 className='mb-2 text-center'>No Data Found</h4>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {requiredConfirmation && (
        <ShowConfirmationMessageModal
          requiredConfirmation={requiredConfirmation}
          hideConfirmationModal={hideConfirmationModal}
          msg={DELETE_CONFIRMATION}
          onConfirm={onConfirm}
          className={'modal-without-header login-modal'}
          title={REQUIRED_CONFIRMATION}
        />
      )}
    </>
  );
};

export default PreferenceTable;
