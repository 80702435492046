import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React from 'react';
import { IModalProps } from './CustomModalInterface';

function CustomModal(props: IModalProps) {
  const { show, handleClose, title, classNames } = props;

  return (
    <Modal show={show} onHide={() => handleClose()} centered className={`${classNames}`}>
      <Modal.Header closeButton closeVariant='white'>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}

export default CustomModal;
