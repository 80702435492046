import { IHomeMortgageDetails } from 'pages/application/applicationDetailsInterface';
import { residenceTypeOptions } from 'pages/application/constants';
import { amountFormatter, getLabel } from 'pages/application/helper';
import { Col, Form, Row } from 'react-bootstrap';

const ShowMortgage = (props: IHomeMortgageDetails) => {
  const { homeData } = props;

  return (
    <Row>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-name' className='form-group'>
          <Form.Label>Residence type</Form.Label>
          <span className='read-text'>
            {homeData?.residenceType ? getLabel(residenceTypeOptions, homeData?.residenceType,'label') : '-'}
          </span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-name' className='form-group'>
          <Form.Label> Monthly mortgage or rent amount</Form.Label>
          <span className='read-text'>
            {homeData?.monthlyMortgageOrRent ? amountFormatter(homeData?.monthlyMortgageOrRent) : '-'}
          </span>
        </Form.Group>
      </Col>
      {homeData?.residenceType === 'Mortgage' && (
        <>
          <Col sm={12} md={6}>
            <Form.Group controlId='form-name' className='form-group'>
              <Form.Label>Mortgage Amount</Form.Label>
              <span className='read-text'>
                {homeData?.mortgageAmount ? amountFormatter(homeData?.mortgageAmount) : '-'}
              </span>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group controlId='form-name' className='form-group'>
              <Form.Label>Market Value</Form.Label>
              <span className='read-text'>{homeData?.marketValue ? amountFormatter(homeData?.marketValue) : '-'}</span>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group controlId='form-name' className='form-group'>
              <Form.Label>Amount Owing on Mortgage</Form.Label>
              <span className='read-text'>
                {homeData?.amountOwningOnMortgage ? amountFormatter(homeData?.amountOwningOnMortgage) : '-'}
              </span>
            </Form.Group>
          </Col>
        </>
      )}
    </Row>
  );
};

export default ShowMortgage;
