import React from 'react';
import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import { Accordion, Button, Card } from 'react-bootstrap';
import PreferenceTable from './PreferenceTable';

const PreferenceVehicle = () => {
  return (
    <Card>
      <Card.Header>
        <AccordionCustomToggle eventKey='8'>
          <div className='card-toggle--left'>
            <span className='fa-icon rv-preference' />
            <span className='card-toggle--left-text'>My Preference</span>
          </div>
          <div className='card-toggle--right'>
            <div className='accordion-arrow'>
              <span className='fa-icon rv-pointer-down' />
            </div>
          </div>
        </AccordionCustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey='8'>
        <Card.Body>
          <div className='vertical-scroll'>
            <PreferenceTable />
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default PreferenceVehicle;
