import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { DDMMYYYY } from 'constants/dateTime';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { formatPhoneNumber } from 'helpers/helper';
import { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { ILeadDetails } from '../applicationDetailsInterface';

export default function LeftSideBar(props: ILeadDetails) {
  const { applicationDetailData } = props;
  const [showProfileDetails, setShowProfileDetails] = useState<boolean>(false);

  const profileClickHandler = () => { 
    setShowProfileDetails(!showProfileDetails);
  }

  return (
    <div className='profile-panel'>
      <div className='profile-circle' onClick={profileClickHandler}>
        <ProfileAvatar
          image={applicationDetailData?.imageUrl}
          name={`${applicationDetailData?.firstName?.charAt(0) ?? ''} ${
            applicationDetailData?.lastName?.charAt(0) ?? ''
          }`}
        />
      </div>
      <h2 className='heading-6'>{`${applicationDetailData?.firstName ?? ''} ${
        applicationDetailData?.lastName ?? ''
      }`}</h2>
      <h4 className='profile-panel--subtitle'>{`Active Since: ${dateFormatter(
        applicationDetailData?.createdAt,
        DDMMYYYY
      )}`}</h4>
      {/* <ListGroup as='ul' bsPrefix=' ' className='list-unstyled profile-panel--controls'>
        <ListGroup.Item as='li' bsPrefix=' '>
          <a href='#'>
            <span className='fa-icon rv-phone' />
          </a>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <a href='#'>
            <span className='fa-icon rv-chat' />
          </a>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <a href='#'>
            <span className='fa-icon rv-email' />
          </a>
        </ListGroup.Item>
      </ListGroup> */}
      <ListGroup as='ul' bsPrefix=' ' className={`list-unstyled profile-panel--details profile-panel--mobile-details ${showProfileDetails ? 'show' : ''}`}>
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='icon-wrap'>
            <span className='fa-icon rv-user' />
          </div>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>Applicant</label>
            <span className='text-capitalize'>{`${applicationDetailData?.applicantRole?.toLowerCase()} Applicant`}</span>
          </div>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='icon-wrap'>
            <span className='fa-icon rv-email' />
          </div>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>Email</label>
            <span>{applicationDetailData?.email ?? ''}</span>
          </div>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='icon-wrap'>
            <span className='fa-icon rv-phone' />
          </div>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>Phone</label>
            <span>{applicationDetailData?.phone ? formatPhoneNumber(applicationDetailData?.phone) : '-'}</span>
          </div>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='icon-wrap'>
            <span className='fa-icon rv-calendar-1' />
          </div>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>Date of birth</label>
            <span>
              {applicationDetailData?.birthDate ? dateFormatter(applicationDetailData?.birthDate, DDMMYYYY) : ''}
            </span>
          </div>
        </ListGroup.Item>
        <ListGroup.Item as='li' bsPrefix=' '>
          <div className='icon-wrap'>
            <span className='fa-icon rv-address' />
          </div>
          <div className='profile-panel--details-text'>
            <label className='heading-4'>Address</label>
            <span>{applicationDetailData?.address}</span>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}
