import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { IAvatar } from './avatarInterface';

const ProfileAvatar = (props: IAvatar) => {
  const { image, name = '', className } = props;
  return (
    <Stack direction='row' spacing={1}>
      <Avatar alt='avatar' src={image} className={className}>
        {name}
      </Avatar>
    </Stack>
  );
};

export default ProfileAvatar;
