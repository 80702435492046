import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, getExceptionPayload } from '../../services/api';
import { signUp } from '../../services/signUp';
import { ISignUpData, ISignUpState } from '../../types/redux/signup';

const initialState: ISignUpState = {
	data: undefined,
	loading: false,
	error: undefined,
	signUpUser: {
		status: APIStatus.IDLE,
	},
};

export const userSignUp = createAsyncThunk('signUpData', async (params: Object, { rejectWithValue }) => {
	try {
		const response = await signUp(params);
		return response.data;
	} catch (error) {
		throw rejectWithValue(getExceptionPayload(error));
	}
});

const slice = createSlice({
	name: 'signUp',
	initialState,
	reducers: {
		resetUserSignUp: (state) => {
			state.loading = false;
			state.data = undefined;
			state.error = undefined;
			localStorage.clear();
		},
	},
	extraReducers(builder): void {
		builder
			.addCase(userSignUp.pending, (state) => {
				state.loading = true;
				state.data = undefined;
				state.error = undefined;
				state.signUpUser.status = APIStatus.PENDING;
			})
			.addCase(userSignUp.fulfilled, (state, action) => {
				state.loading = false;
				state.data = action.payload;
				state.signUpUser.status = APIStatus.FULFILLED;
			})
			.addCase(userSignUp.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message as string;
				state.signUpUser.status = APIStatus.REJECTED;
			});
	},
});
export const { resetUserSignUp } = slice.actions;
export default slice.reducer;
