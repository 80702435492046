import Loader from 'components/loader/Loader';
import { Accordion } from 'react-bootstrap';
import { showVehicleAccordions } from '../VehicleConstants';
import { ShowVehicleDetailsAccordion } from '../VehicleInterface';
import VehicleDetailsAccordions from './VehicleDetailsAccordions';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import SlideVehicleImages from './SlideVehicleImages';

const VehicleDetailsView = () => {
  const loader = useAppSelector((state: RootState) => state.vehicleDetails.loader);

  return loader ? (
    <Loader />
  ) : (
    <>
      <SlideVehicleImages />
      <Accordion defaultActiveKey='0' className='accordion-style-1 accordion-style-2'>
        {showVehicleAccordions.map((item: ShowVehicleDetailsAccordion, index: number) => {
          return <VehicleDetailsAccordions label={item.label} getDataFn={item.getDataFn} eventKey={item.eventKey} icon={item.icon} key={index} />;
        })}
      </Accordion>
    </>
  );
};

export default VehicleDetailsView;
