import Logo from "../../scss/images/login-logo.svg";
import Wave from "../../scss/images/login-top-right-wave.svg";
import "./login-layout.scss";
import { ILoginLayout } from "./LoginLayoutInterface";


export default function LoginLayout(props: ILoginLayout) {
  return (
    <section className="login">
      <div className="login__img" />
      <div className="login__form">
        <img
          src={Wave}
          alt="login-wave-shape"
          className="login-top-right-wave"
        />
        <div className={`login__form-inner ${props.classNames}`}>
          <div className="dabadoo-img">
            <img src={Logo} alt="dabadu-logo" className="w-100" />
          </div>
          {props.children}
        </div>
      </div>
    </section>
  );
}
