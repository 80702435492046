import { capitalizeFirstLetter } from 'helpers';
import { IIncomeDetails } from 'pages/application/applicationDetailsInterface';
import { amountFormatter } from 'pages/application/helper';
import { Table } from 'react-bootstrap';

const ShowIncomeDetails = (props: IIncomeDetails) => {
  const { incomeData } = props;

  return (
    <Table striped responsive borderless>
      <thead>
        <tr>
          <th />
          <th>Monthly</th>
          <th>Annually</th>
        </tr>
      </thead>
      <tbody>
        {!!incomeData?.length &&
          incomeData?.map((item: any, index: number) => {
            return (
              <tr key={index}>
                <th>{capitalizeFirstLetter(item?.name) ?? ' '}</th>
                <td>{amountFormatter(item?.monthly) ?? ' '}</td>
                <td>{amountFormatter(item?.annually) ?? ' '}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default ShowIncomeDetails;
