import apiFactory from './api-factory';
import { IEndPointInterface } from './HttpUtilityInterface';

export async function get(parameters: IEndPointInterface) {
	const { endpoint, params } = parameters;
	const paramsConfig = params ? {params} : undefined;

	return apiFactory().get(endpoint, paramsConfig);
}

export async function post(parameters: IEndPointInterface) {
	const { endpoint, params } = parameters;
	const paramsConfig = params ? params : undefined;

	return apiFactory().post(endpoint, paramsConfig);
}

export async function put(parameters: IEndPointInterface) {
	const { endpoint, params } = parameters;
	const paramsConfig = params ? params : undefined;

	return apiFactory().put(endpoint, paramsConfig);
}

export async function patch(parameters: IEndPointInterface) {
	const { endpoint, params } = parameters;
	const paramsConfig = params ? { params } : undefined;

	return apiFactory().patch(endpoint, paramsConfig);
}

export async function del(parameters: IEndPointInterface) {
	const { endpoint, params } = parameters;
	const paramsConfig = params ? { params } : undefined;

	return apiFactory().delete(endpoint, paramsConfig);
}
