import DateInput from 'components/form-components/DateInput';
import FileUpload from 'components/form-components/FileUpload';
import Input from 'components/form-components/Input';
import { isEmpty } from 'lodash';
import { IDocumentFormData } from 'pages/application/applicationDetailsInterface';
import { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getApplicationsDetails } from 'store/application-detail/action';
import { useAppDispatch } from 'store/hooks';
import { deleteFiles } from 'store/update-files/slice';

const DocumentForm = (props: IDocumentFormData) => {
  const {
    label,
    value,
    inputLabel,
    fieldName,
    handleDocument,
    handlremoveFile,
    fileType,
    fileName,
    data,
    isEdit,
    deleteDocument,
    formState,
    doc,
    setDoc,
    leadId,
  } = props;
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { setValue, clearErrors } = formState;
  const [existingFiles, setExistingFiles] = useState<any>([]);
  const getType = () => {
    if (fileType === 'Primary') {
      return 'primary';
    } else {
      return 'secondary';
    }
  };

  const onChangeExpiryDate = (date: Date | any) => {
    setValue(`${fieldName}ExpiryDate`, date ? new Date(date) : '');
    clearErrors(`${fieldName}ExpiryDate`);
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      setExistingFiles([data]);
    }
  }, [data]);

  const onFileDelete = async (file: any) => {
    if (id) {
      await dispatch(deleteFiles({ documentId: file.documentId, leadDetailId: leadId }));
      await dispatch(getApplicationsDetails(id));
    }
  };

  return (
    <>
      <Col sm={12} md={3}>
        <Form.Group controlId='form-name' className='form-group'>
          <Form.Label>{label}</Form.Label>
          <span className='read-text'>{value}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={3}>
        <Form.Group controlId='form-other-desc' className='form-group'>
          <Form.Label>{inputLabel}</Form.Label>
          <Input
            fieldName={`${fieldName}Number`}
            fieldType={'text'}
            placeholder={`${fieldName} Number`}
            formState={formState}
            disabled={!isEdit}
          />
        </Form.Group>
      </Col>
      <Col sm={12} md={3}>
        <Form.Group controlId='form-expiryDate' className='form-group'>
          <Form.Label>{'Expiry Date'}</Form.Label>
          <DateInput
            fieldName={`${fieldName}ExpiryDate`}
            fieldType={`${fieldName}ExpiryDate`}
            placeholder={'Expiry Date'}
            formState={formState}
            onChange={onChangeExpiryDate}
          />
        </Form.Group>
      </Col>
      <Col sm={12} md={3}>
        <Form.Group controlId='upload-doc' className='form-group'>
          <Form.Label>Upload</Form.Label>
          <FileUpload
            isMultiple={false}
            existingFiles={existingFiles}
            newUploadedFiles={doc}
            setNewUploadedFiles={setDoc}
            onExistingFileRemove={onFileDelete}
          />
          {/* <FileUpload isMultiple={false} existingFiles={[]} newUploadedFiles={doc} setNewUploadedFiles={setDoc} /> */}
        </Form.Group>
      </Col>
    </>
  );
};

export default DocumentForm;
