import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { green, orange } from "@mui/material/colors";
import React from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
  },
  typography: {
    fontSize: 20,
  },
});

export default function LayoutThemeProvider(props: {
  children: React.ReactNode;
}) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
