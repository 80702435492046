import { useEffect, useState } from 'react'
import { Form, ListGroup } from 'react-bootstrap'
import { IFileUpload } from './FormComponentInterface';

const FileUpload:React.FC<IFileUpload> = ({isMultiple=false, newUploadedFiles, setNewUploadedFiles, existingFiles=[], onExistingFileRemove, existingFileNameKey="fileName" }) => {

const [allUploadedFiles, setAllUploadedFiles] = useState<any>([])
  const getClassName = () => {
    const totalLength = newUploadedFiles.length + (existingFiles?.length || 0);
    if(!isMultiple){
      return totalLength == 0 ? 'upload' : 'upload--single';
    }

    if((totalLength) == 0){
      return 'upload';
    }
    else if(totalLength == 1){
      return 'upload--multiple';
    }
    else if(totalLength > 1){
      return 'upload--multiple-with-float';
    }
  }

  const uploadClassName = getClassName();

  const removeNewUploadedFile = (fileIndex:number) => {

    const file = allUploadedFiles[fileIndex];
    if(file.recordType == 'existingFile'){
      onExistingFileRemove && onExistingFileRemove(file);
    }
    else{
      const remainingFiles = newUploadedFiles.filter((newFile) => newFile.name != file.name);
      setNewUploadedFiles(remainingFiles);
    }
  }

  const onFileupload = (e:React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    
    if(newFiles?.length){
      const allFiles = isMultiple ? newUploadedFiles.concat(Array.from(newFiles)) : [newFiles[0]];
      setNewUploadedFiles(allFiles);
    }
  }

  useEffect(() => {

    const allFileArray:any[] = [];
    
    existingFiles.forEach((element:{[key:string]:any}) => {
      const newElement = {...element, name:element[existingFileNameKey], recordType:"existingFile"}
      allFileArray.push(newElement);
    });

    newUploadedFiles.forEach((element) => {
      allFileArray.push(element);
    });

    setAllUploadedFiles(allFileArray);
    
  }, [newUploadedFiles, existingFiles]);


  const getFileName = (obj:any) => {

  }
  
  

  return (
    <div className={`file-ip-parent-wrap ${uploadClassName}`}>
      <div className="file-ip-name-wrap">
        {allUploadedFiles.length && (
          <>
            <span>{allUploadedFiles[0].name}</span>
            <div className="close-circle" onClick={() => {removeNewUploadedFile(0)}}>
              <span className="fa-icon rv-close" />
            </div>
          </>
        )}
        {allUploadedFiles.length > 1 && (<span className="doc-count">, +{allUploadedFiles.length - 1}</span>)}
        
        <ListGroup
          as="ul"
          bsPrefix=" "
          className="list-unstyled floating-doc-list"
        >
          {
            allUploadedFiles.length && allUploadedFiles.map((file:any, index:number) => (
              <ListGroup.Item bsPrefix=" " as="li" key={index}>
                <span>{file?.name}</span>
                <div className="close-circle" onClick={() => {removeNewUploadedFile(index)}}>
                  <span className="fa-icon rv-close" />
                </div>
              </ListGroup.Item>
            ))
          }
        </ListGroup>
      </div>
      <div className="file-ip-wrap">
        <Form.Control 
          type="file"
          multiple
          className="file-input" 
          onChange={onFileupload} 
        />
        <label className="file-ip-label">
          <span className="fa-icon rv-upload" />
        </label>
      </div>
    </div>
  )
}

export default FileUpload