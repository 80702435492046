import React from "react";
import loadingGif from "../../scss/images/loader-gif.gif";
import "./loader.scss";
const Loader = () => {
  return (
    <div className={`loading-wrapper`}>
      <img src={loadingGif} alt="loader" className="img-fluid" />
    </div>
  );
}

export default Loader;
