import AddressAutoComplete from 'components/form-components/AddressAutoComplete';
import { IAddressAutoCompleteResponse } from 'components/form-components/FormComponentInterface';
import Input from 'components/form-components/Input';
import InputSelect from 'components/form-components/InputSelect';
import Loader from 'components/loader/Loader';
import { isRequired } from 'helpers/errorMessageHelper';
import { enterPlaceholder } from 'helpers/placeHoldersHelper';
import { IAddressDetails } from 'pages/application/applicationDetailsInterface';
import { directionOption } from 'pages/application/constants';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { clearRequest, putApplicationDetail } from 'store/home-details/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isEmpty } from 'lodash';
import ShowSuccessMessageModal from 'components/modal/ShowSuccessMessageModal';
import { APIStatus } from 'services/api';


const AddressLookUp: React.FC<IAddressDetails> = (props:IAddressDetails) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();


  const {loading, data:updatedAddress, putStatus:updateAddressStatus}: any = useAppSelector((state: RootState) => state.homeDetails);
  const applicationDetails = useAppSelector((state: RootState) => state.applicationDetails);

  const formState = useForm({});
  const {setValue, handleSubmit, reset} = formState;

  const [addressData, setAddressData] = useState<any>({});

  
  useEffect(() => {
    const currentAddress = applicationDetails.data?.leadDetails;
    setAddressData({
      addressNo: currentAddress?.addressNumber,
      city: currentAddress?.city,
      streetType: currentAddress?.streetType,
      employerStreetName: currentAddress?.streetName,
      employerSuitNumber: currentAddress?.suiteNumber,
      country: currentAddress?.country,
      province: currentAddress?.province,
      postalCode: currentAddress?.zipCode,
      employerStreetNumber: currentAddress?.streetNumber,
    });

    const directionValue = directionOption.find(d => currentAddress.direction == d.value);
    reset({
      address: currentAddress?.address,
      direction: directionValue,
      yearsAtResidence:currentAddress.yearsAtResidence,
      monthsAtResidence:currentAddress.monthsAtResidence
    })

  }, [applicationDetails])
  
  

  const onSubmit = handleSubmit((data) => {
    const payload = {
      id,
      address: addressData ? `${addressData?.employerSuitNumber ?? ''} ${addressData?.streetType ?? ''} ${addressData?.employerSuitNumber ?? ''} ${addressData?.city ??
        ''}, ${addressData?.province?.value ?? ''} ${addressData?.postalCode ?? ''},${addressData?.country?.value ?? ''}` : '',
      suiteNumber: addressData?.employerSuitNumber ?? '',
      streetType: addressData?.employerStreetName ?? '',
      direction: data?.direction?.value ?? '',
      province: addressData?.province ?? '',
      city: addressData?.city ?? '',
      zipCode: addressData?.postalCode ?? '',
      streetName: addressData?.employerStreetName ?? '',
      addressType: addressData?.addressType ?? '',
      yearsAtResidence: data?.yearsAtResidence ?? '',
      monthsAtResidence: data?.monthsAtResidence ?? '',
      addressNumber: addressData?.addressNo ?? '',
      country: addressData?.country ?? '',
    }


    dispatch(putApplicationDetail(payload));
  });

  const onAddressSelect = (result:IAddressAutoCompleteResponse) => {
    setAddressData({
      ...addressData,
      addressNo: result.streetNumber,
      city: result.city,
      streetType: result.streetType,
      employerStreetName: result.streetName,
      employerSuitNumber: result.suiteNumber,
      country: result.country?.value,
      province: result.province,
      postalCode: result.postalCode,
      employerStreetNumber: result.streetNumber,
    });
    setValue('address', result.formatted_address);
  }

  const handleModalClose = () => {
    id && dispatch(getApplicationsDetails(id));
    dispatch(clearRequest());
  }
  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row>
            <Col sm={12}>
              <Form.Group controlId='form-direction' className='form-group'>
                <AddressAutoComplete
                  fieldName='address'
                  placeholder={enterPlaceholder('constants.address')}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.address'),
                    },
                  }}
                  onAddressSelect={onAddressSelect}

                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-direction' className='form-group'>
                <Form.Label>Direction:</Form.Label>
                <InputSelect
                  options={directionOption}
                  fieldName='direction'
                  rules={{
                    required: {
                      value: true,
                      message: isRequired('constants.direction'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-duration' className='form-group'>
                <Form.Label>Duration:</Form.Label>
                <Row>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Group controlId='form-duration-years' className='form-group d-flex align-items-center'>
                      <Form.Label className='mr-2 mb-0'>Years:</Form.Label>
                      <Input
                        fieldName='yearsAtResidence'
                        fieldType='text'
                        placeholder={enterPlaceholder('constants.durationyears')}
                        rules={{
                          required: {
                            value: true,
                            message: isRequired('constants.durationyears'),
                          },
                        }}
                        formState={formState}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <Form.Group controlId='form-duration-months' className='form-group d-flex align-items-center'>
                      <Form.Label className='mr-2 mb-0'>Months:</Form.Label>
                      <Input
                        fieldName='monthsAtResidence'
                        fieldType={'text'}
                        placeholder={enterPlaceholder('constants.durationMonths')}
                        rules={{
                          required: {
                            value: true,
                            message: isRequired('constants.durationMonths'),
                          },
                        }}
                        formState={formState}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='form-btn' className='form-group'>
                <Form.Label className='invisible d-none d-md-block'>Secondary Email:</Form.Label>
                <Button type='submit' className='btn-small ml-auto'>
                  {t('constants.update')}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}

      {updateAddressStatus.status == APIStatus.FULFILLED && <ShowSuccessMessageModal
        show
        handleClose={() => {handleModalClose()}}
        classNames={'modal-without-header login-modal'}
        title={'Successful'}
        text={'Address updated successfully'}
      />}
    </>
  )
}

export default AddressLookUp