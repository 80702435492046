import LoginLayout from 'components/login-layout/LoginLayout';
import ShowMessageModal from 'components/modal/ShowMessageModal';
import { RouteEnum } from 'components/routes/RouteEnum';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import Check from 'scss/images/login-check.svg';
import { resetUpdatePassword } from 'store/update-password/slice';
import { IUpdatePasswordState } from 'types/redux/forgotPassword';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import UpdatePasswordForm from './UpdatePasswordForm';

const UpdatePasswordPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useNavigate();
  const queryparams: any = queryString.parse(location.search);

  const {success:updatePasswordSuccess}: IUpdatePasswordState = useAppSelector((state: RootState) => state.updatePassword);

  const closeSuccessModal = () => { 
    dispatch(resetUpdatePassword());
    history(RouteEnum.Login);
   }

  return (
    <LoginLayout classNames={'signup-form'}>
      <h2 className='heading-2'>
        Update Password
      </h2>
      <h3 className='heading-2'></h3>
      {queryparams?.id && queryparams?.token ? (
        <UpdatePasswordForm queryparams={queryparams} />
      ) : (
        <div>Link is either expired or invalid</div>
      )}
      {updatePasswordSuccess && (
        <ShowMessageModal
          show={updatePasswordSuccess}
          handleClose={closeSuccessModal}
          classNames={'modal-without-header login-modal'}
        >
          <>
            <img src={Check} alt='check' />
            <h2 className='heading-1'>Updated!</h2>
            <p>Password updated successfully. Please login to continue</p>
          </>
        </ShowMessageModal>
      )}
    </LoginLayout>
  );
};

export default UpdatePasswordPage;
