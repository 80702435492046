import { GENDER_OPTIONS, LANGUAGES_OPTIONS, MARITAL_STATUS_OPTIONS } from 'constants/common';
import { DDMMYYYY } from 'constants/dateTime';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { formatPhoneNumber } from 'helpers/helper';
import { ILeadDetails } from 'pages/application/applicationDetailsInterface';
import { addMasking } from 'pages/application/helper';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { label } from '../../constants';
import { getLabel } from '../../helper';

export default function ShowPersonalInfo(props: ILeadDetails) {
  const { applicationDetailData } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-name' className='form-group'>
          <Form.Label>{t('constants.name')}:</Form.Label>
          <span className='read-text'>{`${applicationDetailData?.prefix ?? ''} ${
            applicationDetailData?.firstName ?? ''
          } ${applicationDetailData?.lastName ?? ''} ${applicationDetailData?.suffix ?? ''}`}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-sin' className='form-group'>
          <Form.Label>{t('constants.sin')}:</Form.Label>
          <span className='read-text'>{addMasking(applicationDetailData?.sin ?? '')}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-birth' className='form-group'>
          <Form.Label>{t('constants.dob')}:</Form.Label>
          <span className='read-text'>
            {applicationDetailData?.birthDate ? dateFormatter(applicationDetailData?.birthDate, DDMMYYYY) : ''}
          </span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-lang' className='form-group'>
          <Form.Label>{t('constants.language')}:</Form.Label>
          <span className='read-text text-capitalize'>
            {applicationDetailData?.language
              ? getLabel(LANGUAGES_OPTIONS, applicationDetailData?.language, label)
              : ''}
          </span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-gender' className='form-group'>
          <Form.Label>{t('constants.gender')}:</Form.Label>
          <span className='read-text'>
            {applicationDetailData?.gender?.toString()
              ? getLabel(GENDER_OPTIONS, applicationDetailData?.gender, label)
              : ''}
          </span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-phone' className='form-group'>
          <Form.Label>{t('constants.phone')}:</Form.Label>
          <span className='read-text'>{applicationDetailData?.phone ? formatPhoneNumber(applicationDetailData?.phone) : ' '}</span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-marriage' className='form-group'>
          <Form.Label>{t('constants.marital-status')}</Form.Label>
          <span className='read-text'>
            {applicationDetailData?.maritalStatus
              ? getLabel(MARITAL_STATUS_OPTIONS, applicationDetailData?.maritalStatus, label)
              : ''}
          </span>
        </Form.Group>
      </Col>
      <Col sm={12} md={6}>
        <Form.Group controlId='form-email' className='form-group'>
          <Form.Label>{t('constants.emailId')}:</Form.Label>
          <span className='read-text'>{applicationDetailData?.email}</span>
        </Form.Group>
      </Col>
    </Row>
  );
}
