import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { IFormInput } from './FormComponentInterface';
import { isEmpty } from 'lodash';

const InputNumber = ({
  fieldName,
  fieldType = 'text',
  placeholder,
  rules,
  formState,
  classNames,
  disabled = false,
  format,
}: IFormInput) => {
  const {
    setValue,
    clearErrors,
    getValues,
    control,
    formState: { errors },
  } = formState;
  return (
    <>
      <Controller
        render={({ field }) => (
          <NumberFormat
            format={format}
            inputMode='decimal'
            allowNegative={false}
            thousandSeparator={true}
            className='form-control'
            placeholder={placeholder}
            value={getValues(fieldName)}
            onValueChange={(values) => {
              const { formattedValue } = values;
              setValue(fieldName, formattedValue);
              if (!isEmpty(formattedValue)) {
                clearErrors(fieldName);
              }
            }}
            disabled={disabled}
          />
        )}
        name={fieldName}
        control={control}
        rules={rules}
      />
      {!!errors?.[fieldName] && <Form.Text className='text-danger'>{errors[fieldName].message}</Form.Text>}
    </>
  );
};

export default InputNumber;
