import { isEmpty } from 'lodash';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ISelect } from './FormComponentInterface';

export default function InputSelect({ options, fieldName, formState, rules={}, disabled = false, additionalOptions={}, placeholder }: ISelect) {
  const {
    setValue,
    setError,
    clearErrors,
    getValues,
    control,
    formState: { errors },
  } = formState;
  const { t } = useTranslation();

  return (
    <>
      <Controller
        render={({ field }) => (
          <Select
            className='react-select-container'
            classNamePrefix='react-select'
            name={fieldName}
            options={options}
            value={getValues(fieldName)}
            onChange={(e) => {
              setValue(fieldName, e);
              if (!isEmpty(e)) {
                clearErrors(fieldName);
              }
            }}
            isDisabled={disabled}
            placeholder={placeholder ? placeholder : 'Select...'}
            {...additionalOptions}
          />
        )}
        defaultValue={getValues(fieldName)}
        name={fieldName}
        control={control}
        rules={rules}
      />
      {!!errors?.[fieldName] && <Form.Text className='text-danger'>{errors[fieldName].message}</Form.Text>}
    </>
  );
}
