import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postUpdatePassword } from 'services/login';
import { IUpdatePasswordState } from 'types/redux/forgotPassword';
import { getExceptionPayload } from '../../services/api';

const initialState: IUpdatePasswordState = {
  data: undefined,
  error: undefined,
  loading: false,
  success: false,
};

export const updatePassword = createAsyncThunk('updatePassword', async (params: Object, { rejectWithValue }) => {
  try {
    const response = await postUpdatePassword(params);
    return response.message;
  } catch (error) {
    throw rejectWithValue(getExceptionPayload(error));
  }
});

const slice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    resetUpdatePassword: (state) => {
      state.data = undefined;
      state.error = undefined;
      state.success = false;
      state.loading = false;
    },
  },
  extraReducers(builder): void {
    builder
      .addCase(updatePassword.pending, (state) => {
        state.data = undefined;
        state.error = undefined;
        state.loading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.success = true;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loading = false;
        state.success = false;
      });
  },
});
export const { resetUpdatePassword } = slice.actions;
export default slice.reducer;
