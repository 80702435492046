import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import CustomModal from 'components/modal';
import ShowSuccessMessageModal from 'components/modal/ShowSuccessMessageModal';
import { dateFormatter } from 'helpers/dateTimeHelper';
import { isEmpty } from 'lodash';
import { IEmpHistory } from 'pages/application/applicationDetailsInterface';
import { amountFormatter, convertToInteger, getLabel } from 'pages/application/helper';
import { useEffect, useState } from 'react';
import { Accordion, Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import {
  clearAddEmployeeHistory,
  clearDeleteEmployeeHistory,
  clearUpdateEmployeeHistory,
  deleteEmployeeHistory,
} from 'store/empHistory/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IEmploymentHistoryState } from 'types/redux/applicationDetails';
import {
  employmentTypeOptions,
  frequencyTypeOptions,
  momentDateMonthYear,
  occupationTypeOptions,
} from '../../constants';
import AddNewEmp from './AddNewEmp';

export default function EmpHistory(props: IEmpHistory) {
  const dispatch = useAppDispatch();
  const { id: leadId } = useParams<{ id: string }>();
  const { employeeData } = props;
  const [addEmployee, setAddEmployee] = useState<boolean>(false);
  const [editEmployee, setEditEmployee] = useState<boolean>(false);
  const [editData, setEditData] = useState<{} | null>(null);
  const { deleteEmployeeHistorySuccess, updateEmpHistorySuccess, addEmployeeHistorySuccess }: IEmploymentHistoryState =
    useAppSelector((state: RootState) => state.empHistory);
  const applicationDetails = useAppSelector((state: RootState) => state.applicationDetails);

  const deleteEmpHistory = (empHistoryId: string) => {
    const params = {
      applicationId: applicationDetails?.data?.LeadDetailsId,
      employmentId: empHistoryId,
    };
    dispatch(deleteEmployeeHistory(params));
  };

  const editEmployeeHistory = (item: {}) => {
    setEditEmployee(true);
    setEditData(item);
  };

  const closeSuccessModal = (dispatchAction: ActionCreatorWithoutPayload) => {
    leadId && dispatch(getApplicationsDetails(leadId));
    dispatch(dispatchAction());
  };

  useEffect(() => {
    if (updateEmpHistorySuccess) {
      setEditData(null);
      setEditEmployee(false);
    }
  }, [updateEmpHistorySuccess]);

  useEffect(() => {
    if (addEmployeeHistorySuccess) {
      setAddEmployee(false);
    }
  }, [addEmployeeHistorySuccess]);

  const showSuccessModal = (clearFunction: ActionCreatorWithoutPayload, title: string, text: string) => {
    return (
      <ShowSuccessMessageModal
        show
        handleClose={() => {
          closeSuccessModal(clearFunction);
        }}
        classNames={'modal-without-header login-modal'}
        title={title}
        text={text}
      />
    );
  };

  return (
    <>
      <Card>
        <Card.Header>
          <AccordionCustomToggle eventKey='5'>
            <div className='card-toggle--left'>
              <span className='fa-icon rv-work-history' />
              <span className='card-toggle--left-text'>Employment History</span>
            </div>
            <div className='card-toggle--right'>
              <span className='fa-icon rv-edit' />
              <div className='accordion-arrow'>
                <span className='fa-icon rv-pointer-down' />
              </div>
            </div>
          </AccordionCustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey='5'>
          <Card.Body>
            <div className='d-flex align-items-center justify-content-end mb-4'>
              <a onClick={() => setAddEmployee(true)} className='blue-icon-text-link mr-5'>
                <span className='fa-icon rv-add-circle' />
                <span className='blue-text'>
                  ADD NEW EMPLOYMENT
                </span>
              </a>
              {/* <a href='#' className='blue-icon-text-link'>
                <span className='fa-icon rv-upload' />
                <span className='blue-text' onClick={() => setShowFlag('paystub')}>
                  UPLOAD PAYSTUBS
                </span>
              </a> */}
            </div>
            <div className='accordion-border'>
              <Table striped responsive borderless>
                <thead>
                  <tr>
                    <th>Employer</th>
                    <th>Employment Type</th>
                    <th>Occupation</th>
                    <th>Income</th>
                    <th>Frequency</th>
                    <th>Duration</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {!!employeeData?.length &&
                    employeeData.map((item: any, index: number) => {
                      return (
                        <tr key={item._id}>
                          <th>{item?.employer}</th>
                          <td>{getLabel(employmentTypeOptions, item?.employmentType, 'label')}</td>
                          <td>{getLabel(occupationTypeOptions, item?.occupation, 'label')}</td>
                          <td>{!!item?.income ? amountFormatter(convertToInteger(item?.income)) : ''}</td>
                          <td>{getLabel(frequencyTypeOptions, item?.frequency, 'label')}</td>
                          <td>{`${
                            item?.duration?.from ? dateFormatter(item?.duration?.from, momentDateMonthYear) : '0'
                          }-${item?.duration?.to ? dateFormatter(item?.duration?.to, momentDateMonthYear) : '0'} `}</td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <span
                                className='fa-icon rv-edit mr-4'
                                onClick={() => {
                                  editEmployeeHistory(item);
                                }}
                              />
                              <span
                                className='fa-icon rv-trash'
                                onClick={() => {
                                  deleteEmpHistory(item._id);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {isEmpty(employeeData) && (
                    <tr>
                      <td className='disabled' colSpan={3}>
                        <h5>No Data Found</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      {deleteEmployeeHistorySuccess &&
        showSuccessModal(clearDeleteEmployeeHistory, 'Deleted', 'Employee history deleted successfully')}

      {updateEmpHistorySuccess &&
        showSuccessModal(clearUpdateEmployeeHistory, 'Updated', 'Employee history updated successfully')}

      {addEmployeeHistorySuccess &&
        showSuccessModal(clearAddEmployeeHistory, 'Submitted', 'Employee history submitted successfully')}

      {addEmployee && (
        <CustomModal
          title={'Employment History'}
          show={addEmployee}
          handleClose={() => setAddEmployee(false)}
          classNames={'add-new'}
        >
          <div className='form-area'>
            <AddNewEmp isEdit={editEmployee} />
          </div>
        </CustomModal>
      )}

      {editEmployee && (
        <CustomModal
          title={'Update Employment History'}
          show={editEmployee}
          handleClose={() => setEditEmployee(false)}
          classNames={'add-new'}
        >
          <div className='form-area'>
            <AddNewEmp editData={editData} isEdit={editEmployee} />
          </div>
        </CustomModal>
      )}
    </>
  );
}
