import { Navigate, Outlet } from "react-router-dom";
import { RouteEnum } from "./RouteEnum";
import { useAuth } from "./hooks";

const ProtectedRoutes = (props: any) => {
  const auth = useAuth();
  return auth ? <Outlet /> : <Navigate to={RouteEnum.Login} />;
};

export default ProtectedRoutes;
