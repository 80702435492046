import { del, get, post, put } from '../utils/HttpUtility';
import { api } from '../utils/base';
import { IDeletePaystubParams, IDeletePreference } from 'types/redux/applicationDetails';

export const getApplicationDetails = async (params: string) => {
  const endpoint = api.getApplicationDetails.replace(':id', params);
  const response = await get({ endpoint, params });
  return response.data;
};

export const getIncomeDetail = async (params: string) => {
  const endpoint = `${api.getIncomeDetail}/${params}`;
  const response = await get({ endpoint });
  return response.data;
};

export const postIncomeDetail = async (params: Object) => {
  const endpoint = `${api.calculateIncome}`;
  const response = await post({ endpoint, params });
  return response.data;
};

export const putApplicationDetails = async (id: string, params: Object) => {
  const endpoint = `${api.updateApplicationDetails}/${id}`;
  const response = await put({ endpoint, params });
  return response.data;
};

export const addEmployeeHistory = async (params: {}) => {
  const endpoint = `${api.addEmploymentHistory}`;
  const response = await post({ endpoint, params });
  return response.data;
};

export const putDocumentsDetails = async (params: any) => {
  const endpoint = api.updateDocumentsDetails;
  const response = await put({ endpoint, params });
  return response.data;
};

export const delUpdateFiles = async (params: any) => {
  const endpoint = `${api.deleteDocument}?documentId=${params.documentId}&leadDetailId=${params.leadDetailId}`;
  const response = await del({ endpoint });
  return response.data;
};

export const postUploadDocuments = async (type: string, params: any) => {
  const endpoint = `${api.updateDocuments}?type=${type}`;
  const response = await post({ endpoint, params });
  return response.data;
};

export const removeEmployeeHistory = async (params: {}) => {
  const endpoint = `${api.deleteEmploymentHistory}`;
  const response = await del({ endpoint, params });
  return response.data;
};

export const editEmployeeHistory = async (params: {}) => {
  const endpoint = `${api.uploadEmploymentHistory}`;
  const response = await put({ endpoint, params });
  return response.data;
};

export const getVehicleShared = async (params: Object) => {
  const endpoint = `${api.vehicleShared}`;
  const response = await get({ endpoint, params });
  return response.data;
};

export const postVehicleSharedPreference = async (params: any) => {
  const endpoint = `${api.postVehicleSharedPreference}`;
  const response = await post({ endpoint, params });
  return response.data;
};

export const getCompareVehicle = async (params: any) => {
  params = encodeURIComponent(JSON.stringify(params?.vehicleId));
  const endpoint = `${api.compareVehicle}?vehicleId=${params}`;
  const response = await get({ endpoint });
  return response.data;
};

export const deletePayStub = async (params: IDeletePaystubParams) => {
  const endpoint = api.deletePaystubFile.replace(':leadId', params.leadId).replace(':payStubId', params.payStubId);
  const response = await del({ endpoint });
  return response.data;
};

export const deletePreference = async (params: IDeletePreference) => {
  const endpoint = api.deletePreference.replace(':leadId', params.leadId).replace(':vehicleId', params.vehicleId);
  const response = await del({ endpoint });
  return response.data;
};
