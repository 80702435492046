import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExceptionPayload, APIStatus, APIError } from '../../services/api';
import { getProfileDetail } from 'services/profile';
import { IProfileState } from 'types/redux/profileDetails';

const initialState: IProfileState = {
	data: undefined,
	loading: false,
	error: undefined,
	profileUser: {
		status: APIStatus.IDLE,
	},
};

export const getProfileDetails = createAsyncThunk('profileDetails', async () => {
  try {
    const response = await getProfileDetail();
    return response.message;
  } catch (error) {
    throw rejectWithValue(getExceptionPayload(error));
  }
});

const slice = createSlice({
	name: 'profileDetails',
	initialState,
	reducers: {
		resetProfile: (state) => {
			state.data = undefined;
			state.error = undefined;
		},
	},
	extraReducers(builder): void {
		builder
			.addCase(getProfileDetails.pending, (state) => {
				state.data = undefined;
				state.error = undefined;
				state.loading = true;
				state.profileUser.status = APIStatus.PENDING;
			})
			.addCase(getProfileDetails.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
				state.profileUser.status = APIStatus.PENDING;
			})
			.addCase(getProfileDetails.rejected, (state, action) => {
				state.error = action.error.message as string;
				state.loading = false;
				state.profileUser.status = APIStatus.PENDING;
			});
	},
});
export const { resetProfile } = slice.actions;
export default slice.reducer;
function rejectWithValue(arg0: APIError) {
	throw new Error('Function not implemented.');
}
