import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { IDatePickerInput } from './FormComponentInterface';

const DatePickerInput = ({
  fieldName,
  rules,
  formState,
  defaultValue = null,
  className = 'form-control',
  datepickerOptions = {},
}: IDatePickerInput) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = formState;

  useEffect(() => {
    defaultValue && setValue(fieldName, defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Controller
        name={fieldName}
        render={({ field: { onChange } }) => (
          <DatePicker
            selected={getValues(fieldName)}
            onChange={onChange}
            className={className}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            {...datepickerOptions}
          />
        )}
        rules={rules}
        control={control}
      />
      {!!errors?.[fieldName] && <Form.Text className='text-danger'>{errors[fieldName].message}</Form.Text>}
    </>
  );
};

export default DatePickerInput;
