import { Card, Accordion, Col, Row, Form, ListGroup } from 'react-bootstrap';
import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import { useState } from 'react';
import ShowMortgage from './ShowMortgage';
import EditHomeMortgage from './EditHomeMortgage';
import { IHomeMortgageDetails } from 'pages/application/applicationDetailsInterface';

export default function HomeMortgage(props: IHomeMortgageDetails) {
  const { homeData } = props;
  const [showMortgage, setShowMortgage] = useState(false);

  return (
    <Card>
      <Card.Header>
        <AccordionCustomToggle eventKey='3'>
          <div className='card-toggle--left'>
            <span className='fa-icon rv-info-circle-filled' />
            <span className='card-toggle--left-text'>Home/Mortgage Details</span>
          </div>
          <div className='card-toggle--right'>
            <span
              className='fa-icon rv-edit'
              onClick={(e) => {
                e.stopPropagation();
                setShowMortgage(!showMortgage);
              }}
            />
            <div className='accordion-arrow'>
              <span className='fa-icon rv-pointer-down' />
            </div>
          </div>
        </AccordionCustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey='3'>
        <Card.Body>
          {!showMortgage ? <ShowMortgage homeData={homeData} /> : <EditHomeMortgage homeData={homeData} />}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
