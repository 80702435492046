import { get, put } from '../utils/HttpUtility';
import { api } from '../utils/base';

export const getProfileDetail = async () => {
  const endpoint = api.getProfileDetails;
  const response = await get({ endpoint });
  return response.data;
};

export const putProfileDetail = async (id: string, params: Object) => {
  const endpoint = api.putProfileDetails.replace(':id', id);
  const response = await put({ endpoint, params });
  return response.data;
};

export const putProfileImage = async (id: string, params: any) => {
  const endpoint = api.putProfileImage.replace(':id', id);
  const response = await put({ endpoint, params });
  return response.data;
};
