import NumberFormat from 'react-number-format';
import { phoneFormat } from '../constants/common';
import React from 'react';

export const formatPhoneNumber = (phoneNumberString: string) => {
  return (
    <NumberFormat
      value={phoneNumberString}
      format={phoneFormat}
      displayType={'text'}
      renderText={(value: any) => <span>{value}</span>}
    />
  );
};

export const getSelectedOption = (options:{label:string|number,value:string|number}[], match:string|number) => { 
  return options.find((item) => item.value === match);
}