import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { IShowMessageModal } from './CustomModalInterface';

const ShowMessageModal:React.FC<IShowMessageModal> = ({show, handleClose, classNames, children }) => {
  
  return (
    <Modal show={show} onHide={() => handleClose()} centered className={`${classNames}`}>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ShowMessageModal;
