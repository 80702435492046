import { AxiosResponse } from "axios";

export enum APIStatus {
  IDLE,
  PENDING,
  REJECTED,
  FULFILLED,
}

export type APIError = {
  message: string;
};

export type APIData<DataType = any> = {
  status: APIStatus;
  error?: APIError;
  data?: DataType;
  response?: DataType;
};

export const InternalServerError = {
  message: "Internal server error",
};

export const onFulfilledRequest = (response: AxiosResponse) => response;

export const onRejectedResponse = (error: any): any =>
  Promise.reject(InternalServerError);

export const getExceptionPayload = (ex: unknown): APIError => {
  if (typeof ex !== "object" || !ex) {
    return InternalServerError;
  }
  const typedException = ex as APIData;

  if (ex.hasOwnProperty("message")) {
    return {
      message: typedException.response.data.message,
    };
  }
  return InternalServerError;
};
