export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const humanize = (value: string) => {
  return value.replaceAll('_', ' ');
};

export const patternMessage = (regex: string, message: string) => {
  return {
    value: regex,
    message: message,
  };
};

export const convertStringToLowerCase = (value: string) => {
  return capitalizeFirstLetter(humanize(value)?.toLowerCase()) ?? '';
};

export const getUserName = (applicationDetails: any) => {
  return `${applicationDetails?.firstName ?? ''} ${applicationDetails?.lastName ?? ''}`;
};

export const isLoggedIn=()=>{
  return !!localStorage.getItem('accessToken') 
}