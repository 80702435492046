import InputNumber from 'components/form-components/InputNumber';
import InputSelect from 'components/form-components/InputSelect';
import { IHomeMortgageDetails } from 'pages/application/applicationDetailsInterface';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { residenceTypeOptions } from '../../constants';
import React, { useEffect, useState } from 'react';
import Loader from 'components/loader/Loader';
import { convertToInteger } from 'pages/application/helper';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import { putApplicationDetail, clearRequest } from 'store/home-details/slice';
import { getApplicationsDetails } from 'store/application-detail/action';
import { isEmpty } from 'lodash';

const EditHomeMortgage = (props: IHomeMortgageDetails) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { showMortgage, homeData } = props;
  const [rentFlag, setRentFlag] = useState(false);
  const [ownFlag, setOwnFlag] = useState(false);
  const formState = useForm({});
  const { handleSubmit, getValues, setValue } = formState;

  const putStatus: any = useAppSelector((state: RootState) => state.homeDetails);

  useEffect(() => {
    if (getValues('residenceType')?.label === 'Rent' || getValues('residenceType')?.label !== 'Mortgage') {
      setRentFlag(true);
      setOwnFlag(false);
    }
    if (getValues('residenceType')?.label === 'Mortgage') {
      setRentFlag(false);
      setOwnFlag(false);
    }
    if (getValues('residenceType')?.label === 'Own') {
      setValue('rentAmount', 0);
      setOwnFlag(true);
    }
  }, [getValues('residenceType')]);

  useEffect(() => {
    if (homeData) {
      const filteredResidenceType = residenceTypeOptions?.filter((item) => item.value === homeData?.residenceType);
      if (filteredResidenceType?.length > 0) {
        setValue('residenceType', filteredResidenceType[0]);
      }
      setValue('rentAmount', homeData?.monthlyMortgageOrRent);
      setValue('mortgageAmount', homeData?.mortgageAmount);
      setValue('marketValue', homeData?.marketValue);
      setValue('amountOwningOnMortgage', homeData?.amountOwningOnMortgage);
    }
  }, [homeData]);

  useEffect(() => {
    if (!isEmpty(putStatus?.data) && id) {
      dispatch(getApplicationsDetails(id));
      dispatch(clearRequest());
    }
  }, [putStatus]);

  const onSubmit = handleSubmit((data) => {
    let putHomePayload: any = {
      id: id,
      financialInfo: {
        residenceType: data?.residenceType?.value ?? '',
        monthlyMortgageOrRent: data?.rentAmount ? convertToInteger(data?.rentAmount) : '',
      },
    };
    if (!rentFlag) {
      putHomePayload = {
        ...putHomePayload,
        financialInfo: {
          ...putHomePayload.financialInfo,
          mortgageAmount: data?.mortgageAmount ? convertToInteger(data?.mortgageAmount) : '',
          marketValue: data?.marketValue ? convertToInteger(data?.marketValue) : '',
          amountOwningOnMortgage: data?.amountOwningOnMortgage ? convertToInteger(data?.amountOwningOnMortgage) : '',
        },
      };
    }
    dispatch(putApplicationDetail(putHomePayload));
  });

  return (
    <>
      {putStatus?.loading ? (
        <Loader />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-residence-type' className='form-group'>
                <Form.Label>Residence type:</Form.Label>
                <InputSelect
                  options={residenceTypeOptions}
                  fieldName={'residenceType'}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: 'Residence Type required',
                    },
                  }}
                />
              </Form.Group>
            </Col>
            {!rentFlag && (
              <>
                <Col sm={12} md={6}>
                  <Form.Group controlId='form-monthly-mortgage' className='form-group'>
                    <Form.Label>Mortgage Amount:</Form.Label>
                    <InputNumber
                      fieldName={'mortgageAmount'}
                      fieldType={'text'}
                      placeholder={'Mortgage Amount'}
                      rules={{
                        required: {
                          value: true,
                          message: 'Mortgage Amount required',
                        },
                      }}
                      formState={formState}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Group controlId='form-mortgage-amount' className='form-group'>
                    <Form.Label>Market Value Of Property:</Form.Label>
                    <InputNumber
                      fieldName={'marketValue'}
                      fieldType={'text'}
                      placeholder={'Market Value'}
                      rules={{
                        required: {
                          value: true,
                          message: 'Market Value required',
                        },
                      }}
                      formState={formState}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {getValues('residenceType')?.label === 'Rent' && (
              <Col sm={12} md={6}>
                <Form.Group controlId='form-mortgage-amount' className='form-group'>
                  <Form.Label>Rent amount:</Form.Label>
                  <InputNumber
                    fieldName={'rentAmount'}
                    fieldType={'text'}
                    placeholder={'Rent Amount'}
                    rules={{
                      required: {
                        value: true,
                        message: 'Rent Amount required',
                      },
                    }}
                    formState={formState}
                  />
                </Form.Group>
              </Col>
            )}
            {getValues('residenceType')?.label !== 'Rent' && (
              <Col sm={12} md={6}>
                <Form.Group controlId='form-mortgage-amount' className='form-group'>
                  <Form.Label>Monthly mortgage or rent amount:</Form.Label>
                  <InputNumber
                    fieldName={'rentAmount'}
                    fieldType={'text'}
                    placeholder={'Monthly mortgage or rent amount'}
                    rules={{
                      required: {
                        value: true,
                        message: 'Monthly mortgage or rent amount required',
                      },
                    }}
                    formState={formState}
                    disabled={ownFlag}
                  />
                </Form.Group>
              </Col>
            )}
            {!rentFlag && (
              <Col sm={12} md={6}>
                <Form.Group controlId='form-mortgage-amount' className='form-group'>
                  <Form.Label>Amount Owing on Mortgage:</Form.Label>
                  <InputNumber
                    fieldName={'amountOwningOnMortgage'}
                    fieldType={'text'}
                    placeholder={'Amount Owning On Mortgage'}
                    rules={{
                      required: {
                        value: true,
                        message: 'Amount Owning On Mortgage required',
                      },
                    }}
                    formState={formState}
                  />
                </Form.Group>
              </Col>
            )}
            <Col>
              <Form.Group controlId='form-btn' className='form-group'>
                <Button type='submit' className='btn-small ml-auto'>
                  Update
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default EditHomeMortgage;
