import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';
import DropDownToggel from './DropDownToggel';
import { IHeader } from './HeaderInterface';
import { displayBreadcrumbs } from './header.helpers';
import { Link } from 'react-router-dom';

const Header = (props: IHeader) => {
  const history = useLocation();

  return (
    <div className='header'>
      <div className='header__left'>
        <h1 className='heading-5'>{props.heading ?? 'Home'}</h1>

        <Breadcrumb className='breadcrumb-main'>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/'}} key='home'>Home</Breadcrumb.Item>

          {props.breadcrumb && props.breadcrumb.map((b, i) => (
            b.link 
              ? <Breadcrumb.Item linkAs={Link} linkProps={{to: b.link}} key={i}>{b.label}</Breadcrumb.Item>
              : <Breadcrumb.Item active key={i}>{b.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>


      </div>
      <div className='header__right'>
        <DropDownToggel />
      </div>
    </div>
  );
};

export default Header;
