import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { putProfileDetail, putProfileImage } from 'services/profile';
import { IPutProfileDetails, IPutProfileStatus } from 'types/redux/profileDetails';
import { APIStatus, getExceptionPayload } from '../../services/api';

const initialState: IPutProfileStatus = {
	data: undefined,
	error: undefined,
	loading: false,
	putStatus: {
		status: APIStatus.IDLE,
	},
};

export const putProfileDetails = createAsyncThunk(
	'profileDetails/put',
	async (params: IPutProfileDetails, { rejectWithValue }) => {
		try {
			const response = await putProfileDetail(params?.id, params);
			return response.message;
		} catch (error) {
			throw rejectWithValue(getExceptionPayload(error));
		}
	}
);

export const putProfileImg = createAsyncThunk('profileDetails/put/image', async (params: any, { rejectWithValue }) => {
	try {
		const response = await putProfileImage(params?.id, params?.image);
		return response.message;
	} catch (error) {
		throw rejectWithValue(getExceptionPayload(error));
	}
});

const slice = createSlice({
	name: 'profileDetails',
	initialState,
	reducers: {
		resetProfileUpdate: (state) => {
			state.data = undefined;
			state.error = undefined;
		},
		clearRequest: (state) => {
			state.data = undefined;
			state.error = undefined;
			state.putStatus = initialState.putStatus;
		},
	},

	extraReducers(builder): void {
		builder
			.addCase(putProfileDetails.pending, (state) => {
				state.data = undefined;
				state.error = undefined;
				state.putStatus.status = APIStatus.PENDING;
				state.loading = true;
			})
			.addCase(putProfileDetails.fulfilled, (state, action) => {
				state.data = action.payload;
				state.putStatus.status = APIStatus.FULFILLED;
				state.loading = false;
			})
			.addCase(putProfileDetails.rejected, (state, action) => {
				state.error = action.error.message as string;
				state.putStatus.status = APIStatus.REJECTED;
				state.loading = false;
			})
			.addCase(putProfileImg.pending, (state) => {
				state.data = undefined;
				state.error = undefined;
				state.putStatus.status = APIStatus.PENDING;
				state.loading = true;
			})
			.addCase(putProfileImg.fulfilled, (state, action) => {
				state.data = action.payload;
				state.putStatus.status = APIStatus.FULFILLED;
				state.loading = false;
			})
			.addCase(putProfileImg.rejected, (state, action) => {
				state.error = action.error.message as string;
				state.putStatus.status = APIStatus.REJECTED;
				state.loading = false;
			});
	},
});
export const { resetProfileUpdate, clearRequest } = slice.actions;
export default slice.reducer;
