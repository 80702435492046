import { Card, Accordion, Col, Row, Form, ListGroup } from 'react-bootstrap';
import AccordionCustomToggle from 'components/accordion-custom-toggle/AccordionCustomToggle';
import Select from 'react-select';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ManualAddress from './ManualAddress';
import AddressLookUp from './AddressLookUp';
import ShowAddress from './ShowAddress';
import { IAddressDetails } from 'pages/application/applicationDetailsInterface';

export default function AddressInfo(props: IAddressDetails) {
  const { addressData } = props;
  const [showAddress, setShowAddress] = useState<boolean>(false);
  const [autoSearch, setAutoSearch] = useState<boolean>(false);
  const [manualInput, setManualInput] = useState<boolean>(false);

  return (
    <Card>
      <Card.Header>
        <AccordionCustomToggle eventKey='2'>
          <div className='card-toggle--left'>
            <span className='fa-icon rv-address-filled' />
            <span className='card-toggle--left-text'>Current Address</span>
          </div>
          <div className='card-toggle--right'>
            {showAddress && (
              <>
                {!autoSearch && !manualInput && (
                  <Link
                    to='#'
                    className='blue-icon-text-link'
                    onClick={(e) => {
                      e.stopPropagation();
                      setAutoSearch(!autoSearch);
                      setManualInput(true);
                    }}
                  >
                    <span className='fa-icon rv-search' />
                    <span className='blue-text'>Address Lookup</span>
                  </Link>
                )}
                {manualInput && (
                  <Link
                    to='#'
                    className='blue-icon-text-link'
                    onClick={(e) => {
                      e.stopPropagation();
                      setAutoSearch(!autoSearch);
                      setManualInput(false);
                    }}
                  >
                    <span className='blue-text'>Manual Lookup</span>
                  </Link>
                )}
                <span
                  className='fa-icon rv-close'
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAddress(!showAddress);
                    setManualInput(false);
                    setAutoSearch(false);
                  }}
                />
              </>
            )}

            {!showAddress && (
              <span
                className='fa-icon rv-edit'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAddress(!showAddress);
                }}
              />
            )}
            <div className='accordion-arrow'>
              <span className='fa-icon rv-pointer-down' />
            </div>
          </div>
        </AccordionCustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey='2'>
        <Card.Body>
          {showAddress && !autoSearch && (
            <ManualAddress
              setShowAddress={setShowAddress}
              showAddress={showAddress}
              setAutoSearchAddress={setAutoSearch}
              setManualInput={setManualInput}
              manualInput={manualInput}
              addressData={addressData}
            />
          )}
          {showAddress && autoSearch && <AddressLookUp addressData={addressData} />}
          {!showAddress && <ShowAddress addressData={addressData} />}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
