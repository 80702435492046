import { profileBreadcrumbs } from './ProfileConstant';
import Layout from 'components/layout/Layout';
import Loader from 'components/loader/Loader';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { getProfileDetails } from 'store/profile-detail/slice';
import { IProfileState } from 'types/redux/profileDetails';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import LeftSideBar from './components/LeftSideBar';
import PersonalInfo from './components/PersonalInfo';
import { IProfileDetails, IProfileSidebar } from './ProfileInterface';

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const [profileSidebarDetail, setProfileSidebarDetail] = useState<IProfileSidebar>();
  const [profileData, setProfileData] = useState<IProfileDetails>();

  const profile: IProfileState | any = useAppSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (isEmpty(profile?.data)) {
      dispatch(getProfileDetails());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(profile?.data)) {
      setProfileSidebarDetail(profile?.data);
      setProfileData(profile?.data);
    }
  }, [profile]);

  return (
    <>
      {profile?.loading ? (
        <Loader />
      ) : (
        <Layout heading='Profile' breadcrumb={profileBreadcrumbs}>
          <section className='details-page'>
            <div className='details-page--left'>
              <LeftSideBar profileSidebarDetail={profileSidebarDetail} />
            </div>
            <div className='details-page--right'>
              <div className='details-page--right-with-border'>
                <PersonalInfo profileData={profileData} />
              </div>
            </div>
          </section>
        </Layout>
      )}
    </>
  );
};

export default ProfilePage;
