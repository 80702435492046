import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getExceptionPayload, APIStatus, APIError } from '../../services/api';
import {
  getVehicleShared,
  postVehicleSharedPreference,
  getCompareVehicle,
  deletePreference,
} from 'services/application';
import { IDeletePreference, IVehicleShared } from 'types/redux/applicationDetails';

const initialState: IVehicleShared = {
  deletePreferenceFlag: false,
  compareData: [],
  postPreference: undefined,
  preferenceData: undefined,
  data: undefined,
  loader: false,
  error: undefined,
  vehicleSharedStatus: {
    status: APIStatus.IDLE,
  },
};

export const getVehicleSharedData = createAsyncThunk('vehicleShared', async (params: Object, { rejectWithValue }) => {
  try {
    const response = await getVehicleShared(params);
    return response.message;
  } catch (error) {
    throw rejectWithValue(getExceptionPayload(error));
  }
});

export const getVehicleSharedPreferenceData = createAsyncThunk(
  'vehicleShared/preference',
  async (params: Object, { rejectWithValue }) => {
    try {
      const response = await getVehicleShared(params);
      return response.message;
    } catch (error) {
      throw rejectWithValue(getExceptionPayload(error));
    }
  }
);

export const postVehicleSharedPreferenceData = createAsyncThunk(
  'vehicleShared/preference/post',
  async (params: Object, { rejectWithValue }) => {
    try {
      const response = await postVehicleSharedPreference(params);
      return response.message;
    } catch (error) {
      throw rejectWithValue(getExceptionPayload(error));
    }
  }
);

export const getCompareVehicleData = createAsyncThunk(
  'vehicleShared/preference/compare',
  async (params: Object, { rejectWithValue }) => {
    try {
      const response = await getCompareVehicle(params);
      return response.message;
    } catch (error) {
      throw rejectWithValue(getExceptionPayload(error));
    }
  }
);

export const deletePreferenceVehicle = createAsyncThunk(
  'vehicleShared/preference/delete',
  async (params: IDeletePreference, { rejectWithValue }) => {
    try {
      const response = await deletePreference(params);
      return response.message;
    } catch (error) {
      throw rejectWithValue(getExceptionPayload(error));
    }
  }
);

const slice = createSlice({
  name: 'vehicleShared',
  initialState,
  reducers: {
    resetVehicleShared: (state) => {
      state.data = undefined;
      state.error = undefined;
      state.preferenceData = initialState.preferenceData;
    },
    clearPostPreference: (state) => {
      state.postPreference = initialState.postPreference;
    },
    clearCompareVehicles: (state) => {
      state.compareData = initialState.compareData;
    },
    clearDeletePreferenceFlag: (state) => {
      state.deletePreferenceFlag= initialState.deletePreferenceFlag;
    },
  },
  extraReducers(builder): void {
    builder
      .addCase(getVehicleSharedData.pending, (state) => {
        state.data = initialState.data;
        state.error = initialState.error;
        state.loader = true;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(getVehicleSharedData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
   
      .addCase(getVehicleSharedData.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(postVehicleSharedPreferenceData.pending, (state) => {
        state.postPreference = initialState.data;
        state.error = initialState.error;
        state.loader = true;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(postVehicleSharedPreferenceData.fulfilled, (state, action) => {
        state.postPreference = action.payload;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(postVehicleSharedPreferenceData.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(getCompareVehicleData.pending, (state) => {
        state.compareData = initialState.compareData;
        state.error = initialState.error;
        state.loader = true;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(getCompareVehicleData.fulfilled, (state, action) => {
        state.compareData = action.payload;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(getCompareVehicleData.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(getVehicleSharedPreferenceData.pending, (state) => {
        state.preferenceData = initialState.data;
        state.error = initialState.error;
        state.loader = true;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(getVehicleSharedPreferenceData.fulfilled, (state, action) => {
        state.preferenceData = action.payload;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(getVehicleSharedPreferenceData.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(deletePreferenceVehicle.fulfilled, (state, action) => {
        state.deletePreferenceFlag = true;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      })
      .addCase(deletePreferenceVehicle.rejected, (state, action) => {
        state.error = action.error.message as string;
        state.loader = false;
        state.vehicleSharedStatus.status = APIStatus.PENDING;
      });
  },
});
export const { resetVehicleShared, clearPostPreference, clearCompareVehicles,clearDeletePreferenceFlag } = slice.actions;
export default slice.reducer;
