import { createSlice } from '@reduxjs/toolkit';
import { IErrorToast } from 'types/redux/errorToast';

const initialState: IErrorToast = {
	item: null,
};

const slice = createSlice({
	name: 'errorToast',
	initialState,
	reducers: {
		addError: (state, action) => {
			state.item = action.payload;
		},
		clearError: (state) => {
			state.item = null;
		},
	},
});
export const { addError, clearError } = slice.actions;
export default slice.reducer;
