import DateInput from 'components/form-components/DateInput';
import Input from 'components/form-components/Input';
import InputNumber from 'components/form-components/InputNumber';
import InputSelect from 'components/form-components/InputSelect';
import PhoneNoInput from 'components/form-components/PhoneNoInput';
import PhoneNumberInput from 'components/form-components/PhoneNumberInput';
import Loader from 'components/loader/Loader';
import { GENDER_OPTIONS, LANGUAGES_OPTIONS, MARITAL_STATUS_OPTIONS, onlyCountries } from 'constants/common';
import { phoneRegex, spaceRegex } from 'constants/regex';
import { convertBirthDate, dateOfBirthValidation } from 'helpers/dateTimeHelper';
import { isInvalid } from 'helpers/errorMessageHelper';
import { isEmpty } from 'lodash';
import { ILeadDetails } from 'pages/application/applicationDetailsInterface';
import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getApplicationsDetails } from 'store/application-detail/action';
import { clearRequest, putApplicationDetail } from 'store/home-details/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { prefixOption, suffixOption } from '../../constants';
import { getLabel } from '../../helper';
import { emptyPhone, invalidPhone } from 'helpers/validationHelpers';


export default function EditPersonalInfo(props: ILeadDetails) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const formState = useForm({});
  const { applicationDetailData } = props;
  const { handleSubmit, setValue, clearErrors, setError, getValues, reset } = formState;
  const putStatus: any = useAppSelector((state: RootState) => state.homeDetails);

  useEffect(() => {
    if (applicationDetailData) {
      const suffixFilter = getLabel(suffixOption, applicationDetailData?.suffix);
      const prefixFilter = getLabel(prefixOption, applicationDetailData?.prefix);
      const genderFilter = getLabel(GENDER_OPTIONS, applicationDetailData?.gender);
      const languageOptionFilter = getLabel(LANGUAGES_OPTIONS, applicationDetailData?.language);
      const maritalStatusFilter = getLabel(MARITAL_STATUS_OPTIONS, applicationDetailData?.maritalStatus);
      reset({
        ...applicationDetailData,
        suffix: suffixFilter,
        prefix: prefixFilter,
        gender: genderFilter,
        language: languageOptionFilter,
        maritalStatus: maritalStatusFilter,
        birthDate: applicationDetailData?.birthDate ? convertBirthDate(applicationDetailData?.birthDate) : '',
        cellPhone: applicationDetailData?.phone ?? '',
      });
    }
  }, [applicationDetailData]);

  useEffect(() => {
    if (!isEmpty(putStatus?.data) && id) {
      dispatch(getApplicationsDetails(id));
      dispatch(clearRequest());
    }
  }, [putStatus]);

  const onSubmit = handleSubmit((data) => {
    const payload = {
      id: id,
      prefix: data?.prefix?.value ?? '',
      firstName: data?.firstName,
      middleName: data?.middleName ?? '',
      lastName: data?.lastName ?? '',
      suffix: data?.suffix?.value ?? '',
      gender: data?.gender?.value ?? '',
      maritalStatus: data?.maritalStatus?.value ?? '',
      birthDate: data?.birthDate ?? '',
      sin: data?.sin ?? '',
      phone: data?.cellPhone ?? '',
      otherPhoneNumber: data?.otherPhoneNumber ?? '',
      email: data?.email ?? '',
      language: data?.language?.value ?? '',
    };

    dispatch(putApplicationDetail(payload));
  });

  const onChangeDateOfBirth = (date: Date | any) => {
    setValue('birthDate', new Date(date));
    {
      getValues('birthDate') && dateOfBirthValidation(date) !== ''
        ? setError('birthDate', {
            type: 'manual',
            message: t('errorConstants.dob-pattern'),
          })
        : clearErrors('birthDate');
    }
  };

  return (
    <>
      {putStatus?.loading ? (
        <Loader />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-name' className='form-group'>
                <Form.Label>{t('constants.prefix')}:</Form.Label>
                <InputSelect options={prefixOption} fieldName={'prefix'} formState={formState} />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group controlId='form-name' className='form-group'>
                <Form.Label>{t('constants.firstName')}:</Form.Label>
                <Input
                  fieldName={'firstName'}
                  fieldType={'text'}
                  placeholder={t('constants.first-name')}
                  rules={{
                    required: {
                      value: true,
                      message: t('errorConstants.first-name-required'),
                    },
                    pattern: {
                      value: spaceRegex,
                      message: isInvalid('constants.firstName'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-name' className='form-group'>
                <Form.Label>{t('constants.middleName')}:</Form.Label>
                <Input
                  fieldName={'middleName'}
                  fieldType={'text'}
                  placeholder={t('constants.middleName')}
                  formState={formState}
                  rules={{
                    pattern: {
                      value: spaceRegex,
                      message: isInvalid('constants.middleName'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-name' className='form-group'>
                <Form.Label>{t('constants.lastName')}:</Form.Label>
                <Input
                  fieldName={'lastName'}
                  fieldType={'text'}
                  placeholder={t('constants.last-name')}
                  rules={{
                    required: {
                      value: true,
                      message: t('errorConstants.last-name-required'),
                    },
                    pattern: {
                      value: spaceRegex,
                      message: isInvalid('constants.lastName'),
                    },
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-name' className='form-group'>
                <Form.Label>{t('constants.suffix')}:</Form.Label>
                <InputSelect options={suffixOption} fieldName={'suffix'} formState={formState} />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-sin' className='form-group'>
                <Form.Label>{t('constants.sin')}:</Form.Label>
                <InputNumber
                  format={'###-###-###'}
                  fieldName={'sin'}
                  fieldType={'text'}
                  placeholder={'SIN'}
                  rules={{
                    required: false,
                  }}
                  formState={formState}
                />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group controlId='form-birth' className='form-group'>
                <Form.Label>{t('constants.dob')}</Form.Label>
                <DateInput
                  fieldName={'birthDate'}
                  fieldType={'birthDate'}
                  placeholder={t('constants.birthDate')}
                  formState={formState}
                  rules={{
                    required: {
                      value: true,
                      message: t('errorConstants.dob-required'),
                    },
                  }}
                  onChange={onChangeDateOfBirth}
                />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group controlId='form-lang' className='form-group'>
                <Form.Label>{t('constants.language')}:</Form.Label>
                <InputSelect options={LANGUAGES_OPTIONS} fieldName={'language'} formState={formState} />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-gender' className='form-group'>
                <Form.Label>{t('constants.gender')}:</Form.Label>
                <InputSelect options={GENDER_OPTIONS} fieldName={'gender'} formState={formState} />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-phone' className='form-group'>
                <Form.Label>{t('constants.phone')}:</Form.Label>
                <PhoneNoInput
                  country={onlyCountries[0]}
                  onlyCountries={onlyCountries}
                  countryCodeEditable={false}
                  formState={formState}
                  fieldName='cellPhone'
                  rules={{
                    validate: {
                      emptyPhone: (v:string) => emptyPhone(v, 'constants.phone'),
                      invalidPhone: (v:string) => invalidPhone(v, 'constants.phone'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-phone' className='form-group'>
                <Form.Label>{t('constants.mobile')}:</Form.Label>
                <PhoneNoInput
                  country={onlyCountries[0]}
                  onlyCountries={onlyCountries}
                  countryCodeEditable={false}
                  formState={formState}
                  fieldName='otherPhoneNumber'
                  rules={{
                    validate: {
                      emptyPhone: (v:string) => emptyPhone(v, 'constants.mobile'),
                      invalidPhone: (v:string) => invalidPhone(v, 'constants.mobile'),
                    },
                  }}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-marriage' className='form-group'>
                <Form.Label>{t('constants.marital-status')}</Form.Label>
                <InputSelect options={MARITAL_STATUS_OPTIONS} fieldName={'maritalStatus'} formState={formState} />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId='form-email' className='form-group'>
                <Form.Label>{t('constants.emailId')}:</Form.Label>
                <Input
                  fieldName={'email'}
                  fieldType={'email'}
                  placeholder={t('constants.email-placeholder')}
                  rules={{
                    required: {
                      value: true,
                      message: t('errorConstants.email-required'),
                    },
                  }}
                  formState={formState}
                  disabled={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='form-btn' className='form-group'>
                <Form.Label className='invisible d-none d-md-block'>Secondary Email:</Form.Label>
                <Button type='submit' className='btn-small ml-auto'>
                  {t('constants.update')}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
