import { RouteEnum } from 'components/routes/RouteEnum';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SidebarCloseLogo from 'scss/images/sidebar-close-logo.svg';
import SidebarOpenLogo from 'scss/images/sidebar-open-logo.svg';
import { RootState } from '../../store';
import { useAppSelector } from '../../store/hooks';

export default function SideBar() {
  const history = useNavigate();
  const applications = useAppSelector((state: RootState) => state.applications);

  const [isOpen, setIsOpen] = useState(false);

  const handleApplicationData = (e: Event) => {
    if (!isEmpty(applications)) {
      e.preventDefault();
      history(RouteEnum.Applicant);
    }
  };

  return (
    <aside className={`navbar main-sidebar ${isOpen ? '' : 'slide-sidebar'}`}>
      <div className='main-sidebar__logo'>
        <Link to='#' className='d-block' onClick={(e: any) => handleApplicationData(e)}>
          <img src={isOpen ? SidebarOpenLogo : SidebarCloseLogo} className='img-fluid' />
        </Link>
      </div>
      <div className='main-sidebar__menu'></div>
    </aside>
  );
}
