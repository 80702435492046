import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import { IQueryParams } from '../../components/data-table/DataTableInterface';
import { DDMMYYYY } from '../../constants/dateTime';
import { dateFormatter } from '../../helpers/dateTimeHelper';

export const displayLeadId = (params: GridRenderCellParams) => {
  return <NavLink to={params.row.lead}>{params.value}</NavLink>;
};

export const displayFullName = (params: GridValueGetterParams) => {
  return `${params.row.firstName} ${params.row.lastName}`;
};

export const formatDate = (params: GridValueGetterParams) => {
  return params.row.createdAt ? dateFormatter(params.row.createdAt, DDMMYYYY) : '';
};

export const loadColumns = (setColumns: Function, queryParams: IQueryParams) => {
  setColumns([
    {
      field: 'leadId',
      headerName: 'ID',
      sortable: true,
      renderCell: displayLeadId,
      width: 130,
    },
    {
      field: 'firstName',
      headerName: 'Name',
      sortable: true,
      valueGetter: displayFullName,
      width: 130,
    },
    {
      field: 'email',
      headerName: 'Email Id',
      sortable: true,
      width: 200,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      sortable: true,
      width: 130,
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: true,
      width: 130,
    },
    {
      field: 'applicantRole',
      headerName: 'Applicant Role',
      sortable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Application Submitted Date',
      sortable: true,
      width: 250,
      valueGetter: formatDate,
    },
  ]);
};

export const stringifyAndRedirect = (params: any, navigate: Function, queryString: any) => {
  params.pagination = JSON.stringify(params.pagination);
  params.sortable = JSON.stringify(params.sortable);
  params.filter = JSON.stringify(params.filter);

  navigate({
    pathname: location.pathname,
    search: queryString.stringify(params),
  });
};
